import React from 'react'
import {compose} from 'redux'
import {reduxForm, getFormValues} from 'redux-form'
import {connect} from 'react-redux'
import {FORGOT_PASSWORD_FORM_NAME, forgotPasswordFormValidation} from 'constants/reduxFormConfig'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import DefaultLayout from 'components/layouts/DefaultLayout'
import {forgotPassword} from 'actions/accountActions'
import FormRow from 'components/FormRow'
import Button from 'components/Button'
import RequestWrapper from 'components/RequestWrapper'
import FormField from 'components/FormField'
import styles from './styles.module.scss'

const SUCCESS_MESSAGE = 'Thank you! Please check your email to get your password reset link'

export const ForgotPassword = ({formValues, forgotPassword, forgotPasswordRequestState, handleSubmit}) => {
  const onSubmit = handleSubmit(() => forgotPassword(formValues))

  return (
    <DefaultLayout pageTitle='Forgot Password'>
      <form className={styles.formContainer} onSubmit={onSubmit}>
        <RequestWrapper requestStates={[forgotPasswordRequestState]} successMessage={SUCCESS_MESSAGE}>
          <FormRow label='Email'>
            <FormField name='email' type='email' />
          </FormRow>
          <Button text='Submit' type='submit' />
        </RequestWrapper>
      </form>
    </DefaultLayout>
  )
}

const mapDispatchToProps = {forgotPassword}
const mapStateToProps = state => ({
  formValues: getFormValues(FORGOT_PASSWORD_FORM_NAME)(state),
  forgotPasswordRequestState: getRequestStatus(state.account.forgotPasswordRequestState)
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: FORGOT_PASSWORD_FORM_NAME,
    validate: forgotPasswordFormValidation
  })
)(ForgotPassword)
