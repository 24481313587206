import React, {useEffect} from 'react'
import DefaultLayout from 'components/layouts/DefaultLayout'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'

import styles from './styles.module.scss'

const TermsAndConditions = ({location}) => {
  useEffect(() => {
    if (location.hash === '#corelogic') {
      const id = location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) element.scrollIntoView()
    }
  })

  return (
    <DefaultLayout pageTitle='Terms & conditions'>
      <ContentSection>
        <BackButton />
        <h1 className={styles.sectionTitle}>Terms and Conditions</h1>
        <h3>Last updated: 15 February 2019</h3>
        <p>{`These Terms and Conditions ("Terms", "Terms and Conditions") govern the use of the RealESmart application (RealESmart") operated by Resolve Management Limited ("us", "we", or "our").`}</p>
        <p>
          Please read the Terms and Conditions carefully before using the RealESmart application (the "Service"). By
          accessing or using RealESmart you agree to be bound by these Terms.
        </p>
        <p>
          Your access to and use of RealESmart is conditional on your acceptance of and compliance with these Terms.
          These Terms also apply to all visitors, users and others who access or use RealESmart.
        </p>
        <p>User/s, visitor and those that access the RealESmart application have the following responsibilities:</p>
        <ol>
          <li>to comply with these Terms;</li>
          <li>
            to ensure the accuracy of the information that is supplied on the RealESmart application including user
            profile.
          </li>
          <li>to comply with all applicable laws, regulations, standards and codes;</li>
          <li>to not damage the application or disrupt it.</li>
          <li>
            to not use any of the RealESmart content or service:
            <ul>
              <li>
                for sending spam, chain letters, pyramid schemes, hoaxes or other similar unsolicited communications of
                any kind;
              </li>
              <li>to abuse, defame, threaten, stalk or harass others;</li>
              <li>
                for sending objectionable language, content, or any expressions of hatred, racism or discriminatory
                remarks of any kind;
              </li>
              <li>
                for running any network scanning software, spiders, spyware software, robots, open relay software, or
                any such similar software, including any software that may be used for scraping data;
              </li>
              <li>
                for introducing any viruses, worms, trojan horses, time bombs or bots or any other harmful or
                destructive items or in any other way interfere with our, or anyone else’s network or computer system;
              </li>
              <li>
                for using any software or device which may interfere with the services which are intended to be enjoyed
                by other users through use of mail bombs, war dialling, pinging or other disruptive technical means;
              </li>
              <li>
                for the purpose of misleading others as to the identity of the sender or origin of any message
                (including by using a false identity).
              </li>
              .
            </ul>
          </li>
        </ol>
        <h3>Purchases</h3>
        <p>
          If you wish to purchase any product or service made available through RealESmart ("Purchase"), you may be
          asked to supply certain information relevant to your Purchase including, without limitation, your credit card
          number, the expiration date of your credit card, your billing address, and your shipping information.
        </p>
        <p>You represent and warrant that:</p>
        <ol>
          <li>
            you have the legal right to use any credit card(s) or other payment method(s) in connection with any
            Purchase; and that{' '}
          </li>
          <li>the information you supply to us is true, correct and complete.</li>
        </ol>
        <p>
          By submitting such information, you grant us the right to provide the information to third parties for
          purposes of facilitating the completion of Purchases. We reserve the right to refuse or cancel your order at
          any time for certain reasons including but not limited to: product or service availability, errors in the
          description or price of the product or service, error in your order or other reasons. We reserve the right to
          refuse or cancel your order if fraud or an unauthorised or illegal transaction is suspected.
        </p>
        <h3>Availability</h3>
        <p>
          We are constantly updating our offerings of products and services on RealESmart. We reserve the right to
          change or update information at any time.
        </p>
        <h3>Subscriptions</h3>
        <p>
          Some parts of the Service may be billed on a subscription basis ("Subscription(s)"). You will be billed in
          advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set on a monthly basis.
        </p>
        <p>
          At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions
          unless you cancel it or Resolve Management Limited cancels it. You may cancel your Subscription renewal either
          through your online account management page or by contacting Resolve Management Limited customer support team.
        </p>
        <p>
          A valid payment method, including credit card or PayPal, is required to process the payment for your
          Subscription. You shall provide Resolve Management Limited with accurate and complete billing information
          including full name, address, suburb, city, post code, telephone number, and a valid payment method
          information. By submitting such payment information, you automatically authorise Resolve Management Limited to
          charge all Subscription fees incurred through your account to any such payment instruments.
        </p>
        <p>
          Should automatic billing fail to occur for any reason, Resolve Management Limited will issue an electronic
          invoice indicating that you must proceed manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.
        </p>
        <h3>Fee Changes</h3>
        <p>
          Resolve Management Limited, in its sole discretion and at any time, may modify the Subscription fees for the
          Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
        </p>
        <p>
          Resolve Management Limited will provide you with a reasonable prior notice of any change in Subscription fees
          to give you an opportunity to terminate your Subscription before such change becomes effective.
        </p>
        <p>
          Your continued use of the Service after the Subscription fee change comes into effect constitutes your
          agreement to pay the modified Subscription fee amount.
        </p>
        <h3>Reports</h3>
        <p>
          RealESmart may offer data reports for purchase by the subscriber. The reports will attract a fee. A valid
          payment method, including credit card or PayPal, is required to process the payment for the reports. While
          every effort is made to produce accurate data for the reports, Resolve Management Limited cannot be held
          responsible or liable if there are any errors, seasonal variations or omissions in the reports produced.{' '}
        </p>
        <h3>Refunds</h3>
        <p>
          Certain refund requests for Subscriptions may be considered by Resolve Management Limited on a case-by-case
          basis and granted in sole discretion of Resolve Management Limited. Content
        </p>
        <h3>Content</h3>
        <p>
          Our Service allows you to post, link, store, share and otherwise make available certain information, text,
          graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the
          Service, including its legality, reliability, and appropriateness.
        </p>
        <p>
          By posting Content to the Service, you grant us the right and license to use, modify, perform, display,
          reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to
          any Content you submit, post or display on or through the Service and you are responsible for protecting those
          rights.
        </p>
        <p>You represent and warrant that:</p>
        <ol>
          <li>
            the Content is yours (you own it) or you have the right to use it and grant us the rights and license as
            provided in these Terms, and{' '}
          </li>
          <li>
            the posting of your Content on or through the Service does not violate the privacy rights, publicity rights,
            copyrights, contract rights or any other rights of any person.
          </li>
        </ol>
        <h3>Accounts</h3>
        <p>
          When you create an account with us, you must provide us information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
          your account on our Service.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to access the Service and for any activities or
          actions under your password, whether your password is with our Service or a third-party service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware
          of any breach of security or unauthorised use of your account.
        </p>
        <p>
          You may not use as a username the name of another person or entity or that is not lawfully available for use,
          a name or trade mark that is subject to any rights of another person or entity other than you without
          appropriate authorisation, or a name that is otherwise offensive, vulgar or obscene.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          The Service and its original content, features and functionality are and will remain the exclusive property of
          Resolve Management Limited and its licensors. The Service is protected by copyright, trademark, and other laws
          of both the New Zealand and foreign countries. Our trademarks may not be used in connection with any product
          or service without the prior written consent of Resolve Management Limited.
        </p>
        <h3>Links To Other Web Sites</h3>
        <p>
          Our Service may contain links to third-party web sites or services that are not owned or controlled by Resolve
          Management Limited.
        </p>
        <p>
          Resolve Management Limited has no control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You further acknowledge and agree that
          Resolve Management Limited shall not be responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
          services available on or through any such web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
          services that you visit.
        </p>
        <div style={{visibility: 'hidden'}} id='corelogic' />
        <h3>CoreLogic End User Terms and Conditions</h3>
        <p>
          By using RealESmart Premium you accept the terms laid out in this document:{' '}
          <a href='https://www.corelogic.com.au/resources/pdf/misc/rpdata-terms-conditions-integrators.pdf'>
            https://www.corelogic.com.au/resources/pdf/misc/rpdata-terms-conditions-integrators.pdf
          </a>
        </p>
        <h3>CoreLogic Disclaimers</h3>
        <p>
          Online Use: all relevant disclaimers may be housed on a separate link house on a separate page or footer
          housed on the same page as which the data is being displayed (Disclaimers). The CoreLogic Data provided in
          this publication is of a general nature and should not be construed as specific advice or relied upon in lieu
          of appropriate professional advice.
        </p>
        <p>
          While CoreLogic uses commercially reasonable efforts to ensure the CoreLogic Data is current, CoreLogic does
          not warrant the accuracy, currency or completeness of the CoreLogic Data and to the full extent permitted by
          law excludes all loss or damage howsoever arising (including through negligence) in connection with the
          CoreLogic Data.
        </p>
        <h3>CoreLogic Copyright Notice</h3>
        <p>
          This publication reproduces materials and content owned or licenced by CoreLogic NZ Limited (CoreLogic) and
          may include data, statistics, estimates, indices, photographs, maps, tools, calculators (including their
          outputs), commentary, reports and other information (CoreLogic Data).
        </p>
        <p>
          © Copyright 2018. CoreLogic and its licensors are the sole and exclusive owners of all rights, title and
          interest (including intellectual property rights) subsisting in this publication, including any data,
          analytics, statistics and other information contained in this publication. All rights reserved.
        </p>
        <h3>Termination</h3>
        <p>
          We may terminate or suspend your account immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the
          Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the
          Service by giving one month’s notice in writing to be delivered by email communication to
          info@realesmart.co.nz.
        </p>
        <h3>Limitation Of Liability</h3>
        <p>
          In no event shall Resolve Management Limited, nor its directors, employees, partners, agents, suppliers, or
          affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from
        </p>
        <ol>
          <li>your access to or use of or inability to access or use the Service;</li>
          <li>any conduct or content of any third party on the Service;</li>
          <li>any content obtained from the Service; and</li>
          <li>
            unauthorised access, use or alteration of your transmissions or content, whether based on warranty,
            contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the
            possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential
            purpose.
          </li>
          <li>any errors, omissions or inaccuracies in reports that are produced. </li>
        </ol>
        <h3>Disclaimer</h3>
        <p>
          Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
          The Service is provided without warranties of any kind, whether express or implied, including, but not limited
          to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of
          performance.
        </p>
        <p>
          While Resolve Management Limited its subsidiaries, affiliates, and its licensors will strive to provide a
          fully functional Service and all care is taken to protect the contents of RealESmart, Resolve Management
          Limited does not warrant that:
        </p>
        <ol>
          <li>the Service will function uninterrupted;</li>
          <li>any errors or defects will be corrected;</li>
          <li>the Service is free of viruses or other harmful components 100 percent of the time; or</li>
          <li>the results of using the Service will meet your requirements.</li>
        </ol>
        <h3>Governing Law</h3>
        <p>These Terms shall be governed and construed in accordance with the laws of New Zealand.</p>
        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
          If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
          these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our
          Service, and supersede and replace any prior agreements we might have between us regarding the Service.
        </p>
        <h3>Changes</h3>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
          material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes
          a material change will be determined at our sole discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by
          the revised terms. If you do not agree to the new terms, please don’t use the Service.{' '}
        </p>
        <h3>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us at info@realesmart.co.nz.</p>
      </ContentSection>
    </DefaultLayout>
  )
}

export default TermsAndConditions
