import React from 'react'
import Button from 'components/Button'
import ContentSection from 'components/ContentSection'
import logo from 'assets/static/logo.svg'
import styles from './styles.module.scss'

export const FormBody = ({children}) => <div className={styles.formBody}>{children}</div>

export const FormFooter = ({children}) => <ContentSection className={styles.formFooter}>{children}</ContentSection>

export const FormSubmit = ({text}) => <Button text={text} type='submit' />

const Form = ({children, onSubmit}) => (
  <form className={styles.container} onSubmit={onSubmit}>
    <img className={styles.logo} alt='logo' src={logo} />
    {children}
  </form>
)

export default Form
