/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'

import useFileSelection from 'hooks/useFileSelection'
import useFileUpload from 'hooks/useFileUpload'
import useImageCompression from 'hooks/useImageCompression'
import cameraIcon from 'assets/static/icons/camera.svg'

import FormError from 'components/FormError'

import styles from './styles.module.scss'

const PhotoUploader = ({onPhotoUploaded, calculationID}) => {
  const [uploading, setUploading] = useState(false)

  const [compressedFile, compressFile] = useImageCompression()
  const [file, setFile] = useFileSelection()
  const [imageError, setImageError] = useState(null)
  // eslint-disable-next-line
  const [uploadedFileSrc, uploadRequestStatuses, uploadFile, clearPhoto] = useFileUpload({
    type: 'property_photo',
    src: file.src,
    name: file.name,
    calculationID
  })

  useEffect(() => {
    if (compressedFile) {
      if (compressedFile.size > 256000) {
        setImageError({message: 'File still too large after compression'})
      } else {
        setFile(compressedFile)
      }
    }
  }, [compressedFile])

  useEffect(() => {
    if (file && file.src) {
      uploadFile()
    }
  }, [file])

  useEffect(() => {
    if (imageError) {
      setUploading(false)
    }
  }, [imageError])

  useEffect(() => {
    if (uploadedFileSrc && file.src) {
      onPhotoUploaded(uploadedFileSrc)
      setUploading(false)
      clearPhoto()
    }
  }, [uploadedFileSrc])

  const handlePhotoSelect = ({target}) => {
    setUploading(true)
    compressFile(target.files[0])
  }

  return (
    <>
      <label className={styles.textContainer}>
        <input type='file' onChange={handlePhotoSelect} accept='image/jpeg,image/png' hidden={true} />
        {uploading ? 'Uploading' : <img className={styles.cameraIcon} alt='camera' src={cameraIcon} />}
      </label>
      {imageError && !uploading && <FormError error={imageError.message} />}
    </>
  )
}

export default PhotoUploader
