import React from 'react'
import PropTypes from 'prop-types'
import {ASC, DESC, RIGHT} from 'constants/directional'
import arrowIcon from 'assets/static/icons/left-arrow.svg'
import styles from './styles.module.scss'

const sort = (collection, column, direction, sortFunctions) => {
  if (column === null || column === undefined) return collection
  let func = sortFunctions[column]

  // use slice(0) to copy collection because sort() works in-place
  return func ? collection.slice(0).sort(func) : collection.slice(0).sort((a, b) => {
    let valueA = a[column]
    let valueB = b[column]

    if (valueA < valueB) {
      return direction === DESC ? 1 : -1
    } else if (valueA > valueB) {
      return direction === DESC ? -1 : 1
    } else {
      return 0
    }
  })
}

const ALIGNMENT_CLASSES = {
  [RIGHT]: styles.rightAligned
}

const TableBody = ({collection, sortColumn, sortDirection, sortFunctions, columnAlignments = {}, onClick}) => {
  const sortedCollection = sort(collection, sortColumn, sortDirection, sortFunctions)

  const handleClick = sortedRow => {
    // onClick will expect the row index of the collection passed in (unsorted)
    const item = sortedCollection[sortedRow]
    const unsortedRow = collection.indexOf(item)
    onClick(unsortedRow)
  }

  return (
    <tbody className={styles.tableBody}>
      {sortedCollection.map((item, row) => (
        <tr key={row}>
          {item.map((value, column) => {
            let classes = []
            if (columnAlignments[column]) classes.push(ALIGNMENT_CLASSES[columnAlignments[column]])

            if (onClick && column === 0) {
              classes.push(styles.clickable)
              return (
                <th key={column} className={classes.join(' ')} onClick={() => handleClick(row)}>
                  <div className={styles.arrowWrapper}>
                    {value}
                    <img className={styles.arrowIcon} alt='arrow' src={arrowIcon} />
                  </div>
                </th>
              )
            } else {
              return (
                <td key={column} className={classes.join(' ')}>
                  {value}
                </td>
              )
            }
          })}
        </tr>
      ))}
    </tbody>
  )
}

TableBody.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.array).isRequired,
  sortColumn: PropTypes.number,
  sortDirection: PropTypes.oneOf([ASC, DESC]),
  onClick: PropTypes.func
}

export default TableBody
