const WEEKLY = 'Weekly'
const FORTNIGHTLY = 'Fortnightly'
const MONTHLY = 'Monthly'
const YEARLY = 'Yearly'

export {
  WEEKLY,
  FORTNIGHTLY,
  MONTHLY,
  YEARLY
}