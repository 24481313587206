import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const DEFAULT_INDEX = 0

class AttachedButtons extends Component {
  render() {
    let {children, selectedIndex} = this.props
    return (
      <div className={styles.wrapper}>
        {React.Children.map(children, (child, index) => {
          if (selectedIndex === index) {
            let {className = '', ...props} = child.props
            return React.cloneElement(child, {...props, className: `${className} ${styles.active}`})
          } else {
            return child
          }
        })}
      </div>
    )
  }
}

AttachedButtons.propTypes = {
  children: PropTypes.array,
  selectedIndex: PropTypes.number
}

AttachedButtons.defaultProps = {
  selectedIndex: DEFAULT_INDEX
}

export default AttachedButtons
