/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import useEffect from 'hooks/useEffect'
import useImageCrop from 'hooks/useImageCrop'
import useFileUpload from 'hooks/useFileUpload'
import useAccount from 'hooks/useAccount'
import useFileSelection from 'hooks/useFileSelection'
import RequestWrapper from 'components/RequestWrapper'
import FormError from 'components/FormError'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import styles from './styles.module.scss'

const MAX_KB = 250

const BrandLogo = () => {
  const [[prevLogo, logo], setAndRememberLogo] = React.useState([])
  const setLogo = newLogo => setAndRememberLogo([logo, newLogo])
  const [file, setFile] = useFileSelection()
  const [account, accountRequestStatus, refreshAcccount] = useAccount()
  const {cropHandlers, cropArea, cropSrc} = useImageCrop(file && file.type)
  const [uploadedFileSrc, uploadRequestStatuses, uploadFile, clearUpload] = useFileUpload({
    type: 'brand_logo',
    src: cropSrc,
    name: file.name
  })

  useEffect(() => {
    if (uploadedFileSrc) {
      setLogo(uploadedFileSrc)
      refreshAcccount()
    }
  }, [uploadedFileSrc])

  useEffect(() => {
    if (account.brand_logo_url) {
      setLogo(account.brand_logo_url)
    }
  }, [account.brand_logo_url])

  useEffect(() => {
    if (uploadedFileSrc) {
      clearUpload()
    }
  }, [logo])

  const handleChooseLogo = ({target}) => {
    cropHandlers.onCancel()
    setLogo(null)
    setFile(target.files[0])
  }

  const handleCancelCrop = () => {
    cropHandlers.onCancel()
    setLogo(prevLogo)
    setFile(null)
  }

  const ChooseLogoButton = ({text = 'Choose logo'}) => (
    <label className={styles.file}>
      <input type='file' onChange={handleChooseLogo} accept='image/gif,image/jpeg,image/png' />
      {text}
    </label>
  )

  if (logo) {
    return (
      <Fragment>
        <div className={styles.images}>
          <img alt='brand logo' src={logo} />
        </div>
        {file.name}
        <ButtonGroup className={styles.buttons}>
          <ChooseLogoButton text='Change logo' />
        </ButtonGroup>
      </Fragment>
    )
  } else {
    return (
      <RequestWrapper requestStates={[accountRequestStatus]}>
        <RequestWrapper requestStates={uploadRequestStatuses} progressMessage='Your logo is uploading'>
          {file.kilobytes && file.kilobytes > MAX_KB ? (
            <FormError error={`Image size is too large. Must be less than or equal to ${MAX_KB}KB`} />
          ) : file.src ? (
            <div className={styles.images}>
              <ReactCrop {...cropHandlers} src={file.src} crop={cropArea} style={{width: '50%'}} />
              {cropSrc && <img alt='Crop' src={cropSrc} />}
            </div>
          ) : null}
          {file.name}
          <ButtonGroup className={styles.buttons}>
            <ChooseLogoButton />
            {cropSrc && <Button type='button' text='Crop & Upload' secondary onClick={uploadFile} />}
            {file.src && <Button type='button' text='Cancel' link onClick={handleCancelCrop} />}
          </ButtonGroup>
        </RequestWrapper>
      </RequestWrapper>
    )
  }
}

export default BrandLogo
