import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {change, getFormValues, getFormSyncErrors } from 'redux-form'

import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {
  DEFAULT_PROPERTY_TYPES,
  DEFAULT_MIN_BEDROOMS,
  DEFAULT_MAX_BEDROOMS,
  DEFAULT_BEDROOM_MARKS
} from 'constants/defaultFormValues'
import {getPropertyTypes, getBedroomQuantity, shouldResetBedroomQuantity} from 'lib/componentHelpers'
import {fetchMarketRent} from 'actions/marketRentActions'

import AddressFinder from 'components/AddressFinder'
import Bedrooms from 'components/Calculator/Bedrooms'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'
import EstimatedRentalIncome from 'components/Calculator/EstimatedRentalIncome'
import FormRow from 'components/FormRow'
import FormSectionWrapper from 'components/FormSectionWrapper'
import Input from 'components/Input'
import PhotoUploader from 'components/PhotoUploader'
import Popup from 'components/Popup'
import PropertyCondition from 'components/Calculator/PropertyCondition'
import propertyImagePlaceholder from 'assets/static/propertyImagePlaceholder.jpg'

import styles from './styles.module.scss'

const DEFAULT_FORM_OPTIONS = {
  minBedrooms: DEFAULT_MIN_BEDROOMS,
  maxBedrooms: DEFAULT_MAX_BEDROOMS,
  bedroomMarks: DEFAULT_BEDROOM_MARKS,
  propertyTypes: DEFAULT_PROPERTY_TYPES,
}

const PHOTO_INFO = 'Photo data is not available for this property'
const ADDRESS_PROMPT = 'You need to enter an address to see your rental estimates'
const NO_MARKET_RENT_ALERT =
  "Sorry, we don't have any market rent data for this address. Enter your estimates manually."

export class RentalAssessment extends Component {
  constructor() {
    super()
    this.addressfinderRef = React.createRef()
    this.state = {
      alertNoMarketRent: false,
      ...DEFAULT_FORM_OPTIONS,
      uploadedImage: null,
    }
  }

  componentDidMount() {
    let {marketRent, formValues, change} = this.props
    if (marketRent && formValues) {
      this.setState(getPropertyTypes(marketRent))

      let bedroomQuantity = getBedroomQuantity(marketRent, formValues.dwelling)
      this.setState(bedroomQuantity)
      if (bedroomQuantity.minBedrooms === bedroomQuantity.maxBedrooms) {
        change('number_of_bedrooms', bedroomQuantity.minBedrooms)
      }
    }
  }

  componentDidUpdate(prevProps) {
    let { marketRent, marketRentRequestState, formValues, change, onAddressChanged } = this.props

    this.handleGeoLocatorIcon(prevProps)

    if (
      prevProps.marketRent &&
      prevProps.marketRentRequestState.fetched &&
      !marketRent &&
      !marketRentRequestState.fetched
    ) {
      this.setState(DEFAULT_FORM_OPTIONS)
    }

    if (this.noMarketRentFound(prevProps.marketRentRequestState)) {
      change('weekly_rental_income_lower', 0)
      change('weekly_rental_income_upper', 0)
      change('yearly_rental_income_lower', 0)
      change('yearly_rental_income_upper', 0)
      this.setState(DEFAULT_FORM_OPTIONS)
      this.setState({alertNoMarketRent: true})
    }

    if (this.marketRentHasChanged(prevProps.marketRent)) {
      this.setState(getPropertyTypes(marketRent))
      this.setState(getBedroomQuantity(marketRent, formValues.dwelling))
    } else if (this.dwellingHasChanged(prevProps.formValues)) {
      this.setState(getBedroomQuantity(marketRent, formValues.dwelling))
    }

    // when the address has been changed
    // clear uploaded photo
    // a new calculation is started and the currently loaded one will be deleted on save
    if (this.addressHasChanged(prevProps.formValues.address)) {
      this.setState({ uploadedImage: null })
      change('property_photo_url', null)
      onAddressChanged(this.props.calculationID)
    }
  }

  handleGeoLocatorIcon = prevProps => {
    let { formErrors = {}, formValues = {}, premium,  coreLogicRequestState, flash } = this.props
    let { formErrors: prevFormErrors = {}, premium: prevPremium,  coreLogicRequestState: prevCoreLogicRequestState, flash: prevFlash = {} } = prevProps
    const addressPromptChanged = formErrors.property !== prevFormErrors.property
    const premiumStatusChanged = premium !== prevPremium
    const displayingInfoBanner = (coreLogicRequestState !== prevCoreLogicRequestState) && !formValues.image_url
    const flashChanged = flash.success || prevFlash.success

    if (addressPromptChanged || premiumStatusChanged || displayingInfoBanner || flashChanged ) {
      // This is a quick fix for a bug in AddressFinder where the icon doesn't respond
      // well with changes to the DOM (e.g. an element is hidden or removed).
      // Reset icon positioning when we know the ADDRESS_PROMPT warning changes the layout.
      this.addressfinderRef.current.resetIconPosition()
    }
  }

  noMarketRentFound = prevMarketRentRequestState => {
    let {marketRentRequestState, marketRent} = this.props
    return (
      prevMarketRentRequestState !== marketRentRequestState &&
      marketRentRequestState.fetched === true &&
      marketRent &&
      marketRent.length === 0
    )
  }

  marketRentHasChanged = prevMarketRent => {
    let {marketRent} = this.props
    return marketRent && marketRent !== prevMarketRent && marketRent.length
  }

  dwellingHasChanged = prevFormValues => {
    let {marketRent, formValues} = this.props
    return (
      marketRent &&
      marketRent.length &&
      (formValues && prevFormValues) &&
      shouldResetBedroomQuantity(formValues, prevFormValues)
    )
  }

  addressHasChanged = previousAddress => {
    return previousAddress && previousAddress !== this.props.formValues.address
  }

  handleAddressFinderSelection = (selection, metadata) => {
    let { change, fetchMarketRent } = this.props
    let { sa2_id, meshblock } = metadata
    let suburb = selection.suburb()
    let city = selection.city()

    fetchMarketRent(sa2_id, meshblock, suburb, city)
    change('sa2_code', sa2_id)
    change('meshblock', meshblock)
    change('suburb', suburb)
    change('city', city)
    change('address', metadata.a)
    change('property', metadata.a)
  }

  handleWarningClick = () => {
    this.addressfinderRef.current.focus()
  }

  handleAlertDismissal = () => {
    this.setState({alertNoMarketRent: false})
  }

  handlePhotoUploaded = photo => {
    this.setState({uploadedImage: photo})
  }

  // reposition icon when image has actually loaded
  handleImageLoaded = () => {
    this.addressfinderRef.current.resetIconPosition()
  }

  propertyImage = () => {
    let { formValues: { property_photo_url }, formValues: { image_url } } = this.props
    return this.state.uploadedImage || property_photo_url || image_url || null
  }

  render() {
    let { alertNoMarketRent, minBedrooms, maxBedrooms, bedroomMarks, propertyTypes } = this.state || {}
    let {formValues, formErrors, children, coreLogicRequestState, account, calculationID } = this.props || {}

    let warning = formErrors && formErrors.property ? ADDRESS_PROMPT : null
    let info = !this.propertyImage() && coreLogicRequestState.fetched ? PHOTO_INFO : null

    // The 'Add&zwnj;ress' in inputPlaceholder is a hack to prevent Safari autofill from recognising this field
    // as an address field. It uses the label, placeholder, field name and id to determine whether the field should be
    // filled (based on the word 'address'), and doesn't allow this behaviour to be disabled.
    // The &zwnj; character can't be seen by the user, but tricks Safari into thinking there are no address fields.

    return (
      <FormSectionWrapper
        heading='Rental Assessment'
        warning={warning}
        info={info}
        warningOnClick={this.handleWarningClick}>
        <form autoComplete='off'>
          <FormRow type={warning || info ? 'row-with-image-and-warning' : 'row-with-image'}>
            <AddressFinder
              inputName='property'
              inputPlaceholder='Enter Add&zwnj;ress'
              onSelect={this.handleAddressFinderSelection}
              ref={this.addressfinderRef}
            />
            <div className={styles.imageContainer}>
              <img
                alt='property'
                src={this.propertyImage() || propertyImagePlaceholder}
                className={styles.propertyImg}
                onLoad={this.handleImageLoaded}
              />
              {account && account.premium  && calculationID && ( // allow premium user to upload photo on saved calculations
                <PhotoUploader
                  onPhotoUploaded={this.handlePhotoUploaded}
                  calculationID={calculationID}
                />
              )}
            </div>
          </FormRow>

          <Input hidden={true} name='image_url' type='string' />
          <Input hidden={true} name='suburb' type='string' />
          <Input hidden={true} name='city' type='string' />
          <Input hidden={true} name='meshblock' type='string' />
          <Input hidden={true} name='sa2_code' type='string' />

          {formValues ? (
            <Fragment>
              <Popup isOpen={alertNoMarketRent}>
                <ContentSection className={styles.popupContent}>
                  <p>{NO_MARKET_RENT_ALERT}</p>
                  <ButtonGroup>
                    <Button text='OK' onClick={this.handleAlertDismissal} />
                  </ButtonGroup>
                </ContentSection>
              </Popup>
              <Bedrooms minBedrooms={minBedrooms} maxBedrooms={maxBedrooms} bedroomMarks={bedroomMarks} />
              <PropertyCondition propertyTypes={propertyTypes} />
              <EstimatedRentalIncome />
            </Fragment>
          ) : null}
        </form>
        {children}
      </FormSectionWrapper>
    )
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  formErrors: getFormSyncErrors(CALCULATOR_FORM_NAME)(state),
  account: state.account.data,
  marketRent: state.marketRent.data,
  marketRentRequestState: state.marketRent.requestState,
  coreLogicRequestState: state.coreLogic.requestState,
  flash: state.flash,
})

const mapDispatchToProps = {
  change: (fieldName, fieldValue) => change(CALCULATOR_FORM_NAME, fieldName, fieldValue),
  fetchMarketRent,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalAssessment)
