import React from 'react'
import styles from './styles.module.scss'

const Banner = ({type, children, onClick, icon, className}) => {
  let classes = styles.banner
  if (type) classes += ` ${styles[type]}`
  if (onClick) classes += ` ${styles.clickable}`

  return (
    <div className={classes} onClick={onClick}>
      {icon && <img className={styles.icon} alt='banner icon' src={icon} />}
      <div className={className || ''}>{children}</div>
    </div>
  )
}

export default Banner
