const shouldCalculateRentalYield = (formValues, prevValues) => {
  return formValues.property && formValues.purchase_price &&
         (formValues.purchase_price !== prevValues.purchase_price ||
         formValues.weekly_rental_income !== prevValues.weekly_rental_income ||
         formValues.total_expenses !== prevValues.total_expenses)
}

const shouldCalculateLoanPayment = (formValues, prevValues) => {
  return formValues.purchase_price !== 0 && formValues.interest_rate !== 0 &&
         (prevValues.purchase_price !== formValues.purchase_price ||
         prevValues.deposit !== formValues.deposit ||
         prevValues.loan_duration !== formValues.loan_duration ||
         prevValues.interest_rate !== formValues.interest_rate ||
         prevValues.loan_payment_term !== formValues.loan_payment_term ||
         prevValues.repayment_type !== formValues.repayment_type)
}

const shouldCalculateReturn = (formValues, prevValues) => {
  return formValues.weekly_rental_income !== prevValues.weekly_rental_income ||
         formValues.total_expenses !== prevValues.total_expenses
}

export {
  shouldCalculateRentalYield,
  shouldCalculateLoanPayment,
  shouldCalculateReturn
}