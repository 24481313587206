import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {reduxForm, getFormValues, getFormSubmitErrors} from 'redux-form'
import {EDIT_ACCOUNT_FORM_NAME} from 'constants/reduxFormConfig'
import {fetchAccount, editAccount} from 'actions/accountActions'
import DefaultLayout from 'components/layouts/DefaultLayout'
import subscriptionManageable from 'components/hoc/subscriptionManageable'
import FormRow from 'components/FormRow'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import RequestWrapper from 'components/RequestWrapper'
import FormField from 'components/FormField'
import FormError from 'components/FormError'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'
import {getRequestStatus, IN_PROGRESS} from 'lib/selectors/getRequestStatus'
import {throwSubmissionError} from 'lib/errorUtils'
import styles from './styles.module.scss'

export class EditAccount extends Component {
  componentDidMount() {
    let {fetchAccount, fetchAccountRequestState} = this.props
    if (fetchAccountRequestState !== IN_PROGRESS) {
      fetchAccount()
    }
  }

  handleEdit = () => {
    let {editAccount, formValues, history} = this.props
    return editAccount(formValues)
      .then(() => history.push('/profile'))
      .catch(throwSubmissionError)
  }

  render() {
    let {
      editAccountRequestState,
      handleSubmit,
      submitError,
      handleUncancelSubscription,
      handleCancelSubscription,
      initialValues
    } = this.props
    return (
      <DefaultLayout pageTitle='Edit account'>
        <ContentSection>
          <BackButton />
          <form className={styles.formContainer} onSubmit={handleSubmit(this.handleEdit)}>
            <RequestWrapper requestStates={[editAccountRequestState]}>
              <FormRow label='Email'>
                <FormField name='email' type='email' />
              </FormRow>
              <FormRow label='Name'>
                <FormField name='name' type='string' />
              </FormRow>
              <FormError error={submitError} />
              <ButtonGroup>
                {initialValues && initialValues.premium && initialValues.premium_subscription_cancelled ? (
                  <Button text='Cancel your downgrade' type='button' onClick={handleUncancelSubscription} link />
                ) : initialValues && initialValues.premium ? (
                  <Button text='Downgrade' type='button' onClick={handleCancelSubscription} link />
                ) : null}
                <Button text='Edit Account' type='submit' />
              </ButtonGroup>
            </RequestWrapper>
          </form>
        </ContentSection>
      </DefaultLayout>
    )
  }
}

const mapDispatchToProps = {fetchAccount, editAccount}
const mapStateToProps = state => ({
  fetchAccountRequestState: getRequestStatus(state.account.fetchAccountRequestState),
  editAccountRequestState: getRequestStatus(state.account.editAccountRequestState),
  initialValues: state.account.data,
  formValues: getFormValues(EDIT_ACCOUNT_FORM_NAME)(state),
  submitError: getFormSubmitErrors(EDIT_ACCOUNT_FORM_NAME)(state).error
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({form: EDIT_ACCOUNT_FORM_NAME}),
  subscriptionManageable
)(EditAccount)
