import React from 'react'
import PremiumLink from 'components/PremiumLink'
import badgeIcon from 'assets/static/icons/badge.svg'
import styles from './styles.module.scss'

export const PremiumTag = ({text = 'Upgrade to Premium'}) => {
  return (
    <PremiumLink>
      <span className={styles.premiumTag}>
        <img alt='premium' src={badgeIcon} />
        {text}
      </span>
    </PremiumLink>
  )
}

PremiumTag.displayName = 'PremiumTag'

export default PremiumTag
