import React from 'react'
import {useSelector} from 'react-redux'
import {getFormValues} from 'redux-form'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {parse} from 'lib/componentHelpers'
import {CURRENCY, METERS_SQUARED} from 'constants/formFieldTypes'
import FormSectionWrapper from 'components/FormSectionWrapper'
import FlexFormRows from 'components/FlexFormRows'
import FormRow from 'components/FormRow'
import Input from 'components/Input'

const DATA_INFO = 'Property information data is not available for this property'
const EXPECTED_VALUES = [
  'beds',
  'baths',
  'car_spaces',
  'year_built',
  'last_sale_value',
  'last_sale_date',
  'units_of_use',
  'land_area',
  'floor_area',
  'site_cover',
  'wall_material',
  'deck',
  'pool'
]

const missingSomeExpectedValues = formValues => EXPECTED_VALUES.some(key => formValues[key] == null)

const mapState = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  coreLogicRequestState: state.coreLogic.requestState
})

const PropertyInformation = ({children}) => {
  const {formValues, coreLogicRequestState} = useSelector(mapState)

  let info =
    formValues && missingSomeExpectedValues(formValues) && coreLogicRequestState.fetched === true ? DATA_INFO : null

  return (
    <FormSectionWrapper heading='Property Information' info={info} isAccordion isPremium>
      <FlexFormRows>
        <FormRow label='Beds' type='inline'>
          <Input name='beds' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Baths' type='inline'>
          <Input name='baths' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Car Spaces' type='inline'>
          <Input name='car_spaces' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Year Built' type='inline'>
          <Input name='year_built' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Last Sale Value' type='inline'>
          <Input name='last_sale_value' type='string' disabled={true} inputLabelType={CURRENCY} parse={parse} />
        </FormRow>
        <FormRow label='Last Sale Date' type='inline'>
          <Input name='last_sale_date' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Units of Use' type='inline'>
          <Input name='units_of_use' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Land Area' type='inline'>
          <Input name='land_area' type='string' disabled={true} inputLabelType={METERS_SQUARED} />
        </FormRow>
        <FormRow label='Floor Area' type='inline'>
          <Input name='floor_area' type='string' disabled={true} inputLabelType={METERS_SQUARED} />
        </FormRow>
        <FormRow label='Site Cover' type='inline'>
          <Input name='site_cover' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Wall Material' type='inline'>
          <Input name='wall_material' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Deck' type='inline'>
          <Input name='deck' type='string' disabled={true} />
        </FormRow>
        <FormRow label='Pool' type='inline'>
          <Input name='pool' type='string' disabled={true} />
        </FormRow>
      </FlexFormRows>
      {children}
    </FormSectionWrapper>
  )
}

export default PropertyInformation
