import React, {Component} from 'react'
import { Field } from 'redux-form'
import {connect} from 'react-redux'
import {getFormValues, getFormInitialValues, change} from 'redux-form'
import {CURRENCY, PERCENTAGE} from 'constants/formFieldTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {YEARLY} from 'constants/loanPaymentTermTypes'
import {calculateInterest, parse} from 'lib/componentHelpers'
import FormSectionWrapper from 'components/FormSectionWrapper'
import Input from 'components/Input'
import FlexFormRows from 'components/FlexFormRows'
import FormRow from 'components/FormRow'
import styles from './styles.module.scss'

const CONTINGENCY_TOOLTIP = `A contingency is an amount set aside as an emergency fund for any
                            unforeseeable problems with the property; for example,
                            a broken water pipe.`
const VACANCY_TOOLTIP = `We recommend two weeks’ vacancy in case of repairs and maintenance to
                        be carried out between tenancies or if the property takes longer to
                        rent than expected.`
const RATES_TOOLTIP = `To obtain rates information, most council’s will have a property
                      search function on their website.`
const MANAGEMENT_TOOLTIP = `This is the fee charged by a property manager based on a
                            percentage of rent received to professionally manage your investment
                            property. There could be other fees including inspection fees
                            and advertising fees.  Estimate the percentage of fees charged.
                            8.5% is a guide.`

const INTEREST_TOOLTIP = `Interest is calculated for a one year period only`

const NO_INPUT_WARNING = `Please fill in all expenses to get an accurate calculation`

const EXPENSE_FIELDS = [
  'insurance_cost',
  'repairs_maintenance',
  'body_corporate_fees',
  'contingency',
  'vacancy_cost',
  'property_management_fees',
  'property_management_expense',
  'weekly_rental_income',
  'rates',
  'interest',
  'include_interest_in_calc'
]

export class Expenses extends Component {
  componentDidMount() {
    this.updateInterestExpense()
  }

  componentDidUpdate(prevProps) {
    let {formValues: prevFormValues} = prevProps
    let {formValues} = this.props

    let valuesHaveChangedFor = keys =>
      keys.some(key => formValues && prevFormValues && formValues[key] !== prevFormValues[key])

    if (valuesHaveChangedFor(['property_management_fees', 'weekly_rental_income'])) {
      this.updatePropertyManagementExpense()
    }

    if (valuesHaveChangedFor(['weekly_rental_income'])) {
      this.updateVacancyCost()
    }

    if (valuesHaveChangedFor(['purchase_price', 'deposit', 'interest_rate', 'loan_payment_term', 'loan_duration'])) {
      this.updateInterestExpense()
    }

    if (valuesHaveChangedFor(EXPENSE_FIELDS)) {
      this.updateTotalExpenses()
    }
  }

  getPropertyManagementExpense = () => {
    let {formValues} = this.props
    return formValues.weekly_rental_income * 52 * (formValues.property_management_fees / 100)
  }

  updateTotalExpenses = () => {
    let {formValues, change} = this.props
    let {insurance_cost, repairs_maintenance, rates, body_corporate_fees, contingency, vacancy_cost, interest, include_interest_in_calc} = formValues

    let bodyCorporate = this.shouldRenderBodyCorporateFees() ? body_corporate_fees : 0
    let propertyManagement = this.shouldRenderPropertyManagementExpense() ? this.getPropertyManagementExpense() : 0
    let interestExpense = include_interest_in_calc ? interest : 0

    let totalExpenses = [
      insurance_cost,
      repairs_maintenance,
      rates,
      contingency,
      vacancy_cost,
      propertyManagement,
      bodyCorporate,
      interestExpense
    ].reduce((sum, v) => sum + Number(v), 0)

    change('total_expenses', Math.round(totalExpenses))
  }

  updatePropertyManagementExpense = () => {
    let {change} = this.props
    change('property_management_expense', Math.round(this.getPropertyManagementExpense()))
  }

  updateVacancyCost = () => {
    let {formValues, change} = this.props
    if (formValues.vacancy_cost === 0) {
      change('vacancy_cost', Math.round(formValues.weekly_rental_income * 2))
    }
  }

  updateInterestExpense = () => {
    let {formValues, change} = this.props
    if (formValues) {
      let {purchase_price, deposit, interest_rate, loan_duration} = formValues
      let loan_payment_term = YEARLY
      let {interestOnly} = calculateInterest(purchase_price, deposit, interest_rate, loan_payment_term, loan_duration)
      if (interestOnly !== formValues.interest) change('interest', interestOnly)
    }
  }

  shouldRenderBodyCorporateFees = () => {
    let {formValues = {}} = this.props
    return formValues.dwelling === 'Apartment'
  }

  shouldRenderPropertyManagementExpense = () => {
    let {formValues = {}} = this.props
    return formValues.property_management_fees && formValues.weekly_rental_income
  }

  shouldRenderNoInputWarning = () => {
    let {formValues} = this.props
    return (
      !formValues ||
      formValues.insurance_cost === 0 ||
      formValues.repairs_maintenance === 0 ||
      formValues.rates === 0 ||
      formValues.contingency === 0 ||
      formValues.vacancy_cost === 0
    )
  }

  handleWarningClick() {
    document.getElementsByName('property')[0].focus()
  }

  handleCheckboxChange = (e) => {
    let value = e.target.value
    value === 'false' ? this.updateInterestExpense() : this.props.change('interest', '0.0')
  }

  render() {
    let {children} = this.props

    let warning = this.shouldRenderNoInputWarning() ? NO_INPUT_WARNING : null

    return (
      <FormSectionWrapper
        heading='Annual Expenses'
        warning={warning}
        warningOnClick={this.handleWarningClick}
        isAccordion>
        <FlexFormRows>
          <FormRow label='Insurance' type='inline'>
            <Input name='insurance_cost' type='string' inputLabelType={CURRENCY} parse={parse} />
          </FormRow>
          <FormRow label='Repairs and Maintenance' type='inline'>
            <Input name='repairs_maintenance' type='string' inputLabelType={CURRENCY} parse={parse} />
          </FormRow>
          <FormRow label='Rates' type='inline' toolTipText={RATES_TOOLTIP}>
            <Input name='rates' type='string' inputLabelType={CURRENCY} parse={parse} />
          </FormRow>
          {this.shouldRenderBodyCorporateFees() ? (
            <FormRow label='Body Corporate Fees' type='inline'>
              <Input name='body_corporate_fees' type='string' inputLabelType={CURRENCY} parse={parse} />
            </FormRow>
          ) : null}
          <FormRow label='Property Management Fees' type='inline' toolTipText={MANAGEMENT_TOOLTIP}>
            <Input name='property_management_fees' type='string' inputLabelType={PERCENTAGE} />
          </FormRow>
          {this.shouldRenderPropertyManagementExpense() ? (
            <FormRow label='Property Management Expense' type='inline'>
              <Input
                name='property_management_expense'
                type='string'
                inputLabelType={CURRENCY}
                disabled={true}
                parse={parse}
              />
            </FormRow>
          ) : null}
          <FormRow label='Contingency' type='inline' toolTipText={CONTINGENCY_TOOLTIP}>
            <Input name='contingency' type='string' inputLabelType={CURRENCY} parse={parse} />
          </FormRow>
          <FormRow label='Vacancy' type='inline' toolTipText={VACANCY_TOOLTIP}>
            <Input name='vacancy_cost' type='string' inputLabelType={CURRENCY} parse={parse} />
          </FormRow>
          <FormRow label='Total Expenses' type='inline'>
            <Input name='total_expenses' type='string' inputLabelType={CURRENCY} disabled={true} parse={parse} />
          </FormRow>
          <div className={styles.fullWidthRow}>
            <FormRow label='Interest' type='inline' toolTipText={INTEREST_TOOLTIP}>
              <Input name='interest' type='string' inputLabelType={CURRENCY} disabled={true} parse={parse} />
            </FormRow>
            <div className={styles.labelRow}>
              <label className={styles.checkboxContainer}>
                <Field name='include_interest_in_calc' component='input' type='checkbox' onChange={this.handleCheckboxChange}/>
                <span className={styles.checkmark}></span>
              </label>
              <p>Include annual interest in Net Rental yield calculation.</p>
            </div>
          </div>
        </FlexFormRows>
        {children}
      </FormSectionWrapper>
    )
  }
}

const mapDispatchToProps = {
  change: (name, value) => change(CALCULATOR_FORM_NAME, name, value)
}

const mapStateToProps = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  initialValues: getFormInitialValues(CALCULATOR_FORM_NAME)(state)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Expenses)
