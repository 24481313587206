import React, {Component} from 'react'
import ellipsisIcon from 'assets/static/icons/ellipsis-v.svg'
import styles from './styles.module.scss'

class OptionsMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false}
  }

  toggleMenu = () => {
    this.setState(prevState => ({isOpen: !prevState.isOpen}))
  }

  // children can have onClick handlers; and we
  // want OptionsMenu to close when they're triggered
  wrapClickHandlerFor = child => {
    if (child.props.onClick) {
      return React.cloneElement(child, {
        ...child.props,
        onClick: e => {
          this.toggleMenu()
          child.props.onClick(e)
        }
      })
    } else {
      return child
    }
  }

  render() {
    let {children} = this.props
    let {isOpen} = this.state
    return (
      <div className={styles.container}>
        {isOpen && <div className={styles.overlay} onClick={this.toggleMenu} />}
        <div className={styles.ellipsisIcon} onClick={this.toggleMenu}>
          <img alt='delete' src={ellipsisIcon} />
        </div>
        {isOpen && (
          <ul className={styles.menu}>
            {React.Children.map(children, (child, index) => (
              <li key={index} className={styles.menuItem}>
                {this.wrapClickHandlerFor(child)}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

export default OptionsMenu
