import React, {Component} from 'react'
import {compose} from 'redux'
import {reduxForm, getFormValues, getFormSubmitErrors} from 'redux-form'
import {connect} from 'react-redux'
import {EDIT_PASSWORD_FORM_NAME, editPasswordFormValidation} from 'constants/reduxFormConfig'
import {editPassword} from 'actions/accountActions'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import {throwSubmissionError} from 'lib/errorUtils'
import DefaultLayout from 'components/layouts/DefaultLayout'
import FormRow from 'components/FormRow'
import Button from 'components/Button'
import RequestWrapper from 'components/RequestWrapper'
import FormField from 'components/FormField'
import FormError from 'components/FormError'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'
import styles from './styles.module.scss'

export class EditPassword extends Component {
  handleEditPassword = () => {
    let {formValues, history, editPassword} = this.props
    return editPassword(formValues)
      .then(() => history.push('/profile'))
      .catch(throwSubmissionError)
  }

  render() {
    let {editPasswordRequestState, handleSubmit, submitError} = this.props
    return (
      <DefaultLayout pageTitle='Edit password'>
        <ContentSection>
          <BackButton />
          <form className={styles.formContainer} onSubmit={handleSubmit(this.handleEditPassword)}>
            <RequestWrapper requestStates={[editPasswordRequestState]}>
              <FormRow label='Current password'>
                <FormField name='current_password' autoComplete='current_password' type='password' />
              </FormRow>
              <FormRow label='New password'>
                <FormField name='password' autoComplete='new_password' type='password' />
              </FormRow>
              <FormRow label='Confirm password'>
                <FormField name='password_confirmation' autoComplete='password_confirmation' type='password' />
              </FormRow>
              <FormError error={submitError} />
              <Button text='Edit password' type='submit' />
            </RequestWrapper>
          </form>
        </ContentSection>
      </DefaultLayout>
    )
  }
}

const mapDispatchToProps = {editPassword}
const mapStateToProps = state => ({
  formValues: getFormValues(EDIT_PASSWORD_FORM_NAME)(state),
  editPasswordRequestState: getRequestStatus(state.account.editPasswordRequestState),
  submitError: getFormSubmitErrors(EDIT_PASSWORD_FORM_NAME)(state).error
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: EDIT_PASSWORD_FORM_NAME,
    validate: editPasswordFormValidation
  })
)(EditPassword)
