import React from 'react'
import {IN_PROGRESS} from 'lib/selectors/getRequestStatus.js'
import CometSpinLoader from 'components/SpinLoader'
import styles from './styles.module.scss'

const Button = ({text, className, link, secondary, outline, requestStatus, ...props}) => {
  let classes = [className || '']
  if (secondary) {
    classes.push(styles.secondaryButton)
  } else if (outline) {
    classes.push(styles.outlineButton)
  } else if (link) {
    classes.push(styles.linkButton)
  } else {
    classes.push(styles.button)
  }

  if (requestStatus === IN_PROGRESS) {
    return (
      <button {...props} className={classes.join(' ')} disabled>
        {text} <CometSpinLoader color='#0B142D' size={10} className={styles.loader} />
      </button>
    )
  } else {
    return (
      <button {...props} className={classes.join(' ')}>
        {text}
      </button>
    )
  }
}

export default Button
