import React, {Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'
import {createLocation} from 'history'

import About from 'components/About'
import AboutUs from 'components/AboutUs'
import Calculations from 'components/Calculations'
import CalculationsCompared from 'components/CalculationsCompared'
import Calculator from 'components/Calculator'
import CreateAccount from 'components/CreateAccount'
import EditAccount from 'components/EditAccount'
import EditPassword from 'components/EditPassword'
import ForgotPassword from 'components/ForgotPassword'
import HomeScreen from 'components/HomeScreen'
import Landing from 'components/Landing'
import Login from 'components/Login'
import Plans from 'components/Plans'
import PremiumPopup from 'components/PremiumPopup'
import PremiumRoute from 'components/Routes/PremiumRoute'
import PrivacyPolicy from 'components/PrivacyPolicy'
import Profile from 'components/Profile'
import PublicRoute from 'components/Routes/PublicRoute'
import ResetPassword from 'components/ResetPassword'
import Support from 'components/Support'
import TermsAndConditions from 'components/TermsAndConditions'

export const Routes = ({location}) => {
  // This is a hack for the HashRouter to force a decode of the current
  // path so that the app can identify encoded hashes (%23 = #).
  // Specifically so that we can link to /#/termsandconditions%23corelogic
  // (see the TermsAndConditions component for handling of the hash link).
  const rawPathname = window.location.hash.replace(/^#/, '')
  const newLocation = createLocation(decodeURIComponent(rawPathname), location.state)
  return (
    <Fragment>
      <Route path='/*/premiumpopup' component={PremiumPopup} />
      <Switch location={newLocation}>
        <PublicRoute exact path='/' component={Landing} />
        <PublicRoute exact path='/home' component={HomeScreen} />
        <PublicRoute path='/about-us' component={AboutUs} />
        <PublicRoute path='/signin' component={Login} />
        <PublicRoute path='/signup' component={CreateAccount} />
        <PublicRoute path='/about' component={About} />
        <PublicRoute path='/termsandconditions' component={TermsAndConditions} />
        <PublicRoute path='/privacypolicy' component={PrivacyPolicy} />
        <PublicRoute path='/support' component={Support} />
        <PublicRoute path='/resetpassword/:id' component={ResetPassword} />
        <PublicRoute path='/forgotpassword' component={ForgotPassword} />
        <PublicRoute path='/plans' component={Plans} />

        <PremiumRoute path='/calculations/compare' exact component={CalculationsCompared} />

        <PremiumRoute path='/calculator/:id' component={Calculator} />
        <PremiumRoute path='/calculator' component={Calculator} />
        <PremiumRoute path='/editpassword' component={EditPassword} />
        <PremiumRoute path='/editaccount' component={EditAccount} />
        <PremiumRoute path='/profile' component={Profile} />
        <PremiumRoute path='/calculations' component={Calculations} />
      </Switch>
    </Fragment>
  )
}

const RootRoute = () => <Route path='/' component={Routes} />
export default RootRoute
