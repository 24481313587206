import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import TopNav, {TopNavIcon, TopNavLink} from 'components/TopNav'
import ContentContainer from 'components/ContentContainer'
import SideMenu from 'components/SideMenu'
import Flash from 'components/Flash'
import Footer from 'components/Footer'
import Overlay from 'components/Overlay'
import menuIcon from 'assets/static/icons/menu.svg'
import styles from './styles.module.scss'

export const CalculatorLayout = ({match, children, onSubmit}) => {
  const [drawOpen, setDrawOpen] = React.useState(false)
  const toggleDraw = () => setDrawOpen(!drawOpen)
  const buttonText = match.params.id ? 'Update' : 'Save'
  return (
    <Fragment>
      <TopNav>
        <TopNavIcon icon={menuIcon} onClick={toggleDraw} />
        <h1>Calculator</h1>
        <TopNavLink onClick={onSubmit}>{buttonText}</TopNavLink>
      </TopNav>
      <div className={styles.container}>
        <SideMenu isOpen={drawOpen} toggleDraw={toggleDraw} />
        <Flash />
        <ContentContainer>{children}</ContentContainer>
        <Footer />
        <div className={styles.allowOverlay}></div>
        <Overlay/>
      </div>
    </Fragment>
  )
}

CalculatorLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default withRouter(CalculatorLayout)
