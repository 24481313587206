import calculatorIcon from 'assets/static/icons/calculator.svg'
import profileIcon from 'assets/static/icons/profile.svg'
import starIcon from 'assets/static/icons/star.svg'

import whiteCalculatorIcon from 'assets/static/icons/calculator-white.svg'
import whiteProfileIcon from 'assets/static/icons/profile-white.svg'
import whiteStarIcon from 'assets/static/icons/star-white.svg'
import whiteAboutIcon from 'assets/static/icons/about-white.svg'

import activeCalculatorIcon from 'assets/static/icons/calculator-active.svg'
import activeProfileIcon from 'assets/static/icons/profile-active.svg'
import activeStarIcon from 'assets/static/icons/star-active.svg'
import activeAboutIcon from 'assets/static/icons/about-active.svg'

const calculator = {
  to: '/calculator',
  activePaths: ['/calculator', '/calculator/:id'],
  name: 'Calculator',
  activeIcon: activeCalculatorIcon,
  inactiveIcon: whiteCalculatorIcon,
  defaultIcon: calculatorIcon,
  hasCounter: false
}

const calculations = {
  to: '/calculations',
  activePaths: ['/calculations'],
  name: 'Saved',
  activeIcon: activeStarIcon,
  inactiveIcon: whiteStarIcon,
  defaultIcon: starIcon,
  hasCounter: true
}

const profile = {
  to: '/profile',
  activePaths: ['/profile', '/termsandconditions', '/support', '/editpassword', '/privacypolicy', '/editaccount'],
  name: 'Profile',
  activeIcon: activeProfileIcon,
  inactiveIcon: whiteProfileIcon,
  defaultIcon: profileIcon,
  hasCounter: false
}

const about = {
  to: '/about',
  activePaths: ['/about'],
  name: 'About',
  activeIcon: activeAboutIcon,
  inactiveIcon: whiteAboutIcon
}

const buttons = {
  calculator,
  calculations,
  profile,
  about
}

export default buttons
