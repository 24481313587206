import {calculationTypes} from 'actions/types'
import {get, post, put, destroy} from 'lib/reduxHelpers/thunks'
import formatFetchResponse from './formatFetchResponse'
import formatSaveRequest from './formatSaveRequest'

function fetchCalculation(uuid) {
  let baseType = calculationTypes.FETCH_CALCULATION
  let url = `${process.env.REACT_APP_API_URL}/calculations/${uuid}`
  let extractPayload = response => formatFetchResponse(response.data)
  return get({baseType, url, extractPayload})
}

function saveCalculation(body) {
  let baseType = calculationTypes.SAVE_CALCULATION
  let url = `${process.env.REACT_APP_API_URL}/calculations`
  return post({baseType, url, body: formatSaveRequest(body)})
}

function emailAssessment(body, uuid) {
  let baseType = calculationTypes.SEND_RENTAL_ASSESSEMENT_EMAIL
  let url = `${process.env.REACT_APP_API_URL}/calculations/${uuid}/send_rental_assessment_email`
  return post({baseType, url, body})
}

function editCalculation(body, uuid) {
  let baseType = calculationTypes.EDIT_CALCULATION
  let url = `${process.env.REACT_APP_API_URL}/calculations/${uuid}`
  return put({baseType, url, body: formatSaveRequest(body)})
}

function deleteCalculation(uuid) {
  let baseType = calculationTypes.DELETE_CALCULATION
  let url = `${process.env.REACT_APP_API_URL}/calculations/${uuid}`
  return destroy({baseType, url})
}

export {fetchCalculation, saveCalculation, editCalculation, deleteCalculation, emailAssessment}
