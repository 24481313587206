import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import moment from 'moment'
import subscriptionManageable from 'components/hoc/subscriptionManageable'
import Banner from 'components/Banner'
import Button from 'components/Button'
import badgeIcon from 'assets/static/icons/badge.svg'
import styles from './styles.module.scss'

export const PremiumBanner = ({account, handleUncancelSubscription}) => {
  if (!account || !account.premium) return null

  return (
    <div className={styles.bannerContainer}>
      <Banner type='alert' className={styles.banner} icon={badgeIcon}>
        {account.premium_subscription_cancelled ? (
          <Fragment>
            <p>
              Your account is premium until {moment(account.premium_paid_until, 'YYYY-MM-DD').format('Do MMM YYYY')}
            </p>
            <Button className={styles.link} onClick={handleUncancelSubscription} text='Cancel your downgrade' link />
          </Fragment>
        ) : (
          <Fragment>
            <p>Your account is premium</p>
          </Fragment>
        )}
      </Banner>
    </div>
  )
}

const mapStateToProps = state => ({account: state.account.data})

export default compose(
  connect(mapStateToProps),
  subscriptionManageable
)(PremiumBanner)
