import React from 'react'
import DefaultLayout from 'components/layouts/DefaultLayout'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'
import styles from './styles.module.scss'

const Support = () => {
  return (
    <DefaultLayout pageTitle='Support'>
      <ContentSection>
        <BackButton />
        <h3 className={styles.centerText}>
          Contact us if you have any enquiries! <a href='mailto:info@realesmart.co.nz'>info@realesmart.co.nz</a>
        </h3>
      </ContentSection>
    </DefaultLayout>
  )
}

export default Support
