import React from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

export const Landing = ({ loggedIn }) => {
  if (loggedIn) return <Redirect to='/calculator' />
  else return <Redirect to='/home' />
}

const mapStateToProps = state => ({ loggedIn: state.account.loggedIn })
export default connect(mapStateToProps)(Landing)
