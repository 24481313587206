import {useState} from 'react'

const INIT_STATE = {}

const useFileSelection = () => {
  const [selectedFile, setSelectedFile] = useState(INIT_STATE)
  const onFileSelection = file => {
    if (!file) {
      setSelectedFile(INIT_STATE)
      return
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setSelectedFile({
        src: reader.result,
        name: file.name,
        kilobytes: (file.size / 1024).toFixed(4),
        type: file.type
      })
    })
    reader.readAsDataURL(file)
  }
  return [selectedFile, onFileSelection]
}

export default useFileSelection
