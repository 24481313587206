function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`${baseType}_CANCELLED`]: `${baseType}_CANCELLED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`
  }
}

const accountTypes = {
  ...typesForBaseType('FETCH_ACCOUNT'),
  ...typesForBaseType('CREATE_ACCOUNT'),
  ...typesForBaseType('EDIT_ACCOUNT'),
  ...typesForBaseType('EDIT_PASSWORD'),
  ...typesForBaseType('FORGOT_PASSWORD'),
  ...typesForBaseType('RESET_PASSWORD'),
  ...typesForBaseType('LOGIN'),
  ...typesForBaseType('STRIPE_SESSION'),
  ...typesForBaseType('STRIPE_COUPON'),
  ...typesForBaseType('CANCEL_SUBSCRIPTION'),
  ...typesForBaseType('UNCANCEL_SUBSCRIPTION'),
  LOGOUT: 'LOGOUT'
}

const calculationTypes = {
  ...typesForBaseType('FETCH_CALCULATION'),
  ...typesForBaseType('SAVE_CALCULATION'),
  ...typesForBaseType('EDIT_CALCULATION'),
  ...typesForBaseType('DELETE_CALCULATION'),
  ...typesForBaseType('SEND_RENTAL_ASSESSEMENT_EMAIL')
}

const calculationsTypes = {
  ...typesForBaseType('FETCH_CALCULATIONS')
}

const marketRentTypes = {
  ...typesForBaseType('FETCH_MARKET_RENT'),
  CLEAR_MARKET_RENT: 'CLEAR_MARKET_RENT'
}

const expensesTypes = {
  ...typesForBaseType('FETCH_DEFAULT_CALCULATOR_VALUES')
}

export const uploadTypes = {
  ...typesForBaseType('GET_UPLOAD_URL'),
  ...typesForBaseType('UPLOAD_TO_URL'),
  ...typesForBaseType('CONFIRM_UPLOAD_TO_URL'),
  CLEAR_UPLOAD: 'CLEAR_UPLOAD'
}

export const coreLogicTypes = {
  ...typesForBaseType('FETCH_CORELOGIC')
}

export const flashTypes = {
  CLEAR_FLASH: 'CLEAR_FLASH',
  SET_FLASH: 'SET_FLASH'
}

export {accountTypes, calculationTypes, calculationsTypes, marketRentTypes, expensesTypes}
