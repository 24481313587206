import React from 'react'
import {ASC, RIGHT} from 'constants/directional'
import upIcon from 'assets/static/icons/caret-up.svg'
import downIcon from 'assets/static/icons/caret-down.svg'
import styles from './styles.module.scss'

const ALIGNMENT_CLASSES = {
  [RIGHT]: styles.rightAligned
}

const TableHeader = ({headers, sortColumn, sortDirection, columnAlignments = {}, onClick}) => {
  const isSortable = sortColumn !== null && sortColumn !== undefined
  return (
    <thead className={styles.tableHeader}>
      <tr>
        {headers.map((header, column) => {
          let classes = []
          if (isSortable) classes.push(styles.sortable)
          if (columnAlignments[column]) classes.push(ALIGNMENT_CLASSES[columnAlignments[column]])

          return (
            <th key={column} className={classes.join(' ')} onClick={isSortable ? () => onClick(column) : null}>
              {header}
              {isSortable && sortColumn === column ? (
                <img
                  className={styles.sortIcon}
                  alt={`sort ${sortDirection}`}
                  src={sortDirection === ASC ? downIcon : upIcon}
                />
              ) : null}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
