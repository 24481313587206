const features = {
  free: {
    included: [
      'Quick basic rental appraisals',
      'Mortgage calculator',
      'Annual expenses',
      'Investment return',
      'Yields',
      'Property performance and comparison (one search only)'
    ],
    // we now have no excluded features listed. Am leaving this functionality in case it is needed again in the future
    excluded: []
  },
  premium: {
    included: [
      'Quick advanced rental appraisals',
      'Branded emailable rental appraisals and property report',
      'Mortgage calculator',
      'Annual expenses',
      'Yields',
      'Property performance and comparison (saves multiple property searches)',
      'Market trends (CoreLogic data)',
      'Property information (CoreLogic data)',
      {__html: 'Property photos - <i><strong>Now take your own!</strong></i>'}
    ],
    excluded: []
  }
}

export default features
