import React, {Fragment} from 'react'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {throwSubmissionError} from 'lib/errorUtils'
import {deleteCalculation} from 'actions/calculationActions'
import {calculationsTypes} from 'actions/types'
import useCalculations from 'hooks/useCalculations'
import useAccount from 'hooks/useAccount'
import DefaultLayout from 'components/layouts/DefaultLayout'
import RequestWrapper from 'components/RequestWrapper'
import Popup from 'components/Popup'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'
import AttachedButtons from 'components/AttachedButtons'
import PremiumLink from 'components/PremiumLink'
import OptionsMenu from 'components/OptionsMenu'
import RentalAssessmentEmail from 'components/RentalAssessmentEmail'
import PremiumTag from 'components/PremiumTag'
import listIcon from 'assets/static/icons/list-alt.svg'
import tableIcon from 'assets/static/icons/table.svg'
import arrowIcon from 'assets/static/icons/left-arrow.svg'
import badgeIcon from 'assets/static/icons/badge.svg'
import styles from './styles.module.scss'

const getHumanDate = date => {
  let dateObj = new Date(date)
  moment.locale('en-nz')
  return moment(dateObj).format('ll')
}

const CalculationsListItem = ({readonly, onClick, children}) => {
  const props = {className: styles.calculation, onClick}
  if (readonly) {
    return <PremiumLink {...props}>{children}</PremiumLink>
  } else {
    return <div {...props}>{children}</div>
  }
}

export const Calculations = ({history}) => {
  const dispatch = useDispatch()

  const [confirmingDelete, setConfirmingDelete] = React.useState(false)
  const [emailingAssessment, setEmailingAssessment] = React.useState(false)
  const [calculationID, setCalculationID] = React.useState(null)

  const [calculations, requestStatus] = useCalculations()
  const [account] = useAccount()

  const openDeleteConfirmation = calculationID => {
    setConfirmingDelete(true)
    setCalculationID(calculationID)
  }

  const closeDeleteConfirmation = () => {
    setConfirmingDelete(false)
    setCalculationID(null)
  }

  const openEmailAssessment = calculationID => {
    setEmailingAssessment(true)
    setCalculationID(calculationID)
  }

  const closeEmailAssessment = () => {
    setEmailingAssessment(false)
    setCalculationID(null)
  }

  const handleDelete = () => {
    closeDeleteConfirmation()
    deleteCalculation(calculationID)(dispatch)
      .then(() => dispatch({type: calculationsTypes.CLEAR_FETCH_CALCULATIONS}))
      .catch(throwSubmissionError)
  }

  const handleCalculationClick = calculation => {
    history.push(`/calculator/${calculation.id}`)
  }

  return (
    <DefaultLayout pageTitle='Your saved calculations'>
      <Popup isOpen={emailingAssessment} onClose={closeEmailAssessment} className={styles.emailAssessmentPopup}>
        <RentalAssessmentEmail onSubmit={closeEmailAssessment} calculationID={calculationID} />
      </Popup>
      <Popup isOpen={confirmingDelete}>
        <ContentSection className={styles.deleteConfirmationPopup}>
          <p>{'Are you sure you want to delete this calculation?'}</p>
          <ButtonGroup>
            <Button onClick={handleDelete} text='Yes' />
            <Button onClick={closeDeleteConfirmation} text='No' />
          </ButtonGroup>
        </ContentSection>
      </Popup>
      <RequestWrapper requestStates={[requestStatus]}>
        <ContentSection>
          {!calculations || !calculations.length ? (
            <h3>You don't have any saved calculations yet</h3>
          ) : (
            <Fragment>
              <ButtonGroup>
                <h3>{calculations.length} saved calculations</h3>
                <AttachedButtons>
                  <img className={styles.listIcon} alt='list view' src={listIcon} />
                  <PremiumLink to='/calculations/compare'>
                    <div className={styles.iconContainer}>
                      <img className={styles.tableIcon} alt='table view' src={tableIcon} />
                      {account && !account.premium ? <img className={styles.premiumBadge} alt='premium' src={badgeIcon} /> : null}
                    </div>
                  </PremiumLink>
                </AttachedButtons>
              </ButtonGroup>
              <ul className={styles.savedCalculation}>
                {calculations.map(calculation => (
                  <li key={calculation.id}>
                    <CalculationsListItem
                      readonly={calculation.readonly}
                      onClick={() => handleCalculationClick(calculation)}>
                      <div className={styles.container}>
                        <div>
                          <p className={styles.date}>{getHumanDate(calculation.updated_at)}</p>
                          <p>{calculation.address}</p>
                        </div>
                      </div>
                      {calculation.readonly ? <PremiumTag/> : null}
                      <img className={styles.arrowIcon} alt='redirect to calculation' src={arrowIcon} />
                    </CalculationsListItem>
                    <OptionsMenu>
                      <Button text='Delete' onClick={() => openDeleteConfirmation(calculation.id)} link />
                      <PremiumLink onClick={() => openEmailAssessment(calculation.id)}>
                        <Button text='Email Rental Assessment' link />
                      </PremiumLink>
                    </OptionsMenu>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </ContentSection>
      </RequestWrapper>
    </DefaultLayout>
  )
}

export default withRouter(Calculations)
