import React from 'react'
import styles from './styles.module.scss'

const Nav = ({children, className, right, left}) => {
  let classes = [styles.nav]
  if (className) classes.push(className)
  if (right) classes.push(styles.right)
  if (left) classes.push(styles.left)
  return <div className={classes.join(' ')}>{children}</div>
}

export default Nav
