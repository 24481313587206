import axios from 'lib/axios'
import {setAuthToken} from 'lib/auth'

const request = ({baseType, url, method, body, extractPayload}) => {
  return function(dispatch) {
    dispatch({type: baseType})

    return (method === 'get' ? axios[method](url) : axios[method](url, body))

      .then(response => {
        // SUCCESS
        if (!response) {
          dispatch({type: `${baseType}_CANCELLED`})
        } else {
          let payload = extractPayload ? extractPayload(response) : response.data
          dispatch({type: `${baseType}_FULFILLED`, payload})
          if (response.data.auth_token) setAuthToken(response.data.auth_token)
        }
        return response
      })

      .catch(err => {
        // ERROR
        dispatch({type: `${baseType}_REJECTED`, payload: err})
        throw err
      })
  }
}

export const get = ({...opts}) => request({...opts, method: 'get'})
export const put = ({...opts}) => request({...opts, method: 'put'})
export const post = ({...opts}) => request({...opts, method: 'post'})
export const destroy = ({...opts}) => request({...opts, method: 'delete'})
