import {useSelector, useDispatch} from 'react-redux'
import {getFormValues, getFormSyncErrors, reset} from 'redux-form'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {getRequestStatus} from 'lib/selectors/getRequestStatus.js'
import useEffect from 'hooks/useEffect'
import {fetchDefaultCalculatorValues} from 'actions/expensesActions'
import {fetchCalculation} from 'actions/calculationActions'
import {calculationTypes} from 'actions/types'

const mapState = state => ({
  fetchStatus: getRequestStatus(state.calculation.requestState),
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  formErrors: getFormSyncErrors(CALCULATOR_FORM_NAME)(state)
})

const useCalculation = calculationID => {
  const dispatch = useDispatch()
  const {fetchStatus, formValues, formErrors} = useSelector(mapState)

  useEffect(() => {
    if (calculationID && !fetchStatus) {
      fetchCalculation(calculationID)(dispatch)
    } else if (!calculationID) {
      fetchDefaultCalculatorValues()(dispatch)
    }
    return () => {
      // UNMOUNT clears the formValues & fetched state
      dispatch(reset(CALCULATOR_FORM_NAME))
      dispatch({type: calculationTypes.CLEAR_FETCH_CALCULATION})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {formValues, fetchStatus, formErrors}
}

export default useCalculation
