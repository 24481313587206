import createReducer from 'lib/reducers/createReducer'

function dataHandler(initialState) {
  return (state, action) => {
    return action.payload || initialState
  }
}

function dataReducer({ initialState, baseType }) {
  return createReducer(
    initialState,
    {
      [`${baseType}_FULFILLED`]: dataHandler(initialState),
      [`CLEAR_${baseType}`]: dataHandler(initialState)
    }
  )
}

export default dataReducer
