import React from 'react'
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router-dom'
import PremiumAuthorisation from 'components/PremiumAuthorisation'

export const PremiumRoute = ({component: Component, loggedIn, premium, ...rest}) => {
  const renderComponent = props => {
    if (!loggedIn) {
      return <Redirect to={{pathname: '/signin'}} />
    }
    if (premium) {
      return <Component {...props}/>
    } else {
      return (
        <PremiumAuthorisation {...props}>
          <Component {...props}/>
        </PremiumAuthorisation>
      )
    }
  }
  return <Route {...rest} render={renderComponent}/>
}

const mapStateToProps = ({account}) => ({loggedIn: account.loggedIn, premium: account.data && account.data.premium})
export default connect(mapStateToProps)(PremiumRoute)