import React from 'react'
import {CURRENCY} from 'constants/formFieldTypes'
import {parse} from 'lib/componentHelpers'

import Input from 'components/Input'
import styles from './styles.module.scss'

const RangedRentalIncomeInputs = ({period, disabled, userEstimate, required, min}) => {
  let nameLower
  let nameUpper
  if (userEstimate) {
    nameLower = `estimate_${period}_lower`
    nameUpper = `estimate_${period}_upper`
  } else {
    nameLower = `${period}_rental_income_lower`
    nameUpper = `${period}_rental_income_upper`
  }

  return (
    <div className={styles.displayInline}>
      <Input
        name={nameLower}
        type='string'
        inputLabelType={CURRENCY}
        parse={parse}
        disabled={disabled}
        required={required}
      />
      <p className={styles.joiningText}>to</p>
      <Input
        name={nameUpper}
        type='string'
        inputLabelType={CURRENCY}
        parse={parse}
        min={min}
        disabled={disabled}
        required={required}
      />
    </div>
  )
}

export default RangedRentalIncomeInputs
