/* eslint-disable react-hooks/exhaustive-deps */
import {useDispatch, useSelector} from 'react-redux'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import useEffect from 'hooks/useEffect'
import {confirmUploadToURL, getUploadURL, uploadToURL, clearUpload} from 'actions/uploadActions'

const mapState = state => ({
  params: state.upload.params,
  data: state.upload.data,
  requestStatuses: [
    getRequestStatus(state.upload.getUploadURLState),
    getRequestStatus(state.upload.uploadToURLState),
    getRequestStatus(state.upload.confirmUploadToURLState)
  ]
})

const FileUpload = ({type, src, name, calculationID}) => {
  const dispatch = useDispatch()
  const {params, data, requestStatuses} = useSelector(mapState)

  useEffect(() => {
    if (params && src) {
      uploadToURL({...params, src, name})(dispatch) // STEP 2 upload the file
    }
  }, [params])

  useEffect(() => {
    if (data && data.key) {
      const params = calculationID ? [data.key, calculationID] : [data.key]
      confirmUploadToURL(...params)(dispatch) // STEP 3 confirm the upload with server
    }
  }, [data && data.key])

  const uploadFile = () => getUploadURL(type)(dispatch) // STEP 1 get the upload url
  const clearPhoto = () => clearUpload(type)(dispatch)
  const uploadedFileSrc = data && data.confirmed ? data.url : null
  return [uploadedFileSrc, requestStatuses, uploadFile, clearPhoto]
}

export default FileUpload
