import {useSelector, useDispatch} from 'react-redux'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import useEffect from 'hooks/useEffect'
import {accountTypes} from 'actions/types'
import {fetchAccount} from 'actions/accountActions'

const mapState = state => ({
  account: state.account.data,
  loggedIn: state.account.loggedIn,
  requestStatus: getRequestStatus(state.account.fetchAccountRequestState)
})

const useAccount = () => {
  const dispatch = useDispatch()
  const {account, loggedIn, requestStatus} = useSelector(mapState)

  useEffect(() => {
    if (!requestStatus && loggedIn) fetchAccount()(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus])

  const refresh = () => dispatch({type: accountTypes.CLEAR_FETCH_ACCOUNT})

  return [account, requestStatus, refresh]
}

export default useAccount
