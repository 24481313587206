import {useSelector, useDispatch} from 'react-redux'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import useEffect from 'hooks/useEffect'
import {fetchCalculations} from 'actions/calculationsActions'

const mapState = state => ({
  calculations: state.calculations.data,
  requestStatus: getRequestStatus(state.calculations.requestState)
})

const useCalculations = () => {
  const dispatch = useDispatch()
  const {calculations, requestStatus} = useSelector(mapState)

  useEffect(() => {
    if (!requestStatus) fetchCalculations()(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus])

  return [calculations, requestStatus]
}

export default useCalculations
