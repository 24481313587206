import {WEEKLY, FORTNIGHTLY, MONTHLY, YEARLY} from 'constants/loanPaymentTermTypes'

const round = number => Math.round(number * 100) / 100
const valueAsNumber = value => (typeof value === 'string' ? Number(value.replace(/,\s?/g, '')) : value)

const OCCURANCES_WITHIN_A_YEAR = {
  [WEEKLY]: 52,
  [FORTNIGHTLY]: 26,
  [MONTHLY]: 12,
  [YEARLY]: 1
}

const calculateRentalIncome = (houseCondition, rentalIncomeLower, rentalIncomeUpper) => {
  const houseConditionAsFraction = valueAsNumber(houseCondition) / 100
  const rentalIncomeRange = valueAsNumber(rentalIncomeUpper) - valueAsNumber(rentalIncomeLower)
  const rentalIncome = valueAsNumber(rentalIncomeLower) + houseConditionAsFraction * rentalIncomeRange
  return Math.round(rentalIncome)
}

const calculateAnnualRentalIncome = calculation => {
  let {weekly_rental_income} = calculation
  if (!weekly_rental_income) {
    let {house_condition, weekly_rental_income_lower, weekly_rental_income_upper} = calculation
    weekly_rental_income = calculateRentalIncome(
      house_condition,
      weekly_rental_income_lower,
      weekly_rental_income_upper
    )
  }
  return convertValueByFrequency(valueAsNumber(weekly_rental_income), WEEKLY, YEARLY)
}

const calculateGrossRentalYield = calculation => {
  let {purchase_price} = calculation
  if (valueAsNumber(purchase_price) === 0) return null
  let annualRentalIncome = calculateAnnualRentalIncome(calculation)
  let grossRentalYield = (annualRentalIncome / valueAsNumber(purchase_price)) * 100
  return round(grossRentalYield)
}

const calculateNetRentalYield = calculation => {
  let {purchase_price, total_expenses} = calculation
  if (valueAsNumber(purchase_price) === 0) return null
  let annualRentalIncome = calculateAnnualRentalIncome(calculation)
  let netAnnualRentalIncome = annualRentalIncome - total_expenses
  let netRentalYield = (netAnnualRentalIncome / valueAsNumber(purchase_price)) * 100
  return round(netRentalYield)
}

const calculateReturn = calculation => {
  let {weekly_rental_income, total_expenses} = calculation
  let yearlyRentalIncome = convertValueByFrequency(weekly_rental_income, WEEKLY, YEARLY)
  let yearlyReturn = round(yearlyRentalIncome - total_expenses)
  return {
    yearly: yearlyReturn,
    monthly: convertValueByFrequency(yearlyReturn, YEARLY, MONTHLY),
    weekly: convertValueByFrequency(yearlyReturn, YEARLY, WEEKLY)
  }
}

const calculateInterest = (
  purchase_price,
  deposit,
  interest_rate,
  loan_payment_term,
  loan_duration
) => {
  var borrowedAmount = valueAsNumber(purchase_price) - valueAsNumber(deposit)
  var interestAsDecimal = valueAsNumber(interest_rate) / 100
  var paymentsPerYear = OCCURANCES_WITHIN_A_YEAR[loan_payment_term]

  return {
    interestOnly: calculateInterestOnlyPaymentQuantity(borrowedAmount, interestAsDecimal, paymentsPerYear),
    principalAndInterest: calculatePrincipalAndInterestPaymentQuantity(loan_duration, borrowedAmount, interestAsDecimal, paymentsPerYear)
  }
}

const calculateInterestOnlyPaymentQuantity = (
  borrowedAmount,
  interestAsDecimal,
  paymentsPerYear
) => {
  return Math.round((borrowedAmount * interestAsDecimal) / paymentsPerYear)
}

const calculatePrincipalAndInterestPaymentQuantity = (
  loan_duration,
  borrowedAmount,
  interestAsDecimal,
  paymentsPerYear
) => {
  var payments = valueAsNumber(loan_duration) * paymentsPerYear
  var interest = interestAsDecimal / paymentsPerYear
  var x = Math.pow(1 + interest, payments)
  var value = (borrowedAmount * x * interest) / (x - 1)

  if (!isNaN(value) && value !== Number.POSITIVE_INFINITY && value !== Number.NEGATIVE_INFINITY) {
    return Math.round(value)
  } else {
    return null
  }
}

const convertValueByFrequency = (value, currentFrequency, expectedFrequency) => {
  const currentOccurancesWithinAYear = OCCURANCES_WITHIN_A_YEAR[currentFrequency]
  const expectedOccurancesWithinAYear = OCCURANCES_WITHIN_A_YEAR[expectedFrequency]

  if (!currentOccurancesWithinAYear || !expectedOccurancesWithinAYear) {
    console.error(`unsupported current frequency: ${currentFrequency} or expected frequency: ${expectedFrequency}`)
    return 0
  }

  return (valueAsNumber(value) * currentOccurancesWithinAYear) / expectedOccurancesWithinAYear
}

export {
  calculateRentalIncome,
  calculateGrossRentalYield,
  calculateNetRentalYield,
  calculateInterest,
  convertValueByFrequency,
  calculateReturn
}
