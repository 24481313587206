import {useSelector, useDispatch} from 'react-redux'
import {getFormValues, getFormSyncErrors} from 'redux-form'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {noFormErrors} from 'lib/errorUtils'
import {saveCalculation, editCalculation} from 'actions/calculationActions'
import {calculationsTypes} from 'actions/types'

const mapState = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  formErrors: getFormSyncErrors(CALCULATOR_FORM_NAME)(state)
})

const useCalculationSubmit = calculationID => {
  const dispatch = useDispatch()
  const {formValues, formErrors} = useSelector(mapState)

  return () => {
    if (noFormErrors(formErrors)) {
      let promise
      if (calculationID) {
        promise = editCalculation(formValues, calculationID)(dispatch)
      } else {
        promise = saveCalculation(formValues)(dispatch)
      }
      promise.then(() => dispatch({type: calculationsTypes.CLEAR_FETCH_CALCULATIONS}))
      return promise.then(response => ({error: null, data: response.data}))
    } else {
      return Promise.resolve({error: formErrors && formErrors['property']})
    }
  }
}

export default useCalculationSubmit
