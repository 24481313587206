import React from 'react'
import diff from 'deep-diff'

const useEffectCustom = (callback, dependencies) => {
  const ref = React.useRef()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(
    callback,
    (value => {
      if (diff(value, ref.current)) ref.current = value
      return ref.current
    })(dependencies)
  )
}

export default useEffectCustom
