import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import CometSpinLoader from 'components/SpinLoader'
import {IN_PROGRESS, getRequestStatus} from 'lib/selectors/getRequestStatus'
import {fetchAccount} from 'actions/accountActions'

export class PremiumAuthorisation extends Component {
  componentDidMount = () => {
    let {account, fetchAccount} = this.props
    if (!account || !account.premium) fetchAccount()
  }

  render() {
    let {account, fetchStatus, location, children} = this.props
    if (!fetchStatus || fetchStatus === IN_PROGRESS) {
      return <CometSpinLoader color='#193B85' size={50} />
    } else if (account && account.premium) {
      return children
    } else {
      return <Redirect to={{pathname: '/plans', state: {targetPath: location.pathname}}} />
    }
  }
}

const mapDispatchToProps = {fetchAccount}
const mapStateToProps = ({account}) => ({
  account: account.data,
  fetchStatus: getRequestStatus(account.fetchAccountRequestState)
})

export default connect(mapStateToProps, mapDispatchToProps)(PremiumAuthorisation)
