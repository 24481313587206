import {useRef} from 'react'
import useEffect from 'hooks/useEffect'

const useEffectOnUpdate = (callback, dependencies) => {
  const isMountedRef = useRef(false)
  useEffect(() => {
    if (isMountedRef.current) callback()
    else isMountedRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useEffectOnUpdate
