// Core Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

// Constants
import { CURRENCY } from 'constants/formFieldTypes'
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Components
import Slider from 'components/Slider'
import Input from 'components/Input'
import FormRow from 'components/FormRow'

class Purchase extends Component {

  render() {
    let { formValues, maxPurchasePrice, onChange } = this.props
    return (
      <Fragment>
        <FormRow label={'Estimated Purchase Price'}>
          <Input name='purchase_price' inputLabelType={CURRENCY} type='string' onChange={onChange} />
          <Slider name={'purchase_price'} min={0} max={maxPurchasePrice} step={1000} />
        </FormRow>
        <FormRow label={'Deposit'}>
          <Input name='deposit' inputLabelType={CURRENCY} type='string' onChange={onChange} />
          <Slider name={'deposit'} min={0} max={Number(formValues.purchase_price) || maxPurchasePrice} step={1000} />
        </FormRow>
      </Fragment>
    )
  }
}

export { Purchase }
export default connect(
  state => ({formValues: getFormValues(CALCULATOR_FORM_NAME)(state)})
)(Purchase)
