import React from 'react'
import {useSelector} from 'react-redux'
import {getFormValues} from 'redux-form'
import {CURRENCY, PERCENTAGE} from 'constants/formFieldTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import FormSectionWrapper from 'components/FormSectionWrapper'
import FlexFormRows from 'components/FlexFormRows'
import FormRow from 'components/FormRow'
import Input from 'components/Input'

const DATA_INFO = 'Market trend data is not available for this property'
const EXPECTED_VALUES = ['median_sale_price', 'avm_value_change', 'rental_rate_change']

const missingSomeExpectedValues = formValues => EXPECTED_VALUES.some(key => !formValues[key])

const mapState = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  coreLogicRequestState: state.coreLogic.requestState
})

const MarketTrends = ({children}) => {
  const {formValues, coreLogicRequestState} = useSelector(mapState)

  let info =
    formValues && missingSomeExpectedValues(formValues) && coreLogicRequestState.fetched === true ? DATA_INFO : null

  return (
    <FormSectionWrapper heading='Market Trends' info={info} isAccordion isPremium>
      <FlexFormRows>
        <FormRow label='Median sale price' toolTipText='Median sale price for the last 12 months' type='inline'>
          <Input name='median_sale_price' inputLabelType={CURRENCY} type='string' disabled={true} />
        </FormRow>
        <FormRow
          label='AVM value change'
          toolTipText='Change in average median sales value over previous 5 years'
          type='inline'>
          <Input name='avm_value_change' inputLabelType={PERCENTAGE} type='string' disabled={true} />
        </FormRow>
        <FormRow label='Change in rental rate' toolTipText='Change in rental rate over 12 months' type='inline'>
          <Input name='rental_rate_change' inputLabelType={PERCENTAGE} type='string' disabled={true} />
        </FormRow>
      </FlexFormRows>
      {children}
    </FormSectionWrapper>
  )
}

export default MarketTrends
