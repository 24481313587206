import base64 from 'base-64'
import {clearAuthToken} from 'lib/auth'
import {accountTypes} from 'actions/types'

const checkTokenExpiration = store => next => action => {
  let jwt = localStorage.getItem('realesmart.jwt')
  if (!jwt) {
    next(action)
  } else if (action.type.split('/')[0] === '@@redux-form') { //ignore redux-form actions
    next(action)
  } else {
    let token = JSON.parse(base64.decode(jwt.split('.')[1]))
    let tokenIsExpired = new Date(0).setUTCSeconds(token.exp) <= (new Date())
    if (tokenIsExpired) {
      clearAuthToken()
      next({type: accountTypes.LOGOUT})
    } else {
      next(action)
    }
  }
}

export default checkTokenExpiration