import useEffect from 'hooks/useEffect'
import events from './events'

const useFacebookEvents = ({referrer}) => {
  useEffect(() => {
    if (referrer) {
      let event = events[referrer]
      // Prevents this from blowing up on staging where we have no fb pixel id, so fbq is not initalised 
      if (window.fbq) {
        window.fbq('track', event.type, event.detail)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useFacebookEvents
