import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {getFormValues, change} from 'redux-form'
import {WEEKLY, FORTNIGHTLY, MONTHLY} from 'constants/loanPaymentTermTypes'
import {CURRENCY} from 'constants/formFieldTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import RadioButtons from 'components/RadioButtons'
import FlexFormRows from 'components/FlexFormRows'
import FormRow from 'components/FormRow'
import Input from 'components/Input'
import styles from './styles.module.scss'

export const LoanPayment = ({change}) => {
  const handlePaymentTermChange = e => change('loan_payment_term', e.target.id)
  return (
    <Fragment>
      <FlexFormRows>
        <FormRow label='Loan payment term' type='responsive'>
          <div className={styles.radioButtonContainer}>
            <RadioButtons
              name='loan_payment_term'
              options={[WEEKLY, FORTNIGHTLY, MONTHLY]}
              onChange={handlePaymentTermChange}
            />
          </div>
        </FormRow>
        <FormRow label='Loan payment amount' type='responsive'>
          <div className={styles.container}>
            <Input name='payment_quantity' fullWidth={true} type='string' inputLabelType={CURRENCY} />
          </div>
        </FormRow>
      </FlexFormRows>
      <Input name='weekly_payment' type='number' hidden={true} />
    </Fragment>
  )
}

const mapDispatchToProps = {change: (name, value) => change(CALCULATOR_FORM_NAME, name, value)}
const mapStateToProps = state => ({formValues: getFormValues(CALCULATOR_FORM_NAME)(state)})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanPayment)
