// Core Dependencies
import React, { Component } from 'react'
import { Field, change } from 'redux-form'
import { connect } from 'react-redux'
import formatNum from 'format-num'

// Constants
import { CURRENCY, PERCENTAGE, METERS_SQUARED } from 'constants/formFieldTypes';
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Styles
import styles from './styles.module.scss'

export class Input extends Component {
  static displayName = 'Input' // so FormRow can identify child type

  setClasses = () => {
    let { hidden, disabled, fullWidth } = this.props
    let outputClass = [styles.inputContainer]
    hidden && outputClass.push(styles.hiddenField)
    disabled && outputClass.push(styles.disabledField)
    fullWidth && outputClass.push(styles.fullWidth)
    return outputClass.join(' ')
  }

  valueIsZero = (value) => {
    return Number(value) === 0
  }

  formatWithCommas = (value) => {
    let { inputLabelType } = this.props
    if (inputLabelType === CURRENCY && !this.valueIsZero(value)) {
      return formatNum(value, {locale: 'en-NZ', maximumFractionDigits: 0})
    } else {
      return value
    }
  }

  onFocus = (e) => {
    let { change } = this.props
    let { value, name } = e.target
    if (this.valueIsZero(value)) {
      change(CALCULATOR_FORM_NAME, name, '')
    }
  }

  formatValue = (value) => {
    if (value === null) return ''
    if (value || this.valueIsZero(value)) {
      return this.formatWithCommas(value)
    } else {
      return ''
    }
  }

  getInputType = () => {
    let { inputLabelType } = this.props
    switch(inputLabelType) {
      case CURRENCY:
        return '$'
      case PERCENTAGE:
       return '%'
      case METERS_SQUARED:
        return 'm²'
      default:
        return null
    }
  }

  minValue = min => value => value && value < min ? `Upper figure must not be less than lower` : undefined

  render() {
    let { disabled, name, type, placeholder, onChange, parse, required, min } = this.props

    return (
      <div className={this.setClasses()}>
        <p>{ this.getInputType() }</p>
        <Field
          component='input'
          className={styles.input}
          name={name}
          format={this.formatValue}
          parse={parse}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          onChange={onChange}
          onFocus={this.onFocus}
          validate={!!min ? this.minValue(min) : null}
          required={required}
        />
      </div>
    )
  }
}

const mapDispatchToProps = { change }

export default connect(null, mapDispatchToProps)(Input)
