import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as scriptjs from 'scriptjs'
import {stripeSession, checkCouponIsValid} from 'actions/accountActions'

export const controlledComponent = ComposedComponent =>
  class ControlledComponent extends Component {
    constructor(props) {
      super(props)
      this.state = {
        stripeError: null,
        couponError: null,
        coupon: null
      }
    }

    componentDidUpdate = prevProps => {
      let {
        stripe: prevStripe,
        stripeCouponRequestState: prevStripeCouponRequestState,
        stripeSessionRequestState: prevStripeSessionRequestState
      } = prevProps

      let {stripe, stripeCouponRequestState, stripeSessionRequestState} = this.props

      if (stripe && prevStripe !== stripe) {
        this.redirectToCheckout()
      }

      if (stripeCouponRequestState !== prevStripeCouponRequestState && stripeCouponRequestState.errors) {
        this.setState({couponError: stripeCouponRequestState.errors.response.data.error})
      }

      if (stripeSessionRequestState !== prevStripeSessionRequestState && stripeSessionRequestState.errors) {
        this.setState({stripeError: stripeSessionRequestState.errors.response.data.error})
      }
    }

    triggerSession = () => {
      if (window.Stripe) {
        this.startSession()
      } else {
        scriptjs.get('https://js.stripe.com/v3/', this.checkCoupon)
      }
    }

    checkCoupon = () => {
      let {checkCouponIsValid} = this.props
      let {coupon} = this.state

      if (coupon) checkCouponIsValid(coupon).then(() => this.createSession())
      else this.startSession()
    }

    startSession = () => {
      let {location, stripeSession} = this.props
      let {coupon} = this.state
      let {state = {}} = location

      let stripeRequest = {
        success_url: `${window.location.origin}/#${state.targetPath || location.pathname}`,
        cancel_url: `${window.location.origin}/#${location.pathname}`,
        coupon_code: coupon
      }

      return stripeSession(stripeRequest)
    }

    redirectToCheckout = () => {
      let {publishableKey, sessionId} = this.props.stripe
      window
        .Stripe(publishableKey)
        .redirectToCheckout({sessionId})
        .then(({error}) => {
          // if `redirectToCheckout` fails due to a browser or network error.
          if (error) this.setState({error: error.message})
        })
    }

    setCouponCode = (value) => {
      this.setState({coupon: value})
    }

    render() {
      let {account} = this.props
      let {stripeError, couponError} = this.state
      return !account || account.premium ? (
        <ComposedComponent {...this.props} />
      ) : (
        <ComposedComponent
          {...this.props}
          stripeError={stripeError}
          couponError={couponError}
          handleStripeSession={this.triggerSession}
          setCouponCode={this.setCouponCode} />
      )
    }
  }

const mapDispatchToProps = {stripeSession, checkCouponIsValid}
const mapStateToProps = state => ({
  stripe: state.account.stripe,
  account: state.account.data,
  stripeCouponRequestState: state.account.stripeCouponRequestState,
  stripeSessionRequestState: state.account.stripeSessionRequestState
})

const stripeCheckoutable = ComposedComponent => {
  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withRouter,
    controlledComponent
  )(ComposedComponent)
}

export default stripeCheckoutable
