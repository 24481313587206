// Core Dependencies
import React, { Component } from 'react'
import { Field } from 'redux-form'

// Styles
import styles from './styles.module.scss'

class FormField extends Component {

  renderField = ({ darkTheme, input, placeholder, type, autoComplete, meta: { error, touched } }) => {
    let style = darkTheme ? styles.darkTheme : styles.lightTheme
    return (
      <div className={style}>
        <input {...input} placeholder={placeholder} type={type} autoComplete={autoComplete} className={styles.input}/>
        { (touched && error && error.length) && <div>{error}</div> }
      </div>
    )
  }

  render() {
    let { name, type, placeholder, darkTheme, autoComplete } = this.props
    return <Field name={name} type={type} placeholder={placeholder} darkTheme={darkTheme} autoComplete={autoComplete} component={this.renderField} />
  }
}

export default FormField