import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import formatNum from 'format-num'
import useCalculations from 'hooks/useCalculations'
import {ASC, DESC, RIGHT} from 'constants/directional'
import {calculateGrossRentalYield, calculateNetRentalYield} from 'lib/componentHelpers'
import DefaultLayout from 'components/layouts/DefaultLayout'
import RequestWrapper from 'components/RequestWrapper'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'
import AttachedButtons from 'components/AttachedButtons'
import TableBody from 'components/TableBody'
import TableHeader from 'components/TableHeader'
import listIcon from 'assets/static/icons/list-alt.svg'
import tableIcon from 'assets/static/icons/table.svg'
import styles from './styles.module.scss'

const HEADERS = ['Address', 'Estimated Purchase Price', 'Gross Rental Yield', 'Net Rental Yield']

const formatCalculation = calculation => [
  calculation.address.split(',')[0],
  `$${formatNum(calculation.purchase_price, {locale: 'en-NZ', maximumFractionDigits: 0})}`,
  `${calculateGrossRentalYield(calculation) || 0}%`,
  `${calculateNetRentalYield(calculation) || 0}%`
]
export const CalculationsCompared = ({history}) => {
  const [sort, setSort] = React.useState({column: 0, direction: ASC})

  const [calculations, requestStatus] = useCalculations()

  const handleRowClick = row => history.push(`/calculator/${calculations[row].id}`)
  const handleColumnClick = column => {
    let direction = sort.column !== column ? ASC : sort.direction === ASC ? DESC : ASC
    setSort({column, direction})
  }

  const sortFunc = (a, b) => {
    const parse = v => Number(v.replace(/,|\$/g, ''))

    let valueA = parse(a[sort.column])
    let valueB = parse(b[sort.column])

    if (valueA < valueB) {
      return sort.direction === DESC ? 1 : -1
    } else if (valueA > valueB) {
      return sort.direction === DESC ? -1 : 1
    } else {
      return 0
    }
  }

  if (!calculations.length) {
    return (
      <ContentSection>
        <h3>{`You don't have any saved calculations yet`}</h3>
      </ContentSection>
    )
  }

  return (
    <DefaultLayout pageTitle='Your saved calculations'>
      <RequestWrapper requestStates={[requestStatus]}>
        <ContentSection>
          <ButtonGroup>
            <h3>{`${calculations.length} saved calculations`}</h3>
            <AttachedButtons selectedIndex={1}>
              <Link to='/calculations'>
                <img className={styles.listIcon} alt='list view' src={listIcon} />
              </Link>
              <img className={styles.tableIcon} alt='table view' src={tableIcon} />
            </AttachedButtons>
          </ButtonGroup>
          <div className={styles.tableScroll}>
            <table>
              <TableHeader
                headers={HEADERS}
                sortColumn={sort.column}
                sortDirection={sort.direction}
                columnAlignments={{1: RIGHT, 2: RIGHT, 3: RIGHT}}
                onClick={handleColumnClick}
              />
              <TableBody
                collection={calculations.map(formatCalculation)}
                sortColumn={sort.column}
                sortDirection={sort.direction}
                sortFunctions={{1: (a, b) => sortFunc(a, b)}}
                columnAlignments={{1: RIGHT, 2: RIGHT, 3: RIGHT}}
                onClick={handleRowClick}
              />
            </table>
          </div>
        </ContentSection>
      </RequestWrapper>
    </DefaultLayout>
  )
}

export default withRouter(CalculationsCompared)
