// Core Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Slider from '@abletech/rc-slider';
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'

// Constants
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Styles
import styles from './styles.module.scss'

// if `width` is changed for `.rc-slider-handle` then `margin-left` needs to also change
// to exactly minus the half of the width (otherwise you run into some nastly onFocus issues)
// See https://github.com/react-component/slider/issues/433#issuecomment-397442871
const handleStyle = {borderColor: '#193B85', backgroundColor: '#193B85', width: '1.125em', height: '1.125em', marginLeft: '-0.5625em'}
const trackStyle = {backgroundColor: '#72AFDF', marginTop: '2px'}
const railStyle = {backgroundColor: '#C3C6CC', marginTop: '2px'}
const dotStyle = {display: 'none'}

class SliderComponent extends Component {

  handleChange = (value) => {
    let { change, name } = this.props
    change(CALCULATOR_FORM_NAME, name, value)
  }

  renderMarks() {
    let { marks } = this.props
    let markElements = {}

    marks && Object.entries(marks).forEach(([key, value]) => {
      markElements[key] = <p className={styles.sliderMarkStyle}>{value}</p>
    })

    return markElements
  }

  setClasses = () => {
    let { marks } = this.props
    let outputClass = [styles.slider]
    marks && outputClass.push(styles.sliderWithMarks)
    return outputClass.join(' ')
  }

  convertToNumber = (value) => {
    if (value === '') {
      return 0
    } else if (typeof value === 'string') {
      return Number(value.replace(/,\s?/g, ""))
    } else {
      return value
    }
  }

  render() {
    let { min, max, formValues, name, step } = this.props
    return (
      <div id={`${name}_slider`} className={styles.sliderContainer}>
       <Slider
        className={this.setClasses()}
        handleStyle={handleStyle}
        trackStyle={trackStyle}
        railStyle={railStyle}
        dotStyle={dotStyle}
        marks={this.renderMarks()}
        value={this.convertToNumber(formValues[name])}
        min={min}
        max={max}
        step={step}
        onChange={this.handleChange} />
      </div>
    );
  }
}

SliderComponent.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string.isRequired
}

const mapDispatchToProps = { change }

export { SliderComponent }

export default connect(
  state => {
    return {
      formValues: getFormValues(CALCULATOR_FORM_NAME)(state)
    }
  }, mapDispatchToProps
)(SliderComponent)