import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import TopNav, {TopNavLink, TopNavSpace} from 'components/TopNav'
import Nav from 'components/Nav'
import {withRouter} from 'react-router-dom'

import logo from 'assets/static/logo.svg'
import styles from './styles.module.scss'

export const FullScreenLayout = ({history, location, children, loggedIn}) => {
  const onSignUpClick = () => {
    history.push('/plans')
  }

  const handleLogoClicked = () => {
    history.push('/home')
  }

  const signupPathisActive = () => {
    if (location.pathname === '/signup') return true
  }

  return (
    <div className={styles.layoutContainer}>
      <TopNav transparent>
        {!loggedIn && (
          <Fragment>
            <div className={styles.logo} onClick={handleLogoClicked}>
              <img alt='logo' src={logo} />
            </div>
            <TopNavSpace />

            <Nav left></Nav>
            <Nav right>
              <TopNavLink to='/plans'>Pricing</TopNavLink>
              {'|'}
              <TopNavLink onClick={onSignUpClick} isActive={signupPathisActive()}>
                Sign Up
              </TopNavLink>
              or
              <TopNavLink to='/signin'>Sign In</TopNavLink>
            </Nav>
          </Fragment>
        )}
      </TopNav>
      <div className={styles.layout}>{children}</div>
    </div>
  )
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default compose(
  connect(state => ({loggedIn: state.account.loggedIn}), null),
  withRouter
)(FullScreenLayout)
