// Core Dependencies
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

// Constants
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Styles
import styles from './styles.module.scss'

const RadioButtons = ({ name, options, buttonList, formValues, onChange }) => {
  let buttonStyle = buttonList ? styles.buttonList : styles.buttonRow
  let value = formValues ? formValues[name] : null

  return (
    <Fragment>
    {
      options.map((option) => {
        let isChecked = value === option          
          return (
            <div className={[styles.inputGroup, buttonStyle].join(' ')} key={option}>
              <Field name={name} component='input' type='radio' checked={isChecked}/>
              <label id={option} onClick={onChange}>{option}</label>
            </div>
          )
      })
    }
    </Fragment>
  )
}

RadioButtons.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array.isRequired
}

const mapDispatchToProps = { change }

export { RadioButtons }

export default connect(
  state => {
    return {
      formValues: getFormValues(CALCULATOR_FORM_NAME)(state)
    } 
  }, mapDispatchToProps
)(RadioButtons)