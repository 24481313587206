import React, {Component} from 'react'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import ContentSection from 'components/ContentSection'
import Popup from 'components/Popup'
import stripeCheckoutable from 'components/hoc/stripeCheckoutable'
import FormError from 'components/FormError'
import styles from './styles.module.scss'

export class CouponPopup extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  clearInput = () => {
    this.inputRef.current.value = null
    this.props.setCouponCode(null)
  }

  closePopup = () => {
    this.props.setPopupState(false)
  }

  render() {
    let {
      setCouponCode,
      couponError,
      stripeError,
      handleStripeSession,
      isOpen
    } = this.props
      return (
        <Popup isOpen={isOpen} onClose={this.closePopup}>
          <ContentSection>
            {stripeError && <ContentSection>{stripeError}</ContentSection>}
            <p>Enter coupon code</p>
            <input
              ref={this.inputRef}
              style={{textAlign: 'left'}}
              className={styles.input}
              name={'coupon_code'}
              placeholder={'Enter coupon code'}
              type={'text'}
              onChange={(e) => setCouponCode(e.target.value)} />
            {couponError ? (<FormError error={couponError} />) : null}
            <ButtonGroup>
              <Button text='Upgrade' onClick={handleStripeSession} />
            </ButtonGroup>
          </ContentSection>
        </Popup>
      )
    }
  }

export default stripeCheckoutable(CouponPopup)
