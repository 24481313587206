import React, {Fragment} from 'react'
import styles from './styles.module.scss'
import closeIcon from 'assets/static/icons/close.svg'

const Popup = ({isOpen, onClose, children, className}) => {
  if (!isOpen) return null
  return (
    <Fragment>
      <div className={styles.overlay} />
      <div className={`${styles.popup} ${className || ''}`}>
        {onClose && <img className={styles.closeIcon} alt='close' src={closeIcon} onClick={onClose} />}
        {children}
      </div>
    </Fragment>
  )
}

export default Popup
