// Core Dependencies
import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

 // Assets
 import backIcon from 'assets/static/icons/right-arrow.svg'

// Styles
import styles from './styles.module.scss'

const BackButton = ({history}) => {
  return (
    <div className={styles.flexContainer} onClick={history.goBack}>
      <div className={styles.button}>
        <div className={styles.iconButton} style={{backgroundImage: `url( ${backIcon} )` }}/>
        <p>Back</p>
      </div>
    </div>
  )
}

export { BackButton }
export default compose(
  withRouter
)(BackButton)