
import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { marketRentTypes } from 'actions/types'

const initialState = null

const dataReducer = createReducer(initialState, {
  [marketRentTypes.CLEAR_MARKET_RENT]: (state, action) => initialState,
  [marketRentTypes.FETCH_MARKET_RENT_FULFILLED]: (state, action) => action.payload
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(marketRentTypes.FETCH_MARKET_RENT),
})

export default reducer