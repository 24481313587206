import React from 'react'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import ContentSection from 'components/ContentSection'
import Popup from 'components/Popup'
import {getContextOfPremiumPopup} from './helpers'

export const PremiumPopup = ({location, history}) => {

  const closePopup = () => {
    const popupContext = getContextOfPremiumPopup(location.pathname)
    return history.push(popupContext)
  }

  const redirectToPlansPage = () => {
    return history.push('/plans')
  }

  return (
    <Popup isOpen={true} onClose={closePopup}>
      <ContentSection>
        <p>You need a premium account to access that feature.</p>
        <ButtonGroup>
          <Button text='Upgrade' onClick={redirectToPlansPage} />
        </ButtonGroup>
      </ContentSection>
    </Popup>
  )
}

export default PremiumPopup