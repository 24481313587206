import {reducer} from 'redux-form'
import {calculationTypes, expensesTypes, coreLogicTypes} from 'actions/types'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'

const replaceValues = (state, values) => ({...state, values})
const mergeValues = (state, values) => ({...state, values: {...state.values, ...values}})

export default reducer.plugin({
  [CALCULATOR_FORM_NAME]: (state, action) => {
    switch (action.type) {
      case calculationTypes.FETCH_CALCULATION_FULFILLED:
        return replaceValues(state, action.payload)
      case expensesTypes.FETCH_DEFAULT_CALCULATOR_VALUES_FULFILLED:
        return mergeValues(state, action.payload)
      case coreLogicTypes.FETCH_CORELOGIC_FULFILLED:
        return mergeValues(state, action.payload)
      default:
        return state
    }
  }
})
