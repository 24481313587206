import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {fetchAccount, logout} from 'actions/accountActions'
import DefaultLayout from 'components/layouts/DefaultLayout'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'
import ListLink from 'components/ListLink'
import PremiumBanner from 'components/PremiumBanner'

export class Profile extends Component {
  componentDidMount() {
    let {fetchAccount, account} = this.props
    if (!account) fetchAccount()
  }

  render() {
    let {account, logout} = this.props

    if (!account) return null

    return (
      <DefaultLayout pageTitle='Profile'>
        <ContentSection>
          <PremiumBanner />
          <ListLink to='/editAccount'>Edit account</ListLink>
          <ListLink to='/editpassword'>Edit password</ListLink>
          <ListLink to='/termsandconditions'>Terms & conditions</ListLink>
          <ListLink to='/privacypolicy'>Privacy policy</ListLink>
          <ListLink to='/support'>Support</ListLink>
          <ButtonGroup>
            {!account.premium && <Link to='/plans'>Upgrade to premium</Link>}
            <Button text='Logout' onClick={logout} />
          </ButtonGroup>
        </ContentSection>
      </DefaultLayout>
    )
  }
}

let mapDispatchToProps = {fetchAccount, logout}
let mapStateToProps = state => ({account: state.account.data})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
