import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './styles.module.scss'

export const TopNavSpace = () => <span className={styles.space} />

export const TopNavIcon = ({icon, onClick}) => (
  <div className={styles.icon} style={{backgroundImage: 'url(' + icon + ')'}} onClick={onClick} />
)

export const TopNavLink = ({children, to, onClick, isActive}) => {
  const topNavClassNames = () => {
    let classes = [styles.link]
    if (isActive) classes.push(styles.activeLink)
    return classes
  }

  return to ? (
    <NavLink className={styles.link} activeClassName={styles.activeLink} to={to}>
      {children}
    </NavLink>
  ) : (
    <div className={topNavClassNames().join(' ')} onClick={onClick}>
      {children}
    </div>
  )
}

const TopNav = ({children, transparent}) => {
  let classes = [styles.pageHeader]
  if (transparent) classes.push(styles.transparent)
  return <header className={classes.join(' ')}>{children}</header>
}

export default TopNav
