// Core Dependencies
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Linkify from 'react-linkify';

// Assets
import infoIcon from 'assets/static/icons/info.svg'
import infoIconHover from 'assets/static/icons/info-hover.svg'

// Styles
import styles from './styles.module.scss'

class ToolTip extends Component {
  constructor() {
    super() 

    this.state = {
      tipVisible: false
    }
  }

  toggleTipVisibility = () => {
    let { tipVisible } = this.state
    this.setState({tipVisible: !tipVisible})
  }

  closeTip = () => {
    this.setState({tipVisible: false})
  }

  render() {
    let { tipVisible } = this.state
    let { toolTipText } = this.props
    return (
      <Fragment>
        { tipVisible ? <div className={styles.overlay} onClick={this.closeTip}/> : null }
        <div className={styles.container}>
          <div className={styles.imageContainer} onClick={this.toggleTipVisibility}>
            <img alt='More information' src={infoIconHover} className={styles.infoIconHover}/>
            <img alt='More information' src={infoIcon} className={styles.infoIcon}/>
          </div>
          <Linkify properties={{target: '_blank'}} className={tipVisible ? styles.active : styles.hidden}>{toolTipText}</Linkify>
        </div>
      </Fragment>
    )
  }
}

ToolTip.propTypes = {
  toolTipText: PropTypes.string.isRequired
}

export default ToolTip