import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {clearFlash} from 'actions/flashActions'
import closeIcon from 'assets/static/icons/close.svg'
import styles from './styles.module.scss'

export const Flash = ({success, error, scroll = true, clearFlash}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearFlash, []) // cleanup on unmount

  if (!success && !error) return null

  let klass = ''
  if (success) {
    klass = styles.success
  } else if (error) {
    klass = styles.error
  }

  if (scroll) window.scroll({top: 0, left: 0, behavior: 'smooth'})

  return (
    <div className={`${styles.container} ${klass}`}>
      <img className={styles.closeIcon} alt='close' src={closeIcon} onClick={clearFlash} />
      <p>{success || error}</p>
    </div>
  )
}

const mapStateToProps = ({flash}) => flash
const mapDispatchToProps = {clearFlash}
export default connect(mapStateToProps, mapDispatchToProps)(Flash)
