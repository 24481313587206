const getPropertyTypes = (marketRent) => {
  let allPropertyTypes = marketRent.map((property) =>  property.dwelling )
  return { propertyTypes: [...new Set(allPropertyTypes)] }
}

const getBedroomQuantity = (marketRent, dwelling) => {

  let bedroomMarks = {}
  let properties = marketRent.filter((property) => property.dwelling === dwelling)
  let number_of_bedrooms = properties.map((property) => property.number_of_bedrooms)
  let minBedrooms = Math.min.apply(null, number_of_bedrooms)
  let maxBedrooms = Math.max.apply(null, number_of_bedrooms)

  number_of_bedrooms.forEach((bedroom, index) => {
    let isLastItem = index === number_of_bedrooms.length - 1
    bedroomMarks[bedroom] = !isLastItem ? `${bedroom}` : `${bedroom}+`
  })

  return { minBedrooms: minBedrooms, maxBedrooms: maxBedrooms, bedroomMarks: bedroomMarks }
}

export { getPropertyTypes, getBedroomQuantity }