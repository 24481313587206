import React from 'react'
import DefaultLayout from 'components/layouts/DefaultLayout'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'
import styles from './styles.module.scss'

const PrivacyPolicy = () => (
  <DefaultLayout pageTitle='Privacy Policy'>
    <ContentSection>
      <BackButton />
      <h1 className={styles.sectionTitle}>Privacy Policy - RealEsmart</h1>
      <h3>Effective date: 18 February 2019</h3>
      <p>
        {`Resolve Management Limited ("us", "we", or "our") operates the RealESmart application, referred as “RealESmart".`}
      </p>
      <p>
        This page informs you of our policies regarding the collection, use, disclosure and protection of personal data
        when you use RealESmart and the choices you have associated with that data in accordance with the Privacy Act
        1993.{' '}
      </p>
      <p>
        We use data to provide and improve RealESmart. By using RealESmart, you agree to the collection and use of
        information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in
        this Privacy Policy have the same meanings as in our Terms and Conditions.
      </p>
      <h3>Definitions</h3>
      <p>
        <b>RealESmart</b>
      </p>
      <p>
        RealESmart is the application principally a property calculator owned and operated by Resolve Management
        Limited.
      </p>
      <p>
        <b>Personal Data</b>
      </p>
      <p>Personal Data means data about an identifiable individual.</p>
      <p>
        <b>Usage Data</b>
      </p>
      <p>
        Usage Data is data collected automatically either by being generated through the use of RealESmart or from
        RealESmart’s infrastructure itself (for example, the duration of a page visit).
      </p>
      <p>
        <b>Cookies</b>
      </p>
      <p>
        Cookies are small files stored on your device (computer or mobile device). Cookies may not collect data that is
        personally identifiable but may collect information about when and how you use RealESmart.
      </p>
      <h3>Information Collection and Use</h3>
      <p>We collect several different types of information for various purposes to provide and improve RealESmart.</p>
      <h3>Types of Data Collected</h3>
      <ol>
        <li>
          Personal Data. While using RealESmart, we may ask you to provide us with certain personally identifiable
          information that can be used to contact or identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to: your email address, your first and last name and your phone number.
        </li>
        <li>
          Cookies and Usage Data. We may use your Personal Data to contact you with newsletters, marketing or
          promotional materials and other information that may be of interest to you. You may opt out of receiving any
          or all of these communications from us by following the unsubscribe link.
        </li>
        <li>
          Usage Data. When you access RealESmart with your computer or mobile device, we may collect certain information
          automatically, including, but not limited to, the type of device you use, your device’s unique ID, the IP
          address of your device, your operating system, the type of Internet browser you use, unique device identifiers
          and other diagnostic data ("Usage Data").
        </li>
        <li>
          Tracking Cookies Data.We use cookies and similar tracking technologies to track activity on RealESmart and we
          hold certain information.
          <p>
            Cookies are files with a small amount of data that may include an anonymous unique identifier. Cookies are
            sent to your browser from a website and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information and to improve and analyse RealESmart.
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some portions of RealESmart.
          </p>
          Examples of Cookies we use:
          <ul>
            <li>Session Cookies. We use Session Cookies to operate RealESmart. </li>
            <li>
              Preference Cookies. We use Preference Cookies to remember your preferences and various settings on
              RealESmart.
            </li>
            <li>Security Cookies. We use Security Cookies for security purposes on RealESmart. </li>
          </ul>
        </li>
      </ol>
      <h3>Use of Data</h3>
      <p>Resolve Management Limited uses the collected data for various purposes:</p>
      <ol>
        <li>To provide, maintain and improve RealESmart and customer experience;</li>
        <li>To notify you about changes or improved features of RealESmart;</li>
        <li>To allow you to participate in interactive features of RealESmart when you choose to do so;</li>
        <li>To provide customer support;</li>
        <li>To gather analysis so that we can promote and market RealESmart;</li>
        <li>To monitor the usage of RealESmart;</li>
        <li>To detect, prevent and address technical issues on RealESmart;</li>
        <li>
          To provide you with news, special offers and general information about other goods, services and events which
          may be of interest to you unless you have opted not to receive such information.
        </li>
      </ol>
      <h3>Transfer of Data</h3>
      <p>
        Your information, including Personal Data, may be transferred to and maintained on computers located outside of
        New Zealand where the data protection laws may differ from those of New Zealand.
      </p>
      <p>
        If you are located outside of New Zealand and choose to provide information to us, please note that we transfer
        the data, including Personal Data, to New Zealand and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to
        that transfer.
      </p>
      <p>
        Resolve Management Limited will take all the steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
        organisation or a country unless there are adequate security controls in place to protect data and any personal
        information.
      </p>
      <h3>Disclosure of Data</h3>
      <p>
        If Resolve Management Limited is involved in a merger, acquisition or asset sale, your Personal Data may be
        transferred. We will provide notice before your Personal Data is transferred and if it becomes subject to a
        different Privacy Policy.
      </p>
      <h3>Disclosure of Personal Data</h3>
      <p>
        Under certain circumstances, Resolve Management Limited may be required to disclose your Personal Data
        particularly I required to do so by law or in response to valid request/s by authorities (e.g. police or
        government agency/agencies).
      </p>
      <h3>Legal Requirements</h3>
      <p>Resolve Management Limited may disclose your Personal Data in circumstances such as follows:</p>
      <ol>
        <li>To comply with legal obligation/s;</li>
        <li>To prevent or investigate possible wrongdoing in connection with use of RealESmart;</li>
        <li>To protect the personal safety of users of RealESmart or the public;</li>
        <li>To protect against legal liability.</li>
      </ol>
      <h3>Security of Data</h3>
      <p>
        The security of your data is important to us. Please remember that no method of transmission over the Internet
        or method of electronic storage is perfectly secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>
      <h3>Service Providers</h3>
      <p>
        We may engage third party companies or individuals to facilitate RealESmart ("Service Providers"), who may
        assist us in analysing how RealESmart is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform these tasks on our behalf and are
        obligated not to disclose or use it for any other purpose.
      </p>
      <h3>Links to Other Sites</h3>
      <p>
        RealESmart may contain links to other sites that are not operated by us. If you click a third party link, you
        will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site
        you visit. We have no control over and assume no responsibility for the content, privacy policies or practices
        of any third party sites or services.
      </p>
      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page.
      </p>
      <p>We will let you know via email or when you log in to RealESmart if any changes have been made.</p>
      <h3>Access to Personal Data</h3>
      <p>
        In accordance with the Privacy Act 1993, you are entitled to have assess to your personal information that we
        hold about you. You can update your data at anytime by logging in to RealEsmart and changing your user profile.
        If you would like to ask for a copy of any of your personal data or if you require information to be corrected,
        please contact us at info@realesmart.co.nz.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us either by email or by visiting
        realesmart.co.nz/contact.
      </p>
    </ContentSection>
  </DefaultLayout>
)

export default PrivacyPolicy
