// Core Dependencies
import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux'
import { HashRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga';
import createStore from './store/createStore'

// Components
import Routes from './components/Routes'

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`)

const logPageView = () => {
  ReactGA.set({ page: window.location.hash })
  ReactGA.pageview(window.location.hash)
  return null
}

class App extends Component {
  render() {
    return (
      <Provider store={createStore}>
        <Router>
          <Fragment>
            <Route path="/" component={logPageView} />
            <Routes/>
          </Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
