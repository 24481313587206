// Core Dependencies
import React, {Fragment} from 'react';
import { getFormValues } from 'redux-form'
import { connect } from 'react-redux'

// Constants
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Styles
import styles from './styles.module.scss'

export const Overlay = ({formValues={}}) => {
  let {weekly_rental_income, gross_rental_yield, net_rental_yield} = formValues

  if ((weekly_rental_income !== 0) || (gross_rental_yield !== 0) || (net_rental_yield !== 0 )) {
    return (
      <Fragment>
        <div className={styles.overlay}>
          <div className={styles.overlaySection}>
            <p>Est weekly rental income</p>
            <p className={styles.value}>${Math.round(weekly_rental_income)}</p>
          </div>
          <div className={styles.overlaySection}>
            <p>Gross rental yield</p>
            <p className={styles.value}>{gross_rental_yield}%</p>
          </div>
          <div className={styles.overlaySection}>
            <p>Net rental yield</p>
            <p className={styles.value}>{net_rental_yield}%</p>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return null
  }
}

export default connect(
  state => ({formValues: getFormValues(CALCULATOR_FORM_NAME)(state)})
)(Overlay)