import {useState} from 'react'

const useImageCrop = fileType => {
  const [image, setImage] = useState(null)
  const [cropSrc, setCropSrc] = useState(null)
  const [cropArea, setCropArea] = useState({
    unit: '%',
    width: 100,
    height: 43.5
  })

  const cancelCrop = () => {
    setCropSrc(null)
    setImage(null)
  }

  const makeClientCrop = async crop => {
    if (image && crop.width && crop.height) {
      const canvas = document.createElement('canvas')
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = crop.width
      canvas.height = crop.height
      const context = canvas.getContext('2d')

      context.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )

      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty')
          return
        }
        window.URL.revokeObjectURL(cropSrc)
        setCropSrc(window.URL.createObjectURL(blob))
      }, fileType)
    }
  }

  const cropHandlers = {
    onImageLoaded: setImage,
    onComplete: makeClientCrop,
    onChange: setCropArea,
    onCancel: cancelCrop
  }

  return {cropHandlers, cropArea, cropSrc}
}

export default useImageCrop
