import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CometSpinLoader from 'components/SpinLoader'
import {IN_PROGRESS, FAILURE, SUCCESS} from 'lib/selectors/getRequestStatus'
import styles from './styles.module.scss'

class RequestWrapper extends Component {
  shouldComponentUpdate = nextProps =>
    this.isNotStarted(this.props.requestStates) ||
    this.isDone(this.props.requestStates) ||
    this.isDone(this.props.requestStates) !== this.isDone(nextProps.requestStates) ||
    this.isNotStarted(this.props.requestStates) !== this.isNotStarted(nextProps.requestStates)

  isDone = states => states.every(s => s === SUCCESS || s === FAILURE)
  isNotStarted = states => states.length === 0 || states.every(s => s === null)

  render() {
    let {requestStates, children, color, successMessage, progressMessage} = this.props

    if (this.isNotStarted(requestStates)) {
      return children
    }

    if (!this.isDone(requestStates)) {
      if (progressMessage) {
        return (
          <div className={styles.progressMessage}>
            <CometSpinLoader color={color} size={10} className={styles.loader} />
            <span>{progressMessage}</span>
          </div>
        )
      } else {
        return <CometSpinLoader color={color} size={50} />
      }
    }

    if (successMessage) {
      return <p className={styles.successMessage}>{successMessage}</p>
    } else {
      return children
    }
  }
}

RequestWrapper.propTypes = {
  requestStates: PropTypes.arrayOf(PropTypes.oneOf([null, IN_PROGRESS, FAILURE, SUCCESS])),
  color: PropTypes.string,
  successMessage: PropTypes.string
}

RequestWrapper.defaultProps = {
  requestStates: [],
  color: '#193B85'
}

export default RequestWrapper
