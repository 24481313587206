// Core Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './styles.module.scss'

const ContentSection = ({children, className}) => (
  <section className={`${styles.section} ${className}`}>
    {children}
  </section>
)

ContentSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

ContentSection.defaultProps = {
  className: ''
}

export default ContentSection