import {WEEKLY, MONTHLY, YEARLY} from 'constants/loanPaymentTermTypes'
import {
  calculateRentalIncome,
  convertValueByFrequency,
  calculateGrossRentalYield,
  calculateNetRentalYield,
  calculateReturn
} from 'lib/componentHelpers'
import formatCoreLogicData from 'actions/coreLogicActions/formatFetchResponse'

const formatFetchResponse = response => {
  if (!response) return null

  response.property = response.address
  response.loan_duration = response.loan_duration_months / 12
  response.monthly_rental_income_lower = convertValueByFrequency(response.weekly_rental_income_lower, WEEKLY, MONTHLY)
  response.monthly_rental_income_upper = convertValueByFrequency(response.weekly_rental_income_upper, WEEKLY, MONTHLY)
  response.yearly_rental_income_lower = convertValueByFrequency(response.weekly_rental_income_lower, WEEKLY, YEARLY)
  response.yearly_rental_income_upper = convertValueByFrequency(response.weekly_rental_income_upper, WEEKLY, YEARLY)
  response.weekly_rental_income = calculateRentalIncome(
    response.house_condition,
    response.weekly_rental_income_lower,
    response.weekly_rental_income_upper
  )
  response.payment_quantity = convertValueByFrequency(response.weekly_payment, WEEKLY, response.loan_payment_term)
  response.gross_rental_yield = calculateGrossRentalYield(response)
  response.net_rental_yield = calculateNetRentalYield(response)

  response.estimate_reason = response.estimate_reason || null
  response.estimate_weekly_lower = response.estimate_weekly_lower || null
  response.estimate_weekly_upper = response.estimate_weekly_upper || null
  response.estimate_monthly_lower = response.estimate_monthly_lower || null
  response.estimate_monthly_upper = response.estimate_monthly_upper || null
  response.estimate_yearly_lower = response.estimate_yearly_lower || null
  response.estimate_yearly_upper = response.estimate_yearly_upper || null

  let {yearly, monthly, weekly} = calculateReturn(response)
  response.weekly_profit = weekly
  response.monthly_profit = monthly
  response.yearly_profit = yearly

  let corelogic = {}
  if (response.corelogic) {
    corelogic = formatCoreLogicData(response.corelogic)
  }

  return {...response, ...corelogic}
}

export default formatFetchResponse
