import {get, post, put} from 'lib/reduxHelpers/thunks'
import {accountTypes, calculationsTypes} from 'actions/types'
import {clearAuthToken} from 'lib/auth'

function fetchAccount() {
  let baseType = accountTypes.FETCH_ACCOUNT
  let url = `${process.env.REACT_APP_API_URL}/account`
  return get({baseType, url})
}

function createAccount(credentials) {
  let baseType = accountTypes.CREATE_ACCOUNT
  let url = `${process.env.REACT_APP_API_URL}/account`
  return post({baseType, url, body: credentials})
}

function editAccount(accountDetails) {
  let baseType = accountTypes.EDIT_ACCOUNT
  let url = `${process.env.REACT_APP_API_URL}/account`
  return put({baseType, url, body: accountDetails})
}

function editPassword(credentials) {
  let baseType = accountTypes.EDIT_PASSWORD
  let url = `${process.env.REACT_APP_API_URL}/account`
  return put({baseType, url, body: credentials})
}

function forgotPassword(email) {
  let baseType = accountTypes.FORGOT_PASSWORD
  let url = `${process.env.REACT_APP_API_URL}/account/forgot_password`
  return post({baseType, url, body: email})
}

function resetPassword(credentials) {
  let baseType = accountTypes.RESET_PASSWORD
  let url = `${process.env.REACT_APP_API_URL}/account/password`
  return put({baseType, url, body: credentials})
}

function login(credentials) {
  let baseType = accountTypes.LOGIN
  let url = `${process.env.REACT_APP_API_URL}/authenticate`
  return post({baseType, url, body: credentials})
}

function stripeSession(callbacks) {
  let baseType = accountTypes.STRIPE_SESSION
  let url = `${process.env.REACT_APP_API_URL}/account/stripe_session`
  return post({baseType, url, body: callbacks})
}

function checkCouponIsValid(coupon) {
  let baseType = accountTypes.STRIPE_COUPON
  let url = `${process.env.REACT_APP_API_URL}/account/check_coupon?coupon_code=${coupon}`
  return get({baseType, url})
}

const cancelSubscription = () =>
  post({
    baseType: accountTypes.CANCEL_SUBSCRIPTION,
    url: `${process.env.REACT_APP_API_URL}/account/cancel_subscription`
  })

const uncancelSubscription = () =>
  post({
    baseType: accountTypes.UNCANCEL_SUBSCRIPTION,
    url: `${process.env.REACT_APP_API_URL}/account/uncancel_subscription`
  })

const logout = () => dispatch => {
  clearAuthToken()
  dispatch({type: accountTypes.LOGOUT})
  dispatch({type: accountTypes.CLEAR_FETCH_ACCOUNT})
  dispatch({type: calculationsTypes.CLEAR_FETCH_CALCULATIONS})
}

export {
  fetchAccount,
  createAccount,
  editAccount,
  editPassword,
  forgotPassword,
  resetPassword,
  login,
  logout,
  stripeSession,
  checkCouponIsValid,
  cancelSubscription,
  uncancelSubscription
}
