import React from 'react'
import classnames from 'classnames'

import Button from 'components/Button'
import { TopNavLink } from 'components/TopNav'

import b2bHouse from 'assets/static/b2b-house-graphic.png'
import b2cHouse from 'assets/static/b2c-house-graphic.png'
import logo from 'assets/static/realesmart-logo.png'

import styles from './styles.module.scss'

const HomeScreen = ({ history }) => {

  const handleSignUpClicked = () => {
    history.push('/plans')
  }

  const handleSignInClicked = () => {
    history.push('/signin')
  }

  const handleEnterClicked = (professional = false) => {
    professional ? history.push(`/about-us?professional=true`) : history.push(`/about-us`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.logoContainer}>
          <img alt='logo' src={logo} />
        </div>
        <div className={styles.loginContainer}>
          <div className={styles.linksContainer}>
            <TopNavLink onClick={handleSignUpClicked}>
              <p className={styles.navLinks}>
                Sign Up
              </p>
            </TopNavLink>
            <hr />
            <TopNavLink onClick={handleSignInClicked}>
              <p className={styles.navLinks}>
                Log In
              </p>
            </TopNavLink>
          </div>
        </div>
      </div>
      <div className={styles.panelContainer}>
        <div className={`${styles.panel} ${styles.leftPanel}`}>
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <p className={styles.headerText}>
                Are you a <em className={styles.orange}>home buyer or residential property investor</em> wanting to make a smart investment?
              </p>
            </div>
            <div className={styles.houseContainer}>
              <img alt='house for sale' src={b2cHouse} />
            </div>
            <div className={styles.blurbContainer}>
              <p className={styles.blurbText}>
                Our smart calculator will help you to better understand your current and future property investments
              </p>
            </div>
            <div>
              <Button
                className={classnames(styles.button, styles.orangeButton)}
                text="Enter >"
                onClick={() => handleEnterClicked(false)}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.panel} ${styles.rightPanel}`}>
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <p className={styles.headerText}>
                Are you a <em className={styles.blue}>property professional or business adviser</em> needing to keep up with the latest property data?
              </p>
            </div>
            <div className={styles.houseContainer}>
              <img className={styles.houseImage} alt='commercial property' src={b2bHouse} />
            </div>
            <div className={styles.blurbContainer}>
              <p className={styles.blurbText}>
                Our smart calculator will deliver property and market information, complete rental appraisals and much more
              </p>
            </div>
            <div>
              <Button
                className={styles.button}
                text="Enter >"
                onClick={() => handleEnterClicked(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
