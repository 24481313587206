// Core dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'

// Constants
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'
import { WEEKLY, MONTHLY, YEARLY } from 'constants/loanPaymentTermTypes'

// Actions
import { fetchMarketRent } from 'actions/marketRentActions'

// Lib
import { calculateRentalIncome, convertValueByFrequency } from 'lib/componentHelpers'

// Components
import Input from 'components/Input'
import FormRow from 'components/FormRow';
import RangedRentalIncomeInputs from 'components/Calculator/RangedRentalIncomeInputs'

// the upper and lower quartiles were considered too wide of a range, so
// business decided to adjust the mid range +/- this value
const INCOME_RANGE_ADJUSTMENT = 25

export class EstimatedRentalIncome extends Component {

  componentDidUpdate(prevProps, prevState) {
    let { formValues: prevFormValues, marketRent: prevMarketRent } = prevProps
    let { formValues } = this.props

    if (this.noMarketRent() && formValues && formValues.id) {
      this.getMarketRent()
    } else if (this.marketRentValuesHaveChanged(prevMarketRent, prevFormValues)) {
      this.updateWeeklyRangeFieldsFromMarketRent()
    }

    if (this.weeklyRangeHasChanged(prevFormValues)) {
      this.updateRangeFields(WEEKLY)
    } else if (this.monthlyRangeHasChanged(prevFormValues)) {
      this.updateRangeFields(MONTHLY)
    } else if (this.yearlyRangeHasChanged(prevFormValues)) {
      this.updateRangeFields(YEARLY)
    } else if (this.houseConditionHasChanged(prevFormValues)) {
      this.updateWeeklyRentalIncomeField()
    }
  }

  noMarketRent = () => {
    let { marketRent, marketRentRequestState } = this.props
    return !marketRent && !marketRentRequestState.fetched && !marketRentRequestState.fetching
  }

  getMarketRent = () => {
    let { sa2_code, meshblock, suburb, city } = this.props.formValues
    this.props.fetchMarketRent(sa2_code, meshblock, suburb, city)
  }

  marketRentValuesHaveChanged = (prevMarketRent, prevFormValues) => {
    let { formValues, marketRent } = this.props
    return (marketRent && (marketRent !== prevMarketRent)) ||
           ((formValues.dwelling !== prevFormValues.dwelling) ||
           (formValues.number_of_bedrooms !== prevFormValues.number_of_bedrooms))
  }

  weeklyRangeHasChanged = (prevFormValues) => {
    let { formValues } = this.props
    return (formValues.weekly_rental_income_lower !== prevFormValues.weekly_rental_income_lower) ||
           (formValues.weekly_rental_income_upper !== prevFormValues.weekly_rental_income_upper)
  }

  monthlyRangeHasChanged = (prevFormValues) => {
    let { formValues } = this.props
    return (formValues.monthly_rental_income_lower !== prevFormValues.monthly_rental_income_lower) ||
           (formValues.monthly_rental_income_upper !== prevFormValues.monthly_rental_income_upper)
  }

  yearlyRangeHasChanged = (prevFormValues) => {
    let { formValues } = this.props
    return (formValues.yearly_rental_income_lower !== prevFormValues.yearly_rental_income_lower) ||
           (formValues.yearly_rental_income_upper !== prevFormValues.yearly_rental_income_upper)
  }

  houseConditionHasChanged = (prevFormValues) => {
    let { formValues } = this.props
    return formValues.house_condition !== prevFormValues.house_condition
  }

  updateWeeklyRangeFieldsFromMarketRent = () => {
    let { marketRent, formValues, change } = this.props

    if (marketRent && marketRent.length) {
      const marketRentRecord = marketRent.find(item =>
        (item.dwelling === formValues.dwelling) &&
        (item.number_of_bedrooms === formValues.number_of_bedrooms)
      )

      if (marketRentRecord) {
        // 50 represents the midpoint of 0-100
        const weeklyRentalIncome = calculateRentalIncome(50, marketRentRecord.lower_quartile, marketRentRecord.upper_quartile)
        change('weekly_rental_income_lower', weeklyRentalIncome - INCOME_RANGE_ADJUSTMENT)
        change('weekly_rental_income_upper', weeklyRentalIncome + INCOME_RANGE_ADJUSTMENT)
      }
    }
  }

  updateRangeFields(basedOn) {
    let { change, formValues } = this.props

    const upperRange = formValues[`${basedOn.toLowerCase()}_rental_income_upper`]
    const lowerRange = formValues[`${basedOn.toLowerCase()}_rental_income_lower`]
    const weeklyUpperRange = convertValueByFrequency(upperRange, basedOn, WEEKLY)
    const weeklyLowerRange = convertValueByFrequency(lowerRange, basedOn, WEEKLY)

    const weeklyRentalIncome = calculateRentalIncome(formValues.house_condition, weeklyLowerRange, weeklyUpperRange)
    if (weeklyRentalIncome) change('weekly_rental_income', weeklyRentalIncome)
    if (basedOn !== WEEKLY) {
      change('weekly_rental_income_lower', weeklyLowerRange)
      change('weekly_rental_income_upper', weeklyUpperRange)
    }
    if (basedOn !== MONTHLY) {
      change('monthly_rental_income_lower', convertValueByFrequency(lowerRange, basedOn, MONTHLY))
      change('monthly_rental_income_upper', convertValueByFrequency(upperRange, basedOn, MONTHLY))
    }
    if (basedOn !== YEARLY) {
      change('yearly_rental_income_lower', convertValueByFrequency(lowerRange, basedOn, YEARLY))
      change('yearly_rental_income_upper', convertValueByFrequency(upperRange, basedOn, YEARLY))
    }
  }

  updateWeeklyRentalIncomeField = () => {
    let { change, formValues } = this.props
    const weeklyRentalIncome = calculateRentalIncome(
      formValues.house_condition,
      formValues.weekly_rental_income_lower,
      formValues.weekly_rental_income_upper,
    )
    if (weeklyRentalIncome) change('weekly_rental_income', weeklyRentalIncome)
  }

  render() {
    return (
      <div>
        <Input name='weekly_rental_income' type='string' hidden={true} />
        <FormRow label='Estimated weekly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs period='weekly'disabled={true} />
        </FormRow>
        <FormRow label='Estimated monthly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs period='monthly' disabled={true} />
        </FormRow>
        <FormRow label='Estimated yearly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs period='yearly'disabled={true} />
        </FormRow>
      </div>
    )
  }
}

const mapDispatchToProps = {
  change: (name, value) => change(CALCULATOR_FORM_NAME, name, value),
  fetchMarketRent
}

const mapStateToProps = state => ({
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  marketRent: state.marketRent.data,
  marketRentRequestState: state.marketRent.requestState
})

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedRentalIncome)
