import { marketRentTypes } from 'actions/types'
import { get } from 'lib/reduxHelpers/thunks'

function fetchMarketRent(sa2_code, meshblock, suburb, city) {
  let baseType = marketRentTypes.FETCH_MARKET_RENT

  let url = sa2_code
    ? `${process.env.REACT_APP_API_URL}/market-rent/sa2?sa2_code=${sa2_code}&suburb=${suburb}&city=${city}`
    : `${process.env.REACT_APP_API_URL}/market-rent/meshblock?mb=${meshblock}&suburb=${suburb}&city=${city}`

  return get({ baseType, url })
}

const clearMarketRent = () => dispatch => {
  dispatch({type: marketRentTypes.CLEAR_MARKET_RENT})
  dispatch({type: marketRentTypes.CLEAR_FETCH_MARKET_RENT})
}

export { fetchMarketRent, clearMarketRent }
