import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {reset} from 'redux-form'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'

import {clearMarketRent} from 'actions/marketRentActions'
import {clearCoreLogic} from 'actions/coreLogicActions'

import useCalculations from 'hooks/useCalculations'
import useAccount from 'hooks/useAccount'
import PremiumLink from 'components/PremiumLink'

import inactiveIcon from 'assets/static/icons/calculator-white.svg'
import activeIcon from 'assets/static/icons/calculator-active.svg'
import badgeIcon from 'assets/static/icons/badge.svg'

import styles from './styles.module.scss'

const NewCalculationMenuButton = ({history, reset, clearMarketRent, clearCoreLogic}) => {
  const [calculations] = useCalculations()
  const [account] = useAccount()

  const handleClick = () => {
    reset(CALCULATOR_FORM_NAME)
    clearMarketRent()
    clearCoreLogic()
    history.push('/calculator')
  }

  return calculations.length ? (
    <PremiumLink onClick={handleClick} className={styles.premium}>
      <div className={styles.default}>
        {inactiveIcon && <img className={styles.defaultIcon} alt='logo' src={inactiveIcon} />}
        <p>New Calculation</p>
        {account && !account.premium ? <img className={styles.premiumBadge} alt='premium' src={badgeIcon} /> : null}
      </div>
    </PremiumLink>
  ) : (
    <div onClick={handleClick} className={[styles.default, styles.notPremium].join(' ')}>
      {activeIcon && inactiveIcon && (
        <div>
          <img className={styles.activeIcon} alt='logo' src={activeIcon} />
          <img className={styles.defaultIcon} alt='logo' src={inactiveIcon} />
        </div>
      )}
      <p>New Calculation</p>
    </div>
  )
}

const mapDispatchToProps = {
  clearMarketRent,
  clearCoreLogic,
  reset
}

export {NewCalculationMenuButton}
export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withRouter
)(NewCalculationMenuButton)
