import axios, {CancelToken, isCancel} from 'axios'
import URL from 'url'

const requests = {}
const requestKey = ({url, method, params}) => {
  // given a config, build a key from the config url, method
  // and params to be used when recording a request
  const pathname = URL.parse(url).pathname
  return [`${pathname}/${method}`, JSON.stringify(params)].join('&')
}

const configuredAxiosInstance = () => {
  let instance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem('realesmart.jwt')
    config.headers.Authorization = token ? `Bearer ${token}` : ''

    // define a cancelToken on each request
    config.cancelToken = new CancelToken(handleCancel => {
      const key = requestKey(config)
      if (key in requests) {
        // cancel the request if a duplicate request is pending
        handleCancel()
      } else {
        // record the request as pending
        requests[key] = handleCancel
      }
    })

    return config
  })

  instance.interceptors.response.use(
    response => {
      // clear the record of finished requests
      delete requests[requestKey(response.config)]
      return response
    },
    error => {
      if (isCancel(error)) {
        // do nothing when requests are cancelled
        return Promise.resolve()
      } else {
        // clear the record of finished requests
        delete requests[requestKey(error.config)]
        return Promise.reject(error)
      }
    }
  )

  return instance
}

export default configuredAxiosInstance()
