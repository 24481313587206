import {combineReducers} from 'redux'
import account from './accountReducer'
import calculation from './calculationReducer'
import calculations from './calculationsReducer'
import marketRent from './marketRentReducer'
import coreLogic from './coreLogicReducer'
import expenses from './expensesReducer'
import upload from './uploadReducer'
import flash from './flashReducer'
import form from './formReducer'

export default combineReducers({
  account,
  calculation,
  calculations,
  form,
  marketRent,
  expenses,
  upload,
  coreLogic,
  flash
})
