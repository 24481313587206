import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import useAccount from 'hooks/useAccount'
import ContentSection from 'components/ContentSection'
import Banner from 'components/Banner'
import PremiumTag from 'components/PremiumTag'
import rightArrow from 'assets/static/icons/right.svg'
import downArrow from 'assets/static/icons/down.svg'
import styles from './styles.module.scss'

const FormSectionWrapper = ({heading, children, info, warning, warningOnClick, isAccordion, isPremium}) => {
  const [account] = useAccount()
  const [isClosed, setIsClosed] = useState(true)

  const toggleAccordion = () => {
    if (isAccordion) setIsClosed(!isClosed)
  }

  let klass = styles.heading
  if (isAccordion && isClosed) {
    klass = [styles.closedAccordionHeading, 'clickable'].join(' ')
  } else if (isAccordion) {
    klass = [styles.accordionHeading, 'clickable'].join(' ')
  }

  const accountIsPremium = account && account.premium

  const displayWarningOrInfo = () => {
    if (warning) {
      return (
        <Banner type='warning' onClick={warningOnClick}>
          <p>{warning}</p>
        </Banner>
      )
    } else if (info) {
      return (
        <Banner type='info'>
          <p>{info}</p>
        </Banner>
      )
    }
  }

  return (
    <Fragment>
      {isPremium && !accountIsPremium ? (
        <header className={klass}>
          <h2>{heading}</h2>
          <PremiumTag />
        </header>
      ) : (
        <header className={klass} onClick={toggleAccordion}>
          <h2>{heading}</h2>
          {isAccordion && <img className={styles.icon} src={isClosed ? rightArrow : downArrow} alt='arrow' />}
        </header>
      )}
      {(!isPremium || accountIsPremium) && (
        <ContentSection className={styles.section}>
          {displayWarningOrInfo()}
          {children}
        </ContentSection>
      )}
    </Fragment>
  )
}

FormSectionWrapper.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default FormSectionWrapper
