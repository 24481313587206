const formatFetchResponse = data => {
  let result = {
    corelogic: {},
    capital_value: '',
    image_url: '',
    median_sale_price: '',
    avm_value_change: '',
    rental_rate_change: '',
    beds: '',
    baths: '',
    car_spaces: '',
    year_built: '',
    last_sale_date: '',
    last_sale_value: '',
    units_of_use: '',
    land_area: '',
    floor_area: '',
    site_cover: '',
    wall_material: '',
    deck: '',
    pool: ''
  }

  if (!data || data.error) return result

  result.corelogic = data

  if (data.cv && Object.entries(data.cv).length && !data.cv.error) {
    result.capital_value = data.cv.value
  }

  if (data.image && Object.entries(data.image).length && !data.image.error) {
    result.image_url = data.image.url
  }

  if (data.market_trends && Object.entries(data.market_trends).length && !data.market_trends.error) {
    result.median_sale_price = data.market_trends.metrics.find(m => m.metric_id === 21).value.replace(/[$%]/, '')
    result.avm_value_change = data.market_trends.metrics.find(m => m.metric_id === 72).value.replace(/[$%]/, '')
    result.rental_rate_change = data.market_trends.metrics.find(m => m.metric_id === 79).value.replace(/[$%]/, '')
  }

  if (data.last_sale && Object.entries(data.last_sale).length) {
    result.last_sale_date = data.last_sale.date
    result.last_sale_value = data.last_sale.value
  }

  const PROPERTY_INFO_KEYS = [
    'beds',
    'baths',
    'car_spaces',
    'year_built',
    'last_sale_value',
    'last_sale_date',
    'units_of_use',
    'land_area',
    'floor_area',
    'site_cover',
    'wall_material',
    'deck',
    'pool'
  ]

  Object.entries(data).forEach(([key, value]) => {
    if (PROPERTY_INFO_KEYS.includes(key)) {
      if (value === 'N') {
        value = 'no'
      } else if (value === 'Y') {
        value = 'yes'
      }

      if (typeof value === 'string') value = value.charAt(0).toUpperCase() + value.slice(1)

      result[key] = value
    }
  })

  return result
}

export default formatFetchResponse
