import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {emailAssessment} from 'actions/calculationActions'
import {setFlash} from 'actions/flashActions'

import {PROPERTY_PROFESSIONAL} from 'constants/text'
import {getRequestStatus} from 'lib/selectors/getRequestStatus.js'
import useAccount from 'hooks/useAccount'

import BrandLogo from 'components/BrandLogo'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'
import RentalAssessmentReview from 'components/RentalAssessmentReview'
import ToolTip from 'components/ToolTip'

import styles from './styles.module.scss'

const mapState = state => ({
  requestStatus: getRequestStatus(state.calculation.emailAssessmentRequestState)
})

export const RentalAssessmentEmail = ({calculationID, formValues, formErrors, onSubmit}) => {
  const dispatch = useDispatch()
  const {requestStatus} = useSelector(mapState)
  // eslint-disable-next-line
  const [account, accountRequestStatus, refreshAcccount] = useAccount()

  const [email, setEmail] = useState('')
  const [useLogo, setUseLogo] = useState(!!account.brand_logo_url)
  const [signature, setSignature] = useState(account.email_signature || '')
  const [isPropertyPro, setIsPropertyPro] = useState(account.is_property_professional || false)
  const [showReviewSection, setShowReviewSection] = useState(false)

  useEffect(() => {
    if (!isPropertyPro) {
      setShowReviewSection(false)
      setUseLogo(false)
    }
  }, [isPropertyPro])

  const handleSignatureChange = ({ currentTarget }) => {
    setSignature(currentTarget.value)
  }

  const handleEmailChange = ({ currentTarget }) => {
    setEmail(currentTarget.value)
  }

  const handleLogoAnswer = ({ currentTarget }) => {
    setUseLogo(currentTarget.checked)
  }

  const handleTogglePropertyPro = ({ currentTarget }) => {
    setIsPropertyPro(currentTarget.checked)
  }

  const handleToggleShowReviewSection = showReviewSection => () => {
    setShowReviewSection(showReviewSection)
  }

  const handleSubmit = e => {
    const {estimate_weekly_lower, estimate_weekly_upper, estimate_reason} = formValues
    emailAssessment({
      to: email,
      use_logo: useLogo,
      signature,
      is_property_professional: isPropertyPro,
      estimate_weekly_lower,
      estimate_weekly_upper,
      estimate_reason,
    }, calculationID)(dispatch).then(() => {
      setFlash({success: `Rental assessment email was sent to ${email}`})(dispatch)
      if (isPropertyPro !== account.is_property_professional) refreshAcccount()
      onSubmit()
    })
    e.preventDefault()
  }

  // if the calculation has estimate values filled in previously, expand the estimates view.
  const estimatesValuesExist = () => {
    return (
      formValues.estimate_reason ||
      formValues.estimate_weekly_lower !== null ||
      formValues.estimate_weekly_upper !== null
    )
  }

  const disableSubmit = (useLogo && !account.brand_logo_url) || Object.keys(formErrors).length || formValues.estimate_weekly_upper === 0
  return (
    <ContentSection className={styles.content}>
      <h2>Email Rental Assessment</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.field}>
          <label>
            Send to:
            <input name='email' type='email' placeholder='Enter Email' onChange={handleEmailChange} required />
          </label>
        </div>
        <div className={styles.propertyProContainer}>
          <label>
            <input
              type='checkbox'
              name='is_property_professional'
              checked={isPropertyPro}
              onChange={handleTogglePropertyPro}
            />
             I warrant that I am a professional property manager or a real estate professional
          </label>
          <ToolTip toolTipText={PROPERTY_PROFESSIONAL} />
        </div>
        {isPropertyPro && (showReviewSection || estimatesValuesExist()) ? (
          <RentalAssessmentReview
            formValues={formValues}
            formErrors={formErrors}
            onCancelReview={handleToggleShowReviewSection(false)}
          />
        ) : null}
        <div className={styles.field}>
          <label>
            Use signature:
            <textarea
              rows='4'
              name='signature'
              value={signature}
              placeholder='Enter Signature'
              onChange={handleSignatureChange}
            />
          </label>
        </div>
        {isPropertyPro &&
          <label>
            <input
              name='use_logo'
              type='checkbox'
              checked={useLogo}
              onChange={handleLogoAnswer}
            />
            Use brand logo?
          </label>
        }
        {useLogo && <BrandLogo />}
        <ButtonGroup>
          {(isPropertyPro && !estimatesValuesExist()) && !showReviewSection && (
            <div className={styles.reviewButton}>
              <Button
                type='button'
                secondary
                text='Review rental appraisal'
                onClick={handleToggleShowReviewSection(true)}
              />
            </div>
          )}
          <Button
            type='submit'
            text='Send'
            disabled={disableSubmit}
            requestStatus={requestStatus}
          />
        </ButtonGroup>
      </form>
    </ContentSection>
  )
}

export default withRouter(RentalAssessmentEmail)
