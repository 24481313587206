import {useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getFormValues, isDirty} from 'redux-form'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {getRequestStatus, SUCCESS} from 'lib/selectors/getRequestStatus.js'
import useEffectOnUpdate from 'hooks/useEffectOnUpdate'
import {fetchCoreLogic} from 'actions/coreLogicActions'

const mapState = state => ({
  premium: state.account.data && state.account.data.premium,
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  isDirty: isDirty(CALCULATOR_FORM_NAME)(state),
  fetchAccountStatus: getRequestStatus(state.account.fetchAccountRequestState),
  fetchCalculationStatus: getRequestStatus(state.calculation.requestState)
})

const EXPECTED_VALUES = ['capital_value', 'image_url', 'median_sale_price', 'avm_value_change', 'rental_rate_change']
const missingSomeExpectedValues = formValues => EXPECTED_VALUES.some(key => !formValues[key])

const useCoreLogic = () => {
  const dispatch = useDispatch()
  const {premium, formValues = {}, isDirty, fetchCalculationStatus, fetchAccountStatus} = useSelector(mapState)

  const fetch = () => premium && formValues.address && fetchCoreLogic(formValues.address)(dispatch)

  useEffectOnUpdate(() => {
    if (fetchAccountStatus === SUCCESS) fetch()
  }, [fetchAccountStatus]) // fetchCoreLogic when becoming premium

  const wasDirtyRef = useRef(isDirty)
  useEffectOnUpdate(() => {
    // A 'new calculation' will fetch on any address selection, but a 'loaded calculation'
    // should only fetch when an address is selected AFTER the calculation has been loaded
    // (and form is dirty).
    if (!formValues.id || wasDirtyRef.current) fetch()
    wasDirtyRef.current = isDirty
  }, [formValues.address]) // fetchCoreLogic when address is selected

  useEffectOnUpdate(() => {
    if (fetchCalculationStatus === SUCCESS && missingSomeExpectedValues(formValues)) fetch()
  }, [fetchCalculationStatus]) // fetchCoreLogic when edit form is missing corelogic values
}

export default useCoreLogic
