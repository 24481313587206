import React, {useState, useEffect} from 'react'
import {reduxForm, reset} from 'redux-form'
import {Redirect} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import queryString from 'query-string'
import {CALCULATOR_FORM_NAME, calculatorFormValidation, calculatorFormInitialValues} from 'constants/reduxFormConfig'

import {setFlash, clearFlash} from 'actions/flashActions'
import useAccount from 'hooks/useAccount'
import useCalculation from 'hooks/useCalculation'
import useCalculations from 'hooks/useCalculations'
import useCalculationSubmit from 'hooks/useCalculationSubmit'
import useCoreLogic from 'hooks/useCoreLogic'
import useFacebookEvents from 'hooks/useFacebookEvents'
import {deleteCalculation} from 'actions/calculationActions'

import Borrowing from 'components/Calculator/Borrowing'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import CalculatorLayout from 'components/layouts/CalculatorLayout'
import ContentSection from 'components/ContentSection'
import Expenses from 'components/Calculator/Expenses'
import Investment from 'components/Calculator/Investment'
import MarketTrends from 'components/Calculator/MarketTrends'
import Popup from 'components/Popup'
import PremiumLink from 'components/PremiumLink'
import PropertyInformation from 'components/Calculator/PropertyInformation'
import RentalAssessment from 'components/Calculator/RentalAssessment'
import RentalAssessmentEmail from 'components/RentalAssessmentEmail'
import RequestWrapper from 'components/RequestWrapper'

import styles from './styles.module.scss'

export const Calculator = ({match, history, location}) => {
  const calculationID = match.params.id

  const dispatch = useDispatch()

  const [emailingAssessment, setEmailingAssessment] = useState(false)
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [calculationIdToDelete, setCalculationIdToDelete] = useState(null)

  const [account] = useAccount()
  const [calculations] = useCalculations()
  const {formValues, fetchStatus: fetchCalculationStatus, formErrors} = useCalculation(calculationID)
  const onSubmit = useCalculationSubmit(calculationID)
  useCoreLogic()
  useFacebookEvents(queryString.parse(location.search))

  useEffect(() => {
    if (formValues) {
      clearFlash()(dispatch) // clear saved message on form change
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const dismissError = () => setError(null)

  const handleReset = () => {
    dispatch(reset(CALCULATOR_FORM_NAME))
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }

  const handleSubmit = () => {
    setSaving(true)
    if (calculationIdToDelete) {
      deleteCalculation(calculationIdToDelete)(dispatch)
    }
    onSubmit().then(({error, data}) => {
      if (error) {
        setError(error)
      } else {
        if (!calculationID) setFlash({success: `Rental assessment saved`, scroll: true})(dispatch)
        history.push(`/calculator/${data.id}`)
      }
      setSaving(false)
    })
  }

  const openEmailAssessment = () => {
    onSubmit()
    setEmailingAssessment(true)
  }

  const closeEmailAssessment = () => {
    setEmailingAssessment(false)
  }

  const getSubmitButtonText = () => {
    if (saving) return 'Saving'
    return calculationID ? 'Update' : 'Save'
  }

  const renderButtons = () => (
    <ButtonGroup>
      {!calculationID && <Button text='Start again' onClick={handleReset} link />}
      <Button text={getSubmitButtonText()} onClick={handleSubmit} />
    </ButtonGroup>
  )

  const handleAddressChanged = id => {
    if (id) setCalculationIdToDelete(id)
    history.push(`/calculator`)
  }

  let editingReadonlyCalculation = calculationID && formValues && formValues.readonly
  let newCalculationForNonPremium = !calculationID && account && !account.premium

  return (
    <CalculatorLayout onSubmit={handleSubmit}>
      <Popup isOpen={!!error}>
        <ContentSection>
          <p>{error}</p>
          <Button text='OK' onClick={dismissError} />
        </ContentSection>
      </Popup>
      <RequestWrapper requestStates={[fetchCalculationStatus]}>
        {editingReadonlyCalculation || (newCalculationForNonPremium && calculations.length) ? (
          <Redirect to='/calculations' />
        ) : null}
        <Popup isOpen={emailingAssessment} onClose={closeEmailAssessment} className={styles.emailAssessment}>
          <RentalAssessmentEmail
            onSubmit={closeEmailAssessment}
            calculationID={calculationID}
            formValues={formValues}
            formErrors={formErrors}
          />
        </Popup>
        <RentalAssessment calculationID={calculationID} onAddressChanged={handleAddressChanged}>
          <ButtonGroup>
            {calculationID ? (
              <PremiumLink onClick={openEmailAssessment}>
                <Button text='Email Assessment' />
              </PremiumLink>
            ) : (
              <Button text='Start again' onClick={handleReset} link />
            )}
            <Button text={getSubmitButtonText()} onClick={handleSubmit} />
          </ButtonGroup>
        </RentalAssessment>
        <Borrowing>{renderButtons()}</Borrowing>
        <Expenses>{renderButtons()}</Expenses>
        <Investment>{renderButtons()}</Investment>
        <MarketTrends>{renderButtons()}</MarketTrends>
        <PropertyInformation>{renderButtons()}</PropertyInformation>
      </RequestWrapper>
    </CalculatorLayout>
  )
}

export default reduxForm({
  form: CALCULATOR_FORM_NAME,
  validate: calculatorFormValidation,
  initialValues: calculatorFormInitialValues
})(Calculator)
