const CURRENCY = 'CURRENCY'
const PERCENTAGE = 'PERCENTAGE'
const YEARS = 'YEARS'
const NUMBER = 'NUMBER'
const METERS_SQUARED = 'METERS_SQUARED'

export {
  CURRENCY,
  PERCENTAGE,
  YEARS,
  NUMBER,
  METERS_SQUARED
}