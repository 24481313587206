// Core dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'

// Constants
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Components
import Slider from 'components/Slider'
import Input from 'components/Input'
import FormRow from 'components/FormRow'
import RadioButtons from 'components/RadioButtons'

import styles from './styles.module.scss'

const QUALITY_SLIDER_MARKS = {0: 'Awful', 50: 'Average', 100: 'Awesome'}

class PropertyCondition extends Component {

  componentDidMount() {
    this.setHouseConditionSliderWidth()
    window.addEventListener('resize', this.setHouseConditionSliderWidth)
  }

  componentDidUpdate() {
    this.setDwellingOption()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHouseConditionSliderWidth)
  }

  setHouseConditionSliderWidth() {
    let houseConditionSlider = document.getElementById('house_condition_slider')
    if (houseConditionSlider) {
      let rail = houseConditionSlider.getElementsByClassName('rc-slider-rail')[0]
      let marks = houseConditionSlider.getElementsByClassName('rc-slider-mark')[0]
      let railPostion = rail.getBoundingClientRect()
      marks.style.width = railPostion.width - 60 + 'px'
      marks.style.left = -15 + 'px'
    }
  }

  renderInput = (name) => {
    return <Input name={name} type='number' />
  }

  handleTypeChange = (e) => {
    let { change } = this.props
    let dwellingType = e.target.id
    change(CALCULATOR_FORM_NAME, 'dwelling', dwellingType)
  }

  // If the selected dwelling option is not available for this property, select the first option. This prevents
  // errors when trying to set the bedroom quantity.
  setDwellingOption = () => {
    let { propertyTypes, formValues } = this.props
    let value = formValues ? formValues['dwelling'] : null
    if (!propertyTypes.includes(value)) {
      this.handleTypeChange({target: {id: propertyTypes[0]}})
    }
  }

  render() {
    let { propertyTypes } = this.props
    return (
      <Fragment>
        <FormRow label={'Property Quality'}>
          <Slider min={0} max={100} name={'house_condition'} step={1} marks={QUALITY_SLIDER_MARKS} />
        </FormRow>
        <FormRow>
          <div className={styles.radioButtonContainer}>
            <RadioButtons name={'dwelling'} options={propertyTypes} onChange={this.handleTypeChange} />
          </div>
        </FormRow>
      </Fragment>
    )
  }
}

const mapDispatchToProps = { change }

export { PropertyCondition }
export default connect(
  state => {
    return {
      formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
    }
  }, mapDispatchToProps
)(PropertyCondition)