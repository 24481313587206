import React, {Component} from 'react'
import {compose} from 'redux'
import {reduxForm, getFormValues, change, getFormSubmitErrors} from 'redux-form'
import {connect} from 'react-redux'
import {RESET_PASSWORD_FORM_NAME, resetPasswordFormValidation} from 'constants/reduxFormConfig'
import {resetPassword} from 'actions/accountActions'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import {throwSubmissionError} from 'lib/errorUtils'
import DefaultLayout from 'components/layouts/DefaultLayout'
import FormRow from 'components/FormRow'
import Button from 'components/Button'
import RequestWrapper from 'components/RequestWrapper'
import FormField from 'components/FormField'
import FormError from 'components/FormError'
import styles from './styles.module.scss'

export class ResetPassword extends Component {
  componentDidMount() {
    let {match, change} = this.props
    let resetToken = match.params.id
    if (resetToken) {
      change('reset_password_token', resetToken)
    }
  }

  handleResetPassword = () => {
    let {formValues, resetPassword} = this.props
    return resetPassword(formValues).catch(throwSubmissionError)
  }

  render() {
    let {resetPasswordRequestState, handleSubmit, submitError} = this.props
    const successMessage = submitError ? undefined : 'Your password was successfully reset'
    return (
      <DefaultLayout pageTitle='Reset Password'>
        <form className={styles.formContainer} onSubmit={handleSubmit(this.handleResetPassword)}>
          <RequestWrapper requestStates={[resetPasswordRequestState]} successMessage={successMessage}>
            <FormRow label='Password'>
              <FormField name='password' type='password' />
            </FormRow>
            <FormRow label='Confirm Password'>
              <FormField name='password_confirmation' type='password' />
            </FormRow>
            <FormError error={submitError} />
            <input className={styles.hiddenField} name='reset_password_token' type='string' />
            <Button text='Submit' type='submit' />
          </RequestWrapper>
        </form>
      </DefaultLayout>
    )
  }
}

const mapDispatchToProps = {resetPassword, change}
const mapStateToProps = state => ({
  formValues: getFormValues(RESET_PASSWORD_FORM_NAME)(state),
  resetPasswordRequestState: getRequestStatus(state.account.resetPasswordRequestState),
  submitError: getFormSubmitErrors(RESET_PASSWORD_FORM_NAME)(state).error
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: RESET_PASSWORD_FORM_NAME,
    validate: resetPasswordFormValidation
  })
)(ResetPassword)
