import React from 'react'
import {Link} from 'react-router-dom'
import corelogicTrusted from 'assets/static/corelogic-trusted-blue.svg'
import styles from './styles.module.scss'

const Footer = () => (
  <div className={styles.container}>
    <p>
      <Link to='/termsandconditions#corelogic'>Property information and photos</Link>
      <span> supplied by</span>
    </p>
    <img src={corelogicTrusted} alt='CoreLogic trusted logo' className={styles.corelogicTrusted} />
  </div>
)

export default Footer
