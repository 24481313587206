import React, {Fragment, Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {fetchAccount, cancelSubscription, uncancelSubscription} from 'actions/accountActions'
import Button from 'components/Button'
import Popup from 'components/Popup'
import ButtonGroup from 'components/ButtonGroup'
import ContentSection from 'components/ContentSection'

export const controlledComponent = ComposedComponent =>
  class ControlledComponent extends Component {
    constructor(props) {
      super(props)
      this.state = {isCancelling: false}
    }

    handleCancelSubscription = () => {
      this.props.cancelSubscription().then(() => {
        setTimeout(() => {
          // give the api server time to update the account
          this.props.fetchAccount().then(() => {
            this.setState({isCancelling: false})
          })
        }, 500)
      })
    }

    handleUncancelSubscription = () => {
      this.props.uncancelSubscription().then(() => {
        setTimeout(() => {
          // give the api server time to update the account
          this.props.fetchAccount()
        }, 500)
      })
    }

    handleCancelStart = () => {
      this.setState({isCancelling: true})
    }

    handleCancelEnd = () => {
      this.setState({isCancelling: false})
    }

    render() {
      let {isCancelling} = this.state

      return (
        <Fragment>
          <Popup isOpen={isCancelling}>
            <ContentSection>
              <Fragment>
                <h2>Here are some things you should know:</h2>
                <ul>
                  <li>Your account will remain premium until the end of your billing cycle</li>
                  <li>You can cancel your downgrade before your billing cycle ends</li>
                  <li>You can upgrade again in the future</li>
                </ul>
                <p>Are you sure you want to downgrade?</p>
                <ButtonGroup>
                  <Button onClick={this.handleCancelSubscription} text='Yes' />
                  <Button onClick={this.handleCancelEnd} text='No' />
                </ButtonGroup>
              </Fragment>
            </ContentSection>
          </Popup>
          <ComposedComponent
            {...this.props}
            handleUncancelSubscription={this.handleUncancelSubscription}
            handleCancelSubscription={this.handleCancelStart}
          />
        </Fragment>
      )
    }
  }

const mapDispatchToProps = {fetchAccount, cancelSubscription, uncancelSubscription}

const subscriptionManageable = ComposedComponent => {
  return compose(
    connect(
      null,
      mapDispatchToProps
    ),
    controlledComponent
  )(ComposedComponent)
}

export default subscriptionManageable
