import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {reduxForm, getFormValues, getFormSubmitErrors} from 'redux-form'
import {CREATE_ACCOUNT_FORM_NAME, createAccountFormValidation} from 'constants/reduxFormConfig'
import useStripe from 'hooks/useStripe'
import useFacebookEvents from 'hooks/useFacebookEvents'
import FullScreenLayout from 'components/layouts/FullScreenLayout'
import RequestWrapper from 'components/RequestWrapper'
import FormField from 'components/FormField'
import FormError from 'components/FormError'
import Form, {FormBody, FormSubmit, FormFooter} from 'components/Form'
import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import {throwSubmissionError} from 'lib/errorUtils'
import queryString from 'query-string'
import {login, createAccount, checkCouponIsValid} from 'actions/accountActions'
import {SubmissionError} from 'redux-form'

export const CreateAccount = ({
  location,
  createAccount,
  formValues,
  login,
  checkCouponIsValid,
  createAccountStatus,
  loginStatus,
  loggedIn,
  handleSubmit,
  submitError
}) => {

  const state = location.state || {}

  const {stripeCheckout} = useStripe({
    success_url: `${window.location.origin}/#/calculator?referrer=premiumsuccess`,
    cancel_url: `${window.location.origin}/#/calculator`,
    coupon_code: formValues && formValues.coupon_code
  })

  useFacebookEvents(queryString.parse(location.search))

  const handleCreateAccount = () =>
    createAccount(formValues)
      .then(() => login(formValues))
      .then(() => state.goPremium && stripeCheckout())
      .catch(throwSubmissionError)

  const checkCouponCode = () =>
    checkCouponIsValid(formValues.coupon_code)
      .catch((error) => {
        const errorMessage = error.response.data.error + ' Leave this field blank to signup without a coupon code'
        throw new SubmissionError({ 'error': errorMessage })
      })
      .then(() => handleCreateAccount())

  const onSubmit = () => {
    return formValues && formValues.coupon_code ?
      checkCouponCode() :
      handleCreateAccount()
  }

  let stripeStatus = loginStatus

  if (loggedIn) {
    if (!state.goPremium) {
      return <Redirect push to='/calculator?referrer=freesuccess' />
    } else {
      stripeStatus = null
    }
  }

  return (
    <FullScreenLayout>
      <RequestWrapper requestStates={[createAccountStatus]} color='#FFFFFF'>
        <RequestWrapper requestStates={[loginStatus, stripeStatus]} color='#FFFFFF'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormBody>
              <label htmlFor='name' hidden>Name</label>
              <FormField name='name' placeholder='Full Name' type='string' darkTheme />
              <label htmlFor='email' hidden>Name</label>
              <FormField name='email' placeholder='Email' type='email' darkTheme />
              <label htmlFor='password' hidden>Name</label>
              <FormField name='password' placeholder='Password' type='password' darkTheme />
              <label htmlFor='password_confirmation' hidden>Name</label>
              <FormField name='password_confirmation' placeholder='Confirm Password' type='password' darkTheme />
              { state.withCouponCode ? (
                  <>
                    <label htmlFor='password_confirmation' hidden>Coupon Code</label>
                    <FormField name='coupon_code' placeholder='Coupon Code' type='text' darkTheme />
                  </>
                ) : null
              }
              <FormError error={submitError} darkTheme />
            </FormBody>
            <FormSubmit text='Sign Up' />
            <FormFooter>
              <p>
                Clicking "<i>Sign Up</i>" means that you agree to the{' '}
                <Link to='/termsandconditions'>Terms and Conditions</Link> and{' '}
                <Link to='/privacypolicy'>Privacy Policy</Link>.
              </p>
            </FormFooter>
          </Form>
        </RequestWrapper>
      </RequestWrapper>
    </FullScreenLayout>
  )
}

const mapDispatchToProps = {login, createAccount, checkCouponIsValid}
const mapStateToProps = state => ({
  loggedIn: state.account.loggedIn,
  formValues: getFormValues(CREATE_ACCOUNT_FORM_NAME)(state),
  createAccountStatus: getRequestStatus(state.account.createAccountRequestState),
  loginStatus: getRequestStatus(state.account.requestState),
  submitError: getFormSubmitErrors(CREATE_ACCOUNT_FORM_NAME)(state).error
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: CREATE_ACCOUNT_FORM_NAME,
    validate: createAccountFormValidation
  })
)(CreateAccount)
