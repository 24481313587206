import {expensesTypes} from 'actions/types'
import {get} from 'lib/reduxHelpers/thunks'

function fetchDefaultCalculatorValues() {
  let baseType = expensesTypes.FETCH_DEFAULT_CALCULATOR_VALUES
  let url = `${process.env.REACT_APP_API_URL}/expenses`
  let extractPayload = response =>
    response.data.reduce((result, {name, value}) => {
      result[name] = value
      return result
    }, {})
  return get({baseType, url, extractPayload})
}

export {fetchDefaultCalculatorValues}
