import React, {Fragment} from 'react'
import {NavLink, Link} from 'react-router-dom'
import {compose} from 'redux'
import {connect} from 'react-redux'
import useAccount from 'hooks/useAccount'
import {logout} from 'actions/accountActions'
import logoutIcon from 'assets/static/icons/logout.svg'
import badgeIcon from 'assets/static/icons/badge.svg'
import buttons from './buttons'
import NewCalculationMenuButton from 'components/NewCalculationMenuButton'

import styles from './styles.module.scss'


const MenuItem = ({to, name, activeIcon, inactiveIcon}) => (
  <NavLink to={to} activeClassName={styles.active} className={styles.default}>
    {activeIcon && inactiveIcon && (
      <div>
        <img className={styles.activeIcon} alt='logo' src={activeIcon} />
        <img className={styles.defaultIcon} alt='logo' src={inactiveIcon} />
      </div>
    )}
    <p>{name}</p>
  </NavLink>
)

  export const SideMenu = ({isOpen, toggleDraw, logout}) => {
    const [account] = useAccount()
    return (
      account && (
        <Fragment>
          {isOpen && <div className={styles.overlay} onClick={toggleDraw} />}
          <div className={[styles.sideMenu, isOpen ? styles.show : styles.hide].join(' ')}>
            <header>
              <p>{account.name}</p>
              {account.premium && <img className={styles.premiumIcon} alt='premium' src={badgeIcon} />}
            </header>
            <div className={styles.primaryLinkContainer}>
              <NewCalculationMenuButton />
              <MenuItem {...buttons.calculations} />
              <MenuItem {...buttons.profile} />
              <MenuItem {...buttons.about} />
            </div>
            <div className={styles.secondaryLinkContainer}>
              <Link to='/support'>Support</Link>
              <Link to='/termsandconditions'>Terms and Conditions</Link>
              <Link to='/privacypolicy'>Privacy Policy</Link>
            </div>
            <footer className={styles.logoutContainer} onClick={logout}>
              <img className={styles.logoutIcon} alt='logo' src={logoutIcon} />
              <p>Logout</p>
            </footer>
          </div>
        </Fragment>
      )
    )
  }

export default compose(
  connect(
    null,
    {logout}
  )
)(SideMenu)