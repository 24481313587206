import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import checkTokenExpiration from './middleware'

import reducer from 'reducers/index'

const middleware = applyMiddleware(thunk, checkTokenExpiration)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const initialState = {}

export default createStore(reducer, initialState, composeEnhancers(middleware))