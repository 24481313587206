import axios from 'axios'
import convert from 'xml-js'

import {get, post} from 'lib/reduxHelpers/thunks'
import {uploadTypes} from 'actions/types'

export const getUploadURL = uploadType => (
  get({
    baseType: uploadTypes.GET_UPLOAD_URL,
    url: `${process.env.REACT_APP_API_URL}/uploads/new?upload_type=${uploadType}`
  })
)

export const confirmUploadToURL = (key, calculation_uuid) => {
  const body = calculation_uuid ? { key, calculation_uuid } : { key }
  return post({
    baseType: uploadTypes.CONFIRM_UPLOAD_TO_URL,
    url: `${process.env.REACT_APP_API_URL}/uploads`,
    body,
  })
}

export const uploadToURL = ({url, url_fields, src, name}) => dispatch => {
  dispatch({type: uploadTypes.UPLOAD_TO_URL})

  return axios.get(src, {responseType: 'blob'}).then(response => {
    const data = new FormData()

    Object.entries(url_fields).forEach(([key, value]) => {
      data.append(key, key === 'key' ? value.replace(/\${filename}/, name) : value)
    })

    const blob = new Blob([response.data])
    data.append('file', blob, blob.name)

    return axios
      .post(url, data, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(response => {
        const data = JSON.parse(convert.xml2json(response.data, {compact: true}))
        const url = data.PostResponse.Location._text
        const key = data.PostResponse.Key._text
        dispatch({type: `${uploadTypes.UPLOAD_TO_URL}_FULFILLED`, payload: {url, key}})
        return response
      })
      .catch(error => {
        dispatch({type: `${uploadTypes.UPLOAD_TO_URL}_REJECTED`, payload: error})
        throw error
      })
  })
}

export const clearUpload = () => dispatch => {
  dispatch({type: uploadTypes.CLEAR_UPLOAD})
}
