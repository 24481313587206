// Core Dependencies
import React, { Component } from 'react'

// Styles
import styles from './styles.module.scss'

class FormError extends Component {

  render() {
    let { error, darkTheme } = this.props
    let style = darkTheme ? styles.darkTheme : styles.lightTheme
    return (
      <div className={style}>
        { error && <div>{error}</div> }
      </div>
    )
  }
}

export default FormError
