// Core Dependencies
import React, {Children} from 'react'

// Styles
import styles from './styles.module.scss'

const ButtonGroup = ({children, className = ''}) => {
  const childCount = Children.toArray(children).filter(child => !!child).length // count non-null children
  const klassName = childCount > 1 ? styles.buttonGroup : styles.buttonWrapper
  return <div className={`${klassName} ${className}`}>{children}</div>
}

export default ButtonGroup
