// Core Dependencies
import React, {Component} from 'react'
import * as scriptjs from 'scriptjs'
import {Field} from 'redux-form'
import PropTypes from 'prop-types'

// Styles
import styles from './styles.module.scss'

const API_KEY = process.env.REACT_APP_ADDRESSFINDER_KEY
const OPTIONS = {
  show_locations: false,
  show_nearby: true,
  manual_style: true,
  address_params: {post_box: '0'},
  address_metadata_params: {census: 2018}
}

class AddressFinder extends Component {
  constructor() {
    super()
    this.widget = null
  }

  componentDidMount() {
    scriptjs.get('https://api.addressfinder.io/assets/v3/widget.js', this.initialiseWidget)
  }

  componentWillUnmount() {
    if (this.widget) this.widget.destroy()
  }

  initialiseWidget = () => {
    let input = this.input()
    if (input) {
      this.widget = new window.AddressFinder.Widget(input, API_KEY, 'NZ', OPTIONS)
      this.widget.on('result:select', (fullAddress, metaData) => {
        const selection = new window.AddressFinder.NZSelectedAddress(fullAddress, metaData)
        this.props.onSelect(selection, metaData)
      })
    }
  }

  input = () => document.getElementsByName(this.props.inputName)[0]

  resetIconPosition = () => {
    if (this.widget) this.widget._resetIconPosition()
  }

  focus = () => this.input().focus()

  setWidgetWidth = () => {
    const inputPosition = this.input().getBoundingClientRect()
    const afLists = [...document.getElementsByClassName('af_list')]
    afLists.forEach(afList => {
      afList.style.width = inputPosition.width + 'px'
    })
  }

  render() {
    return (
      <Field
        component='input'
        name={this.props.inputName}
        className={styles.input}
        placeholder={this.props.inputPlaceholder}
        autoComplete='disable-autocomplete'
        onKeyDown={this.setWidgetWidth}
      />
    )
  }
}

AddressFinder.propTypes = {
  onSelect: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string
}

export default AddressFinder
