import React from 'react'
import BackButton from 'components/BackButton'
import ContentSection from 'components/ContentSection'
import DefaultLayout from 'components/layouts/DefaultLayout'
import styles from './styles.module.scss'

const About = () => (
  <DefaultLayout pageTitle='About'>
    <ContentSection>
      <BackButton />

      <h1 className={styles.sectionTitle}>
        {`Welcome to RealESmart – your home for smart investing in residential property.`}
      </h1>
      <p>
        {`RealESmart is a residential property investment and property yield
            calculator that simplifies the process for buyers, investors and
            property enthusiasts enabling an understanding of investing in
            property.  RealESmart provides residential property rental
            appraisals, calculates borrowing, provides for expenses associated
            with owning residential property, calculates residential property net
            and gross yields.  RealESmart provides the property investor with an
            easy to use toolkit aiding decision making about investing in
            residential property and gaining an understanding of return on
            investment.`}
      </p>

      <h3>About</h3>
      <p>
        {`RealESmart was based on an excel spreadsheet built to aid residential
            property investment decisions which worked pretty well for 16 years.
            As a spreadsheet, it was not keeping up with technology, property
            market data and ease of use. As residential property investors, it
            was no longer giving us joy so we retired the spreadsheet and made an
            app.   RealESmart is now an easy to use residential property
            investment tool for buyers, investors and property enthusiasts,
            providing property investment data and enabling informed decisions
            about investing in a particular property.`}
      </p>
      <p>
        {`RealESmart is a complementary residential property investment and
            yeild calculator that works alongside your own research and advisory
            team. We hope you enjoy using RealESmart as much as we have had
            building it with the support of our friends at Abletech here in
            Wellington, New Zealand.`}
      </p>

      <h3>Some frequently asked questions:</h3>
      <p>
        <b>{`Q: GPS isn’t working.`}</b>
      </p>
      <p>
        {`Simply check the location services for your browser in your phone settings and
            ensure it is enabled.`}
      </p>

      <p>
        <b>{`Q: How can I add RealESmart to my home screen on my phone for ease of access?`}</b>
      </p>
      <p>{`The process is slightly different depending on what device and browser you are using.`}</p>
      <p>
        {`For iOS, open realesmart.co.nz in Safari. At the bottom of the page, tap the share
            button, then select "Add to Home Screen".`}
      </p>
      <p>
        {`For Android running Chrome, open realesmart.co.nz. Once the page has loaded, tap on
            the vertical ellipsis (three dots) button to reveal more options, then select
            "Add to Home Screen."`}
      </p>
      <p>
        For Android running Firefox, follow{' '}
        <a href='https://support.mozilla.org/en-US/kb/how-add-shortcut-website-android'>these steps</a>.
      </p>

      <p>
        <b>{`Q: I’m doing a search and RealESmart is telling me that rent is “not available”.`}</b>
      </p>
      <p>
        {`Sometimes, there are not enough properties to accurately measure the rental return
            for that particular location. You will need to estimate the amount of return yourself.
            Data on RealESmart is updated monthly. Please check regularly as we need multiple
            data sets to ensure we provide you with decent rental estimates.`}
      </p>
      <p>
        If you have any issues, compliments or feedback, please contact us at{' '}
        <a href='mailto:info@realesmart.co.nz'>info@realesmart.co.nz</a>
      </p>
    </ContentSection>
  </DefaultLayout>
)

export default About
