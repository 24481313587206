import React from 'react'
import classnames from 'classnames'
import queryString from 'query-string'

import Button from 'components/Button'
import { TopNavLink } from 'components/TopNav'

import coreLogicTrusted from 'assets/static/corelogic-trusted-blue.svg'
import craig from 'assets/static/craig-broadhead.jpg'
import headerImage from 'assets/static/header-image.png'
import logo from 'assets/static/realesmart-logo.png'
import testimonialStars from 'assets/static/testimonial-stars.png'
import vesna from 'assets/static/vesna-wells.jpg'
import styles from './styles.module.scss'

const AboutUs = ({ location, history }) => {

  const professional = queryString.parse(location.search).professional || false

  const handleSignInClicked = () => {
    history.push('/signin')
  }

  const handleSignUpClicked = () => {
    history.push('/plans')
  }

  const handleLogoClicked = () => {
    history.goBack()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topBar}>
          <div
            className={styles.logoContainer}
            onClick={handleLogoClicked}>
            <img alt='logo' src={logo} />
          </div>
          <div className={styles.linksContainer}>
            <TopNavLink onClick={handleSignUpClicked}>
              <p className={styles.navLinks}>
                Sign Up
              </p>
            </TopNavLink>
            <hr />
            <TopNavLink onClick={handleSignInClicked}>
              <p className={styles.navLinks}>
                Log In
              </p>
            </TopNavLink>
          </div>
        </div>
        <div className={styles.headerPanel}>
          <div>
            <p className={styles.h1}>
              RealESmart Property Investment Calculator for&nbsp;
              {professional ?
                <>Professionals <em className={styles.blue}>working with Property</em></> :
                <><em className={styles.orange}>Home Buyers</em> and <em className={styles.orange}>Property Investors</em></>
              }
            </p>
            <Button
              className={`${styles.button} ${professional && styles.orangeButton}`}
              text="Sign Up Here"
              onClick={handleSignUpClicked}
            />
          </div>
          <div>
            <img alt="mobile phone" src={headerImage} />
          </div>
        </div>
        <div>
          <div className={styles.welcomeContainer}>
            <div>
              <p className={styles.h2}>
                RealESmart property calculator – <em className={!professional ? styles.orange : styles.blue}>property data you can count on</em>
              </p>
              <img className={styles.coreLogicTrusted} alt='core logic trusted data logo' src={coreLogicTrusted} />
            </div>
            {professional ? (
              <div>
                <p className={styles.h3}>
                  Whether you are a property manager, real estate professional, business adviser or mortgage broker, we know that time is money. Collating appraisals, customising proposals, sourcing data and running the analytics all takes time.
                </p>
                <p className={styles.body}>
                  Our RealESmart property investment calculator has been developed to 'take away the pain and lift the gain' by bringing together independently sourced and highly reliable data sets, building a back-end that can calculate everything from rental appraisals to investment yields, and delivering an interface that is easy to use and fast to deliver.
                </p>
              </div>
            ) : (
              <div>
                <p className={styles.h3}>
                  At RealESmart we know that in the fast moving world of real estate we all have to start somewhere, and that small can be big if you know what you’re doing. So, we have developed the RealESmart property calculator as a tool to make it easier for everybody to make sense of the market.
                </p>
                <p className={styles.body}>
                  Whether you are buying your first home, considering major renovations or looking at buying a rental property, our calculator brings together a range of data from independent and highly reliable sources, enabling you to understand not just the costs of meeting a mortgage, but you can also factor in other costs like rates and insurance premiums.
                </p>
                <p className={styles.body}>
                  It also calculates the returns you might expect – what you could earn if you needed to rent your house out, and what your return on investment would be if you are buying a house for rental purposes.
                </p>
                <p className={styles.body}>
                  RealESmart is easy to use and fast to deliver and it's all in your hands. Find out how a house can be more than a home.
                </p>
              </div>
            )}
          </div>
          <div className={styles.reviewContainer}>
            {professional ? (
              <>
                <div className={styles.review}>
                  <div className={styles.testimonialstars}>
                    <img alt="5 star review" src={testimonialStars} />
                  </div>
                  <p className={styles.body}>
                    The RealESmart App is a fantastic tool for Real Estate Salespersons. Instant access to the latest Rental information within seconds. So easy to use so Professional. A must to have in your toolkit.
                  </p>
                  <p className={classnames(styles.body, styles.darkBlue)}>
                    <em>Euon Murrel</em>
                  </p>
                  <p className={styles.body}>
                    Director, Tommy's Real Estate Mana
                  </p>
                </div>
                <div className={styles.review}>
                  <div className={styles.testimonialstars}>
                    <img alt="5 star review" src={testimonialStars} />
                  </div>
                  <p className={styles.body}>
                    I recommend using RealESmart, it is so easy to use and I regularly utilise the app when selling property.  Buyers often require a rental appraisal and Realesmart provides users with accurate data to ascertain the rental value of a property.
                  </p>
                  <p className={styles.body}>
                    <em>Babette Griffiths</em>
                  </p>
                  <p className={styles.body}>
                    Sales and Marketing Consultant Ray White Wellington City
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className={styles.review}>
                  <div className={styles.testimonialstars}>
                    <img alt="5 star review" src={testimonialStars} />
                  </div>
                  <p className={styles.body}>
                    Thanks for putting me onto the RealESmart calculator, finally there is a tool that can calculate Yields for any individual rental property scenario and will help any investor manage their expenses and yields down to the dollar. <br />Fantastic!
                  </p>
                  <p className={classnames(styles.body, styles.darkBlue)}>
                    <em>Jason Hurdle</em>
                  </p>
                  <p className={styles.body}>
                    Financial Adviser – Mortgages Beyond Mortgages
                  </p>
                </div>
                <div className={styles.review}>
                  <div className={styles.testimonialstars}>
                    <img alt="5 star review" src={testimonialStars} />
                  </div>
                  <p className={styles.body}>
                    I absolutely love RealESmart, it is such a useful tool and I find myself using it often in my day-to-day work and appraisals.
                  </p>
                  <p className={styles.body}>
                    <em>Rhiannon Greenwood</em>
                  </p>
                  <p className={styles.body}>
                    Business Development ManagerMcDowell Property Management Ltd
                  </p>
                </div>
              </>
            )}
            <div className={styles.testimonialImage} />
          </div>
          <div className={styles.blurbContainer}>
            <p className={classnames(styles.h3, styles.centered)}>
              Keeping up with the play in the New Zealand Property Market has never been easier. Built on a <em className={!professional ? styles.orange : styles.blue}>smart software</em> platform that draws on up-to-date and <em className={!professional ? styles.orange : styles.blue}>independently sourced</em> property data and accessible via <em className={!professional ? styles.orange : styles.blue}>mobile and desktop</em> devices, the RealESmart <em className={!professional ? styles.orange : styles.blue}>property investment calculator</em> delivers the numbers you need to make well informed property investment decisions.
            </p>
          </div>
          <div>
            <p className={styles.h2}>
              Our Team
            </p>
            <div className={styles.profiles} >
              <div className={styles.founder}>
                <img alt='vesna wells - founder' src={vesna} />
                <p className={styles.h4}>Vesna Wells</p>
                <p className={classnames(styles.h5, !professional && styles.orange)}>Founder</p>
                <p className={styles.body}>
                  Vesna has an undeniable passion for all things property. In her professional career Vesna has held senior management positions having been the CEO of one of New Zealand's largest privately owned property companies. Since 2014, Vesna has been the director of her own property consultancy Resolve which specialises in assisting clients to better managing their properties and providing sage legal advice in the dynamic property landscape.</p>
              </div>
              <div className={styles.founder}>
                <img alt='craig broadhead - founder' src={craig} />
                <p className={styles.h4}>Craig Broadhead</p>
                <p className={classnames(styles.h5, !professional && styles.orange)}>Founder</p>
                <p className={styles.body}>
                  Craig has had a long-term affinity with property starting in his early twenties after seeing property as a way to secure his future. Craig has spent the last 4+ years selling, implementing, and supporting software for real estate professionals. This has led to an extensive understanding of the real estate environment after seeing it from multiple sides.</p>
              </div>
              <div className={`${classnames(styles.signUpBox, styles.centered, professional ? styles.blueBackground : styles.orangeBackground)}`}>
                <span>
                  <p className={classnames(styles.h3, styles.white)}>Try</p>
                  <p className={classnames(styles.h2, styles.white)}>RealESmart <em>Premium</em></p>
                  <p className={classnames(styles.h3, styles.white)}>and enjoy full functionality with all the analytics you need for just $9.99 per month
                  </p>
                </span>
                <Button
                  className={`${styles.button} ${professional && styles.orangeButton}`}
                  text="Sign Up Here"
                  onClick={handleSignUpClicked}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <p>
            &copy; 2021 RealESmart all rights reserved
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
