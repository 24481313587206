import {useState} from 'react'

const useImageCompression = () => {
  const [compressedFile, setCompressedFile] = useState(null)

  const compressFile = file => {
    const maxSide = 500
    const fileName = file.name
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = event => {
      const img = new Image()
      img.src = event.target.result
      return (
        (img.onload = () => {
          const elem = document.createElement('canvas')
          if (img.height > img.width) {
            const scaleFactor = maxSide / img.height
            elem.height = maxSide
            elem.width = img.width * scaleFactor
          } else {
            const scaleFactor = maxSide / img.width
            elem.width = maxSide
            elem.height = img.height * scaleFactor
          }
          const ctx = elem.getContext('2d')
          ctx.drawImage(img, 0, 0, elem.width, elem.height)
          ctx.canvas.toBlob(
            blob => {
              const newFile = new File([blob], fileName, {
                type: 'image/jpeg',
                lastModified: Date.now()
              })
              setCompressedFile(newFile)
            },
            'image/jpeg',
            0.7
          )
          // eslint-disable-next-line
        }),
        (reader.onerror = error => console.log(error))
      )
    }
  }

  return [compressedFile, compressFile]
}

export default useImageCompression
