import styles from './styles.module.scss'

// based on the ComitSpinLoader component from react-css-loaders
// https://github.com/LucasBassetti/react-css-loaders
// react-css-loaders library is not being maintained and has security vulnerabilities

export default function SpinLoader({color = '#000', size = 90, duration = 1.7, className}) {
  const animation = `${styles.loading} ${duration}s infinite ease, ${styles.round} ${duration}s infinite ease`

  return <div class={`${styles.loader} ${className}`} style={{animation, color, fontSize: `${size}px`}} />
}
