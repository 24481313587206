import React from 'react'
import PropTypes from 'prop-types'
import ToolTip from 'components/ToolTip'
import styles from './styles.module.scss'

const getClassName = type => {
  switch (type) {
    case 'inline':
      return styles.containerWithInlineLabel
    case 'responsive':
      return styles.containerWithResponsiveWidth
    case 'responsive-inline':
      return styles.containerWithResponsiveInlineLabel
    case 'row-with-image-and-warning':
      return styles.rowWithImageAndWarning
    case 'row-with-image':
      return styles.rowWithImage
    default:
      return styles.container
  }
}

const INPUT_AND_TAG_NAMES = ['Connect(Input)', 'PremiumTag']

const FormRow = ({children, label, subtitle, toolTipText, type}) => {
  const arrayOfChildren = React.Children.toArray(children)
  const inputAndTagChildren = []
  const nonInputNorTagChildren = []
  arrayOfChildren.forEach(child => {
    INPUT_AND_TAG_NAMES.includes(child.type.displayName)
      ? inputAndTagChildren.push(child)
      : nonInputNorTagChildren.push(child)
  })

  return (
    <div className={getClassName(type)}>
      <div className={styles.row}>
        <div className={styles.labelContainer}>
          <label>{label}</label>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {toolTipText && <ToolTip toolTipText={toolTipText} />}
        </div>
        {inputAndTagChildren}
      </div>
      {nonInputNorTagChildren}
    </div>
  )
}

FormRow.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  subtitle: PropTypes.string
}

export default FormRow
