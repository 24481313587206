import {compose} from 'redux'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import {reduxForm, getFormValues, getFormSubmitErrors} from 'redux-form'
import React, {Component} from 'react'

import {LOGIN_FORM_NAME, loginFormValidation} from 'constants/reduxFormConfig'

import {getRequestStatus} from 'lib/selectors/getRequestStatus'
import {login} from 'actions/accountActions'
import {throwSubmissionError} from 'lib/errorUtils'

import Form, {FormBody, FormSubmit, FormFooter} from 'components/Form'
import FormError from 'components/FormError'
import FormField from 'components/FormField'
import FullScreenLayout from 'components/layouts/FullScreenLayout'
import RequestWrapper from 'components/RequestWrapper'

export class Login extends Component {
  handleLogin = () => {
    let {formValues, login} = this.props
    return login(formValues).catch(throwSubmissionError)
  }
 
  render() {
    let {loggedIn, loginRequestState, handleSubmit, submitError} = this.props

    if (loggedIn) return <Redirect push to='/calculator' />

    return (
      <FullScreenLayout>
        <RequestWrapper requestStates={[loginRequestState]} color='#FFFFFF'>
          <Form onSubmit={handleSubmit(this.handleLogin)}>
            <FormBody>
              <FormField name='email' placeholder='Email' type='email' darkTheme />
              <FormField name='password' placeholder='Password' type='password' darkTheme />
              <FormError error={submitError} darkTheme />
            </FormBody>
            <FormSubmit text='Sign In' />
            <FormFooter>
              <Link to='/forgotpassword'>Forgot Password?</Link>
              <p>
                Don't have an account? <Link to='/plans'>Sign Up</Link>
              </p>
            </FormFooter>
          </Form>
        </RequestWrapper>
      </FullScreenLayout>
    )
  }
}

const mapDispatchToProps = {login}
const mapStateToProps = state => ({
  loggedIn: state.account.loggedIn,
  loginRequestState: getRequestStatus(state.account.requestState),
  formValues: getFormValues(LOGIN_FORM_NAME)(state),
  submitError: getFormSubmitErrors(LOGIN_FORM_NAME)(state).error
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: LOGIN_FORM_NAME,
    validate: loginFormValidation
  })
)(Login)
