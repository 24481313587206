import {combineReducers} from 'redux'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import {calculationTypes} from 'actions/types'

const reducer = combineReducers({
  requestState: requestStateReducer(calculationTypes.FETCH_CALCULATION),
  saveCalculationRequestState: requestStateReducer(calculationTypes.SAVE_CALCULATION),
  editCalculationRequestState: requestStateReducer(calculationTypes.EDIT_CALCULATION),
  deleteCalculationRequestState: requestStateReducer(calculationTypes.DELETE_CALCULATION),
  emailAssessmentRequestState: requestStateReducer(calculationTypes.SEND_RENTAL_ASSESSEMENT_EMAIL)
})

export default reducer
