// Core Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { change } from 'redux-form'

// Constants
import { PRINCIPAL_AND_INTEREST, INTEREST_ONLY } from 'constants/loanPaymentTypes'
import { PERCENTAGE } from 'constants/formFieldTypes'
import { CALCULATOR_FORM_NAME } from 'constants/reduxFormConfig'

// Components
import Slider from 'components/Slider'
import Input from 'components/Input'
import RadioButtons from 'components/RadioButtons'
import FormRow from 'components/FormRow'

// Styles
import styles from './styles.module.scss'

class Interest extends Component {
  constructor() {
    super()
    this.handleLoanPaymentTypeChange = this.handleLoanPaymentTypeChange.bind(this)
  }

  handleLoanPaymentTypeChange(e) {
    let { change } = this.props
    let paymentType = e.target.id
    change(CALCULATOR_FORM_NAME, 'repayment_type', paymentType)
  }

  render() {
    let { maxInterestRate, onChange } = this.props
    return (
      <Fragment>
        <FormRow label='Loan repayment type' type='responsive-inline'>
          <div className={styles.radioButtonContainer}>
            <RadioButtons name={'repayment_type'} options={[PRINCIPAL_AND_INTEREST, INTEREST_ONLY]} onChange={this.handleLoanPaymentTypeChange}/>
          </div>
        </FormRow>
        <FormRow label={'Loan interest rate'}>
          <Input name='interest_rate' inputLabelType={PERCENTAGE} type='string' onChange={onChange} />
          <Slider name={'interest_rate'} min={0} step={0.01} max={maxInterestRate} />
        </FormRow>
      </Fragment>
    )
  }
}

const mapDispatchToProps = { change }

export { Interest }
export default connect(null, mapDispatchToProps)(Interest)
