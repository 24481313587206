import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import useAccount from 'hooks/useAccount'
import useStripe from 'hooks/useStripe'
import {logout} from 'actions/accountActions'
import FullScreenLayout from 'components/layouts/FullScreenLayout'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import ContentSection from 'components/ContentSection'
import HorizontalLine from 'components/HorizontalLine'
import FormError from 'components/FormError'
import CouponPopup from 'components/CouponPopup'
import Banner from 'components/Banner'
import styles from './styles.module.scss'
import checkmark from 'assets/static/icons/checkmark-black.svg'
import features from './features'
import {IN_PROGRESS} from 'lib/selectors/getRequestStatus.js'


export const Plans = ({history, logout}) => {
  const [popupIsOpen, setPopupState] = React.useState(false)
  const [stripeStatus, setStripeStatus] = React.useState(undefined)
  let [account] = useAccount()

  let {stripeCheckout, stripeError} = useStripe({
    success_url: `${window.location.origin}/#/calculator`,
    cancel_url: `${window.location.origin}/#/plans`
  })

  const handleGetPremium = () => {
    if (account) {
      setStripeStatus(IN_PROGRESS)
      stripeCheckout()
    }
    else history.push({pathname: '/signup?referrer=premiumsignup', state: {goPremium: true}})
  }

  const handleGetPremiumWithCouponCode = () => {
    if (account) setPopupState(true)
    else history.push({pathname: '/signup?referrer=premiumsignup', state: {goPremium: true, withCouponCode: true}})
  }

  return (
    <FullScreenLayout>
      <CouponPopup isOpen={popupIsOpen} setPopupState={setPopupState} />
      <ContentSection className={styles.content}>
        <FormError error={stripeError} darkTheme />
        <h1>Property Investment Calculator</h1>
        <HorizontalLine />
        <h2>
          RealESmart is a tool for property lovers, a tool to help you make the best decisions for your property
          journey.
        </h2>
        <h2>
          You could be an investor, a home owner or first home buyer, RealESmart offers you everything you need for
          every step of the way.
        </h2>
        {
          account && !account.premium && (
            <Banner type='info'>
            <p>You are logged in with a free account, but need to upgrade for full access.<span className={styles.logoutLink} onClick={logout}>Click here to log out</span></p>
            </Banner>
          )
        }
        <div className={styles.panels}>
          <div className={styles.panel}>
            <div className={styles.panelContent}>
              <h3>RealESmart Premium</h3>
              <h4>
                $9.99 <span>/ month</span>
              </h4>
              <ButtonGroup className={styles.panelButtons}>
              {!account || !account.premium ? (
                <>
                  <Button text='Get Premium' onClick={handleGetPremium} requestStatus={stripeStatus} />
                  <Button text='Use a coupon code' onClick={handleGetPremiumWithCouponCode} />
                </>
              ) : (
                <Button text='Go to Calculator' onClick={() => history.push('/calculator')} />
              )}
            </ButtonGroup>
              <ul>
                {features.premium.included.map((text, i) => (
                  /* the use of dangerouslySetInnerHTML to display italic and bold is safe as there is no way for a user to inject html in this page */
                  <li key={i} className={styles.includedItem}>
                    <img src={checkmark} alt='checkmark' />
                    {typeof text === 'string' ? text : <span dangerouslySetInnerHTML={text} />}
                  </li>
                ))}
                {features.premium.excluded.map((text, i) => (
                  <li key={i} className={styles.excludedItem}>
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </ContentSection>
    </FullScreenLayout>
  )
}

let mapDispatchToProps = {logout}

export default withRouter(connect(() => ({}), mapDispatchToProps)(Plans))
