import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as scriptjs from 'scriptjs'
import {stripeSession} from 'actions/accountActions'

const mapState = state => ({
  stripe: state.account.stripe
})

const useStripe = session => {
  const dispatch = useDispatch()
  const {stripe} = useSelector(mapState)
  const [stripeError, setError] = useState(null)

  useEffect(() => {
    if (stripe) {
      let {publishableKey, sessionId} = stripe
      window
        .Stripe(publishableKey)
        .redirectToCheckout({sessionId})
        .then(({error}) => {
          // if `redirectToCheckout` fails due to a browser or network error.
          if (error) {
            setError(error.message)
          }
        })
    }
  }, [stripe])

  const stripeCheckout = () => {
    if (window.Stripe) startSession()
    else scriptjs.get('https://js.stripe.com/v3/', startSession)
  }

  const startSession = () => {
    stripeSession(session)(dispatch)
  }

  return {stripeCheckout, stripeError}
}

export default useStripe
