import React, {Component} from 'react'
import {getFormValues, change, getFormSyncErrors} from 'redux-form'
import {connect} from 'react-redux'
import {CURRENCY, PERCENTAGE} from 'constants/formFieldTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {shouldCalculateRentalYield, shouldCalculateReturn} from 'lib/componentHelpers/shouldCalculate'
import {calculateGrossRentalYield, calculateNetRentalYield, calculateReturn} from 'lib/componentHelpers/calculations'
import FormSectionWrapper from 'components/FormSectionWrapper'
import PremiumTag from 'components/PremiumTag'
import FlexFormRows from 'components/FlexFormRows'
import FormRow from 'components/FormRow'
import Input from 'components/Input'

const CV_INFO = 'Capital value data is not available for this property'
const ADDRESS_PROMPT = 'You need to enter an address to see your estimates'

export class Investment extends Component {
  componentDidUpdate(prevProps) {
    let {formValues, change} = this.props

    if (formValues && prevProps.formValues) {
      if (shouldCalculateRentalYield(formValues, prevProps.formValues)) {
        change('gross_rental_yield', calculateGrossRentalYield(formValues))
        change('net_rental_yield', calculateNetRentalYield(formValues))
      }
      if (shouldCalculateReturn(formValues, prevProps.formValues)) {
        let { yearly, monthly, weekly } = calculateReturn(formValues)
        change('weekly_profit', weekly)
        change('monthly_profit', monthly)
        change('yearly_profit', yearly)
      }
    }
  }

  warningOnClick() {
    document.getElementsByName('property')[0].focus()
  }

  render() {
    let {formValues, formErrors, premium, children, coreLogicRequestState} = this.props

    let info = formValues && !formValues.capital_value && coreLogicRequestState.fetched === true ? CV_INFO : null
    let warning = formErrors && formErrors.property ? ADDRESS_PROMPT : null

    return (
      <FormSectionWrapper
        heading='Investment Return'
        info={info}
        warning={warning}
        warningOnClick={this.warningOnClick}
        isAccordion>
        <FlexFormRows>
          <FormRow label='Weekly return' type='inline'>
            <Input name='weekly_profit' inputLabelType={CURRENCY} type='string' disabled={true} />
          </FormRow>
          <FormRow label='Monthly return' type='inline'>
            <Input name='monthly_profit' inputLabelType={CURRENCY} type='string' disabled={true} />
          </FormRow>
          <FormRow label='Annual return' type='inline'>
            <Input name='yearly_profit' inputLabelType={CURRENCY} type='string' disabled={true} />
          </FormRow>
          <FormRow
            label='Gross rental yield'
            toolTipText='Weekly rent × 52 weeks ÷ purchase price × 100 = % gross rental return'
            type='inline'>
            <Input name='gross_rental_yield' inputLabelType={PERCENTAGE} type='string' disabled={true} />
          </FormRow>
          <FormRow
            label='Net rental yield'
            toolTipText='Annual rental Income - expenses ÷ purchase price × 100 = % net rental'
            type='inline'>
            <Input name='net_rental_yield' inputLabelType={PERCENTAGE} type='string' disabled={true} />
          </FormRow>
          <FormRow
            label='Estimated property value'
            toolTipText='Would you like to know the estimated value of this property? Please go to oneroof.co.nz/estimate/map/'
            type='inline'>
            <Input type='string' name='estimated_value' inputLabelType={CURRENCY} />
          </FormRow>
          <FormRow label='CoreLogic CV' type='inline'>
            {premium ? (
              <Input type='string' name='capital_value' inputLabelType={CURRENCY} disabled={true} />
            ) : (
              <PremiumTag text='Upgrade to Premium' />
            )}
          </FormRow>
        </FlexFormRows>
        {children}
      </FormSectionWrapper>
    )
  }
}

const mapDispatchToProps = {
  change: (k, v) => change(CALCULATOR_FORM_NAME, k, v)
}

const mapStateToProps = state => ({
  premium: state.account.data && state.account.data.premium,
  formValues: getFormValues(CALCULATOR_FORM_NAME)(state),
  formErrors: getFormSyncErrors(CALCULATOR_FORM_NAME)(state),
  coreLogicRequestState: state.coreLogic.requestState
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Investment)
