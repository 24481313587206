import { WEEKLY } from 'constants/loanPaymentTermTypes'
import { PRINCIPAL_AND_INTEREST } from 'constants/loanPaymentTypes'

// FORM NAMES
const CALCULATOR_FORM_NAME = 'calculator'
const LOGIN_FORM_NAME = 'login'
const CREATE_ACCOUNT_FORM_NAME = 'createAccount'
const EDIT_ACCOUNT_FORM_NAME = 'editAccount'
const EDIT_PASSWORD_FORM_NAME = 'editPassword'
const FORGOT_PASSWORD_FORM_NAME = 'forgotPassword'
const RESET_PASSWORD_FORM_NAME = 'resetPassword'


// FORM VALIDATION
const calculatorFormValidation = (values) => {
  const errors = {}
  if (!values.property) {
    errors.property = 'You need to enter an address to save this calculation'
  }
  return errors
}

const loginFormValidation = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = true
  } else if (!values.password) {
    errors.password = true
  }
  return errors
}

const createAccountFormValidation = (values) => {
  const errors = {}
  if (!values.name || !values.email || !values.password || !values.password_confirmation) {
    errors.name = true
  } else if (!values.email) {
    errors.email = true
  } else if (!values.password) {
    errors.password = true
  } else if (!values.password_confirmation) {
    errors.password_confirmation = true
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Your passwords do not match'
  }
  return errors
}

const editPasswordFormValidation = (values) => {
  const errors = {}
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Your passwords do not match'
  } else if (!values.current_password) {
    errors.current_password = true
  } else if (!values.password) {
    errors.password = true
  } else if (!values.password_confirmation) {
    errors.password_confirmation = true
  }
  return errors
}

const forgotPasswordFormValidation = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = true
  }
  return errors
}

const resetPasswordFormValidation = (values) => {
  const errors = {}
  if (!values.password) {
    errors.password = true
  } else if (!values.password_confirmation) {
    errors.password_confirmation = true
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Your passwords do not match'
  }
  return errors
}

const calculatorFormInitialValues = {
  body_corporate_fees: 0,
  contingency: 0,
  deposit: 0,
  dwelling: 'House',
  estimated_value: 0,
  gross_rental_yield: 0,
  house_condition: 50,
  include_interest_in_calc: true,
  insurance_cost: 0,
  interest_rate: 0,
  interest: 0,
  loan_duration: 0,
  loan_payment_term: WEEKLY,
  monthly_profit: 0,
  monthly_rental_income_lower: 0,
  monthly_rental_income_upper: 0,
  net_rental_yield: 0,
  number_of_bedrooms: 1,
  number_of_car_parks: 0,
  payment_quantity: 0,
  property_estimated_value: 0,
  property_management_fees: 0,
  purchase_price: 0,
  rates: 0,
  repairs_maintenance: 0,
  repayment_type: PRINCIPAL_AND_INTEREST,
  total_expenses: 0,
  vacancy_cost: 0,
  weekly_profit: 0,
  weekly_rental_income_lower: 0,
  weekly_rental_income_upper: 0,
  weekly_rental_income: 0,
  yearly_profit : 0,
  yearly_rental_income_lower: 0,
  yearly_rental_income_upper: 0,
  estimate_weekly_lower: null,
  estimate_weekly_upper: null,
  estimate_monthly_lower: null,
  estimate_monthly_upper: null,
  estimate_annual_lower: null,
  estimate_annual_upper: null,
  estimate_reason: null,
}

export {
  CALCULATOR_FORM_NAME,
  LOGIN_FORM_NAME,
  CREATE_ACCOUNT_FORM_NAME,
  EDIT_ACCOUNT_FORM_NAME,
  EDIT_PASSWORD_FORM_NAME,
  FORGOT_PASSWORD_FORM_NAME,
  RESET_PASSWORD_FORM_NAME,
  calculatorFormValidation,
  loginFormValidation,
  createAccountFormValidation,
  editPasswordFormValidation,
  forgotPasswordFormValidation,
  resetPasswordFormValidation,
  calculatorFormInitialValues,
}
