import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import useAccount from 'hooks/useAccount'
import {createPathForPremiumPopup} from 'components/PremiumPopup/helpers'

export const PremiumLink = ({to, onClick, children, location, history, className}) => {
  const [account] = useAccount()

  if (!(account && account.premium)) {
    // render a link to the premium popup if the account isn't premium
    const pathname = createPathForPremiumPopup(location.pathname)
    return (
      <Link className={className} to={{pathname, state: {targetPath: to || location.pathname}}}>
        {children}
      </Link>
    )
  }

  if (to) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    )
  } else {
    return (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    )
  }
}

export default withRouter(PremiumLink)
