import {combineReducers} from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import {uploadTypes} from 'actions/types'

const INITIAL_PARAMS_STATE = null
const paramsReducer = createReducer(INITIAL_PARAMS_STATE, {
  [uploadTypes.GET_UPLOAD_URL_FULFILLED]: (_, action) => action.payload
})

const INITIAL_DATA_STATE = null
const dataReducer = createReducer(INITIAL_DATA_STATE, {
  [uploadTypes.UPLOAD_TO_URL_FULFILLED]: (_, action) => action.payload,
  [uploadTypes.CONFIRM_UPLOAD_TO_URL_FULFILLED]: state => ({...state, confirmed: true}),
  [uploadTypes.CLEAR_UPLOAD]: (state, action) => INITIAL_PARAMS_STATE,
})

const reducer = combineReducers({
  params: paramsReducer,
  data: dataReducer,
  getUploadURLState: requestStateReducer(uploadTypes.GET_UPLOAD_URL),
  uploadToURLState: requestStateReducer(uploadTypes.UPLOAD_TO_URL),
  confirmUploadToURLState: requestStateReducer(uploadTypes.CONFIRM_UPLOAD_TO_URL),
})

export default reducer
