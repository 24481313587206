import {coreLogicTypes} from 'actions/types'
import {get} from 'lib/reduxHelpers/thunks'
import formatFetchResponse from './formatFetchResponse'

export const fetchCoreLogic = address => {
  let baseType = coreLogicTypes.FETCH_CORELOGIC
  let url = `${process.env.REACT_APP_API_URL}/corelogic/property?address=${address}`
  let extractPayload = response => formatFetchResponse(response.data)
  return get({baseType, url, extractPayload})
}

export const clearCoreLogic = () => dispatch => {
  dispatch({type: coreLogicTypes.CLEAR_FETCH_CORELOGIC})
}
