import React, {Fragment, useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import SideMenu from 'components/SideMenu'
import Flash from 'components/Flash'
import TopNav, {TopNavIcon, TopNavLink, TopNavSpace} from 'components/TopNav'
import ContentContainer from 'components/ContentContainer'
import Footer from 'components/Footer'
import menuIcon from 'assets/static/icons/menu.svg'
import styles from './styles.module.scss'

export const DefaultLayout = ({children, pageTitle, history}) => {
  const loggedIn = useSelector(state => state.account.loggedIn)
  const [drawOpen, setDrawOpen] = useState(false)
  const toggleDraw = () => setDrawOpen(!drawOpen)
  const handleSignIn = () => history.push('/signin')
  return (
    <Fragment>
      <TopNav>
        {loggedIn ? <TopNavIcon icon={menuIcon} onClick={toggleDraw} /> : <TopNavSpace />}
        <h1>{pageTitle}</h1>
        {!loggedIn ? <TopNavLink onClick={handleSignIn}>Sign In</TopNavLink> : <TopNavSpace />}
      </TopNav>
      <div className={styles.layout}>
        {loggedIn ? <SideMenu isOpen={drawOpen} toggleDraw={toggleDraw} /> : null}
        <Flash />
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </div>
    </Fragment>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default withRouter(DefaultLayout)
