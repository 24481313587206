import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getFormValues, change} from 'redux-form'
import {PRINCIPAL_AND_INTEREST, INTEREST_ONLY} from 'constants/loanPaymentTypes'
import {YEARS} from 'constants/formFieldTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {
  calculateInterest,
  shouldCalculateLoanPayment
} from 'lib/componentHelpers'
import FormSectionWrapper from 'components/FormSectionWrapper'
import Slider from 'components/Slider'
import FormRow from 'components/FormRow'
import Input from 'components/Input'
import LoanPayment from 'components/Calculator/LoanPayment'
import Interest from 'components/Calculator/Interest'
import Purchase from 'components/Calculator/Purchase'

export class Borrowing extends Component {
  constructor() {
    super()
    this.state = {
      purchase_price_max: 2000000,
      loan_duration_max: 35,
      interest_rate_max: 15
    }

    this.updatePaymentQuantity = this.updatePaymentQuantity.bind(this)
  }

  componentDidMount() {
    this.updatePaymentQuantity()
  }

  componentDidUpdate(prevProps) {
    let {formValues} = this.props
    if (formValues && prevProps.formValues) {
      if (shouldCalculateLoanPayment(formValues, prevProps.formValues)) {
        this.updatePaymentQuantity()
      }
    }
  }

  updatePaymentQuantity() {
    let {formValues, change} = this.props
    let {purchase_price, loan_duration, interest_rate, repayment_type, deposit, loan_payment_term} = formValues
    const {interestOnly, principalAndInterest} = calculateInterest(purchase_price, deposit, interest_rate, loan_payment_term, loan_duration)
    const paymentQuantity = repayment_type === INTEREST_ONLY ? interestOnly : principalAndInterest
    if (paymentQuantity) {
      change(CALCULATOR_FORM_NAME, 'payment_quantity', paymentQuantity)
    }
  }

  setMaxValue = e => {
    let {value, name} = e.target
    let max = `${name}_max`
    let numberValue = Number(value.replace(/,\s?/g, ''))

    if (numberValue > this.state[max]) {
      this.setState({[max]: numberValue})
    }
  }

  render() {
    let {formValues, children} = this.props
    let {purchase_price_max, loan_duration_max, interest_rate_max} = this.state
    return formValues ? (
      <FormSectionWrapper heading='Borrowing' isAccordion>
        <Purchase maxPurchasePrice={purchase_price_max} onChange={this.setMaxValue} />
        {formValues.repayment_type === PRINCIPAL_AND_INTEREST ? (
          <FormRow label={'Length of loan'}>
            <Input name='loan_duration' inputLabelType={YEARS} type='string' onChange={this.setMaxValue} />
            <Slider name={'loan_duration'} min={0} max={loan_duration_max} />
          </FormRow>
        ) : null}
        <Interest maxInterestRate={interest_rate_max} onChange={this.setMaxValue} />
        <LoanPayment />
        {children}
      </FormSectionWrapper>
    ) : null
  }
}

const mapDispatchToProps = {change}
const mapStateToProps = state => ({formValues: getFormValues(CALCULATOR_FORM_NAME)(state)})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Borrowing)
