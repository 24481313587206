// Core Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './styles.module.scss'

const ContentContainer = ({children, className}) => (
  <div className={`${styles.container} ${className}`}>
    {children}
  </div>
)

ContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

ContentContainer.defaultProps = {
  className: ''
}

export default ContentContainer