// Core dependencies
import React, {Fragment} from 'react'

// Components
import Slider from 'components/Slider'
import Input from 'components/Input'
import FormRow from 'components/FormRow'

import './styles.scss'

const CAR_PARK_SLIDER_MARKS = {0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5+'}

const Bedrooms = ({minBedrooms, maxBedrooms, bedroomMarks}) => (
  <Fragment>
    <FormRow label={'Bedrooms'}>
      <Input name='number_of_bedrooms' type='string' parse={value => Number(value)} />
      {minBedrooms === maxBedrooms ? (
        <p>
          {`We only have one data point for this property type.
            If your property's bedroom quantity differs from the
            input field above you can enter an estimate manually.`}
        </p>
      ) : (
        <Slider min={minBedrooms} max={maxBedrooms} name={'number_of_bedrooms'} marks={bedroomMarks} />
      )}
    </FormRow>
    <FormRow label='How many car parks does the property have?'>
      <Input name='number_of_car_parks' type='string' />
      <Slider min={0} max={5} step={1} name={'number_of_car_parks'} marks={CAR_PARK_SLIDER_MARKS} />
    </FormRow>
  </Fragment>
)

export default Bedrooms
