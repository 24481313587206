const events = {
  premiumsignup: {
    type: 'ViewContent',
    detail: {content_ids: 'premiumSignup'}
  },
  freesignup: {
    type: 'ViewContent',
    detail: {content_ids: 'freemiumSignup'}
  },
  freesuccess: {
    type: 'CompleteRegistration',
    detail: {}
  },
  premiumsuccess: {
    type: 'Purchase',
    detail: {value: 9.99, currency: 'NZD'}
  }
}

export default events
