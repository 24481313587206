/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {change} from 'redux-form'

import {WEEKLY, YEARLY, MONTHLY} from 'constants/loanPaymentTermTypes'
import {CALCULATOR_FORM_NAME} from 'constants/reduxFormConfig'
import {convertValueByFrequency} from 'lib/componentHelpers'
import {ACCEPT_TERMS} from 'constants/text'

import Button from 'components/Button'
import FormRow from 'components/FormRow'
import RangedRentalIncomeInputs from 'components/Calculator/RangedRentalIncomeInputs'
import ToolTip from 'components/ToolTip'

import styles from './styles.module.scss'

const RentalAssessmentReview = ({formValues, formErrors, change, onCancelReview}) => {
  const [conditionsAccepted, setConditionsAccepted] = useState(false)

  const {
    estimate_weekly_lower,
    estimate_weekly_upper,
    estimate_monthly_lower,
    estimate_monthly_upper,
    estimate_yearly_lower,
    estimate_yearly_upper,
    estimate_reason
  } = formValues || {}

  useEffect(() => {
    if (estimate_weekly_lower || estimate_weekly_upper) {
      updateRangeFields(WEEKLY)
    }
  }, [estimate_weekly_lower, estimate_weekly_upper])

  useEffect(() => {
    if (estimate_monthly_lower || estimate_monthly_upper) {
      updateRangeFields(MONTHLY)
    }
  }, [estimate_monthly_lower, estimate_monthly_upper])

  useEffect(() => {
    if (estimate_yearly_lower || estimate_yearly_upper) {
      updateRangeFields(YEARLY)
    }
  }, [estimate_yearly_lower, estimate_yearly_upper])

  const updateRangeFields = basedOn => {
    const upperRange = formValues[`estimate_${basedOn.toLowerCase()}_upper`]
    const lowerRange = formValues[`estimate_${basedOn.toLowerCase()}_lower`]
    const weeklyUpperRange = convertValueByFrequency(upperRange, basedOn, WEEKLY)
    const weeklyLowerRange = convertValueByFrequency(lowerRange, basedOn, WEEKLY)

    if (basedOn !== WEEKLY) {
      change('estimate_weekly_lower', weeklyLowerRange)
      change('estimate_weekly_upper', weeklyUpperRange)
    }
    if (basedOn !== MONTHLY) {
      change('estimate_monthly_lower', convertValueByFrequency(lowerRange, basedOn, MONTHLY))
      change('estimate_monthly_upper', convertValueByFrequency(upperRange, basedOn, MONTHLY))
    }
    if (basedOn !== YEARLY) {
      change('estimate_yearly_lower', convertValueByFrequency(lowerRange, basedOn, YEARLY))
      change('estimate_yearly_upper', convertValueByFrequency(upperRange, basedOn, YEARLY))
    }
  }

  const handleConditionsAccepeted = () => {
    setConditionsAccepted(!conditionsAccepted)
  }

  const handleEstimateReasonChange = e => {
    change('estimate_reason', e.currentTarget.value)
  }

  const handleClearReview = () => {
    change('estimate_weekly_lower', null)
    change('estimate_weekly_upper', null)
    change('estimate_monthly_lower', null)
    change('estimate_monthly_upper', null)
    change('estimate_yearly_lower', null)
    change('estimate_yearly_upper', null)
    change('estimate_reason', null)
    onCancelReview()
  }

  return (
    <>
      <div>
        <span className={styles.estimateHeader}>Alternative Appraisal Estimate</span>
        <FormRow label='Estimated weekly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs
            period='weekly'
            userEstimate
            required={true}
            min={formValues.estimate_weekly_lower}
          />
        </FormRow>
        {formErrors.estimate_weekly_upper && <div className={styles.error}>{formErrors.estimate_weekly_upper}</div>}
        <FormRow label='Estimated monthly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs
            period='monthly'
            userEstimate
            required={true}
            min={formValues.estimate_monthly_lower}
          />
        </FormRow>
        {formErrors.estimate_monthly_upper && <div className={styles.error}>{formErrors.estimate_monthly_upper}</div>}
        <FormRow label='Estimated yearly rental income' type='responsive-inline'>
          <RangedRentalIncomeInputs
            period='yearly'
            userEstimate
            required={true}
            min={formValues.estimate_yearly_lower}
          />
        </FormRow>
        {formErrors.estimate_yearly_upper && <div className={styles.error}>{formErrors.estimate_yearly_upper}</div>}
        <div className={styles.estimateReasonContainer}>
          <label>
            This estimate differs from the RealESmart estimate having been carefully thought through for this property
            and is provided with all due care and is subject to market forces. The reasons for the rental estimate are
            as follows:
            <textarea
              rows='4'
              maxLength={400}
              name='estimate_reason'
              value={estimate_reason}
              required
              placeholder='Enter reasons for your rental estimates - max 400 characters'
              onChange={handleEstimateReasonChange}
            />
          </label>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div>
          <label>
            <input name='accept_terms' type='checkbox' required onChange={handleConditionsAccepeted} />I accept the
            terms
          </label>
          <ToolTip toolTipText={ACCEPT_TERMS} />
        </div>
        <Button secondary type='button' text='Clear' onClick={handleClearReview} />
      </div>
    </>
  )
}
const mapDispatchToProps = {change: (name, value) => change(CALCULATOR_FORM_NAME, name, value)}

export {RentalAssessmentReview}
export default connect(() => ({}), mapDispatchToProps)(RentalAssessmentReview)
