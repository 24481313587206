import React from 'react'
import {Link} from 'react-router-dom'
import styles from './styles.module.scss'
import arrowIcon from 'assets/static/icons/left-arrow.svg'

const ListLink = ({children, ...props}) => (
  <Link {...props} className={styles.link}>
    {children}
    <img className={styles.arrowIcon} alt={'icon'} src={arrowIcon} />
  </Link>
)

export default ListLink
