import { SubmissionError } from 'redux-form'

const throwSubmissionError = (error) => {
  let errorMessage = 'Something went wrong. Please contact info@realesmart.co.nz if the problem persists'
  let errorCode = error.response.status
  if (errorCode === 401 || errorCode === 422) {
    errorMessage = error.response.data.error
  }
  throw new SubmissionError({ 'error': errorMessage })
}

const noFormErrors = (formErrors) => {
  return Object.keys(formErrors).length === 0
}

export { throwSubmissionError, noFormErrors }