import {combineReducers} from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import {accountTypes} from 'actions/types'

const INITIAL_DATA_STATE = null
const dataReducer = createReducer(INITIAL_DATA_STATE, {
  [accountTypes.LOGOUT]: (state, action) => INITIAL_DATA_STATE,
  [accountTypes.FETCH_ACCOUNT_FULFILLED]: (state, action) => action.payload
})

const INITIAL_AUTH_STATE = !!localStorage.getItem(`realesmart.jwt`)
const authReducer = createReducer(INITIAL_AUTH_STATE, {
  [accountTypes.LOGIN_FULFILLED]: (state, action) => true,
  [accountTypes.LOGOUT]: (state, action) => false
})

const INITIAL_STRIPE_STATE = null
const stripeReducer = createReducer(INITIAL_STRIPE_STATE, {
  [accountTypes.STRIPE_SESSION_FULFILLED]: (state, action) => action.payload
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(accountTypes.LOGIN),
  loggedIn: authReducer,
  stripe: stripeReducer,
  stripeSessionRequestState: requestStateReducer(accountTypes.STRIPE_SESSION),
  cancelSubscriptionRequestState: requestStateReducer(accountTypes.CANCEL_SUBSCRIPTION),
  uncancelSubscriptionRequestState: requestStateReducer(accountTypes.UNCANCEL_SUBSCRIPTION),
  createAccountRequestState: requestStateReducer(accountTypes.CREATE_ACCOUNT),
  fetchAccountRequestState: requestStateReducer(accountTypes.FETCH_ACCOUNT),
  editAccountRequestState: requestStateReducer(accountTypes.EDIT_ACCOUNT),
  editPasswordRequestState: requestStateReducer(accountTypes.EDIT_PASSWORD),
  forgotPasswordRequestState: requestStateReducer(accountTypes.FORGOT_PASSWORD),
  resetPasswordRequestState: requestStateReducer(accountTypes.RESET_PASSWORD),
  stripeCouponRequestState: requestStateReducer(accountTypes.STRIPE_COUPON)
})

export default reducer
